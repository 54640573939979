.drawer :global .ant-drawer-content-wrapper{
    width: 80px !important;
    transition: 0.2s all;
}

.drawer :global .ant-drawer-body{
    padding-left: 0;
    padding-right: 0;
}

.drawer :global .ant-drawer-wrapper-body{
    overflow: auto;
}

.fullDrawer :global .ant-drawer-content-wrapper{
    width: 320px !important;
    transition: 0.2s all;
}
.wrapper {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.title {
  font-family: 'Exo 2', Roboto, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  vertical-align: top;
  min-height: 44px;
  color: var(--text);
}

.list {
  padding-left: 0;
}

.list li::marker {
  color: #0ea28a;
}

.description {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text);
}

.imageWrapper {
  margin-top: 18px;
}

.image {
  width: 1082px;
  pointer-events: none;
  user-select: none;
}

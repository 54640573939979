/* Toolbar */
.wrapper {
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.refreshWrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 35px;
}

.refreshIconWrapper {
  margin-left: 8px;
}

.refreshIcon {
  cursor: pointer;
}

.refreshIconLoading {
  cursor: not-allowed;
  animation: refreshProcess 1s linear infinite;
}

@keyframes refreshProcess {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 796px) {
  .buttonsWrapper {
    display: none;
  }
}

.downloadIcon:global.anticon-download {
  font-size: 24px;
  color: var(--limeGreen);
}

.downloadButton:global {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', Arial, sans-serif;
  color: var(--lightBlack);
  margin-right: 16px;
}

.downloadButton :global .ant-btn {
  height: 100%;
  line-height: 42px;
}

.downloadButton .downloadIcon {
  top: 3px;
  position: relative;
}

.downloadButton:global:hover {
  background-color: var(--secondary);
  color: var(--lightBlack);
}

.newButton:global.ant-btn {
  padding: 12px;
}

.newButtonMobile {
  display: none;
  width: 100%;
  margin-top: 25px;
}

@media screen and (max-width: 796px) {
  .newButtonMobile {
    display: block;
  }

  .newButtonMobile :global .ant-btn {
    width: 100%;
  }
}

/* Mobile toolbar */
.iconsWrapper {
  display: none;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 796px) {
  .iconsWrapper {
    display: flex;
    margin: 15px 0 0 auto;
  }
}

.filterIconWrapper {
  position: relative;
}

.filterIconBadge {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--redDashboard);

  position: absolute;
  top: 2px;
  right: -5px;
}

.searchIconWrapper {
  display: flex;
  margin-left: 15px;
}
.searchIcon svg {
  width: 19px;
  height: 19px;
  fill: var(--textInTables);
}

.searchIcon.completed:global.anticon-check-circle {
  color: var(--limeGreen);
}

.buttonsWrapperMobile {
  display: none;
}

@media screen and (max-width: 796px) {
  .buttonsWrapperMobile {
    display: block;
    width: 100%;
    margin-top: 8px;
  }

  .downloadButton:global.ant-btn {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.searchInputMobile:global.ant-input-affix-wrapper {
  margin-top: 24px;
}
/* Filters row */

.filtersRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 796px) {
  .row {
    display: none;
  }
}

.select:first-child {
  max-width: 160px;
}
.select:nth-child(2) {
  max-width: 160px;
}
.select:nth-child(3) {
  max-width: 135px;
}
.select:nth-child(4) {
  max-width: 100px;
}

.select:not(:first-child) {
  margin-left: 8px;
}

.searchInput:global.ant-input-affix-wrapper {
  max-width: 150px;
  margin-left: 8px;
}

.searchIconPrefix svg {
  fill: #e3e3e3;
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datePicker:global.ant-calendar-picker {
  flex: 1;
}

.datePicker:global.ant-calendar-picker div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
}

.datePicker:global.ant-calendar-picker .ant-calendar-picker-input {
  height: 38px;
  border: none;
  border-radius: 4px;
  color: var(--text);
  text-align: center;
  text-transform: capitalize;
  padding-left: 5px;
  padding-right: 0;
}

/* hover,active,focus */
:global .ant-calendar-picker .ant-input:focus,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:hover,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:active,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:focus {
  border: none;
  box-shadow: none;
}

:global
  .ant-calendar-picker:hover
  .ant-input.ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--text);
}

.dropdown
  :global
  .ant-calendar-month-panel-selected-cell
  .ant-calendar-month-panel-month,
.dropdown
  :global
  .ant-calendar-month-panel-selected-cell
  .ant-calendar-month-panel-month:hover {
  background-color: var(--limeGreen);
  color: var(--white);
  line-height: inherit;
  height: auto;
  border-radius: 4px;
}

.dropdown :global .ant-calendar-month .ant-calendar-month-header-wrap {
  height: 190px;
}

.dropdown :global .ant-calendar-month-panel {
  padding-bottom: 10px;
}

.dropdown :global .ant-calendar-month-panel-header,
.dropdown :global .ant-calendar-year-panel-header {
  border: none;
}

.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-year-select,
.dropdown :global .ant-calendar-month-panel-month {
  color: var(--spinfiText);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.dropdown :global .ant-calendar-month-panel-month {
  display: flex;
  justify-content: center;
}

.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-year-btn,
.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-year-btn {
  width: 40px;
}

.dropdown :global .ant-calendar-header {
  line-height: 32px;
}

.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-year-btn::before,
.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-year-btn::before {
  display: none;
}

.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-year-btn::after,
.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-year-btn::after {
  border-color: var(--lightBlack);
  width: 8px;
  height: 8px;
  border-width: 2px 0 0 2px;
  transform: rotate(-45deg) scale(1);
}

.dropdown
  :global
  .ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-year-btn::after {
  transform: rotate(135deg) scale(1);
}

.dropdown :global .ant-calendar-header a:hover {
  color: var(--limeGreen);
}

.dropdown :global .ant-calendar-month-panel-month:hover {
  background: transparent;
}

/* icon */
.datePicker:global.ant-calendar-picker .ant-calendar-picker-icon {
  color: var(--limeGreen);
  right: auto;
  left: 0;
  position: static;
  order: -1;
  margin: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--linesList);
  border-radius: 6px;
  position: relative;
}

.button:global.ant-btn-icon-only.ant-btn-lg {
  background-color: transparent;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 0;
  flex: 0 0 auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #575756;
}

.button + .button {
  right: 0;
  left: auto !important;
}

.button:global.ant-btn-icon-only.ant-btn-lg:focus {
  color: var(--lightBlack);
}

.button:global.ant-btn-icon-only.ant-btn-lg:hover,
.button:global.ant-btn-icon-only.ant-btn-lg:active {
  color: var(--limeGreen);
}

.dropdown:global.ant-calendar-picker-container {
  padding-top: 42px !important;
}

@media screen and (min-width: 768px) {
  .wrapper {
    max-width: 210px;
    min-width: 210px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown:global.ant-calendar-picker-container {
    width: calc(100% - 22px) !important;
  }

  .dropdown :global .ant-calendar.ant-calendar-month {
    width: 100%;
  }

  .datePicker:global.ant-calendar-picker .ant-calendar-picker-input {
    width: 80px;
  }

  .button:global.ant-btn-icon-only.ant-btn-lg:hover,
  .button:global.ant-btn-icon-only.ant-btn-lg:focus {
    color: var(--lightBlack);
  }
  .button:global.ant-btn-icon-only.ant-btn-lg:active {
    color: var(--limeGreen);
  }
}

.dropdown
  :global
  .ant-calendar-year-panel-selected-cell
  .ant-calendar-year-panel-year,
.dropdown
  :global
  .ant-calendar-year-panel-selected-cell
  .ant-calendar-year-panel-year:hover {
  background-color: var(--limeGreen);
  color: var(--white);
  line-height: inherit;
  height: auto;
  border-radius: 4px;
}

.dropdown :global .ant-calendar-input-wrap,
.dropdown :global .ant-calendar-body,
.dropdown :global .ant-calendar-footer {
  display: none;
}

.dropdown :global .ant-calendar {
  height: 190px;
}

.dropdown :global .ant-calendar-panel {
  padding-bottom: 10px;
}

.dropdown :global .ant-calendar-panel-header,
.dropdown :global .ant-calendar-panel-header {
  border: none;
}

.dropdown :global .ant-calendar-panel-header .ant-calendar-panel-year-select,
.dropdown :global .ant-calendar-panel-month {
  color: var(--spinfiText);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.dropdown :global .ant-calendar-panel-month {
  display: flex;
  justify-content: center;
}

.dropdown :global .ant-calendar-panel .ant-calendar-year-panel-next-decade-btn,
.dropdown :global .ant-calendar-panel .ant-calendar-year-panel-prev-decade-btn {
  width: 40px;
}

.dropdown :global .ant-calendar-header {
  line-height: 32px;
}

.dropdown
  :global
  .ant-calendar-panel
  .ant-calendar-year-panel-prev-decade-btn::before,
.dropdown
  :global
  .ant-calendar-panel
  .ant-calendar-year-panel-next-decade-btn::before {
  display: none;
}

.dropdown
  :global
  .ant-calendar-panel
  .ant-calendar-year-panel-prev-decade-btn::after,
.dropdown
  :global
  .ant-calendar-panel
  .ant-calendar-year-panel-next-decade-btn::after {
  border-color: var(--lightBlack);
  width: 8px;
  height: 8px;
  border-width: 2px 0 0 2px;
  transform: rotate(-45deg) scale(1);
}

.dropdown
  :global
  .ant-calendar-panel
  .ant-calendar-year-panel-next-decade-btn::after {
  transform: rotate(135deg) scale(1);
}

.dropdown :global .ant-calendar-header a:hover {
  color: var(--limeGreen);
}

.dropdown :global .ant-calendar-panel-month:hover {
  background: transparent;
}

.background {
  min-height: 540px;
  background: var(--servicesSectionBG);
}

.newsWrapper {
  display: flex;
  margin-top: 18px;
  width: 100%;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categoriesContainer {
  display: flex;
  gap: 4px;
}

.articleContainer {
  margin-top: 37px;
  padding-left: 174px;
  padding-right: 174px;
  width: 100%;
  margin-bottom: 37px;
}

@media screen and (max-width: 1024px) {
  .articleContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 425px) {
  .articleContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

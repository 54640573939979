.background{
  background: var(--servicesSectionBG);
  position: relative;
  padding-top: 38px;
}

.title {
  margin-bottom: 32px;
}

.flowersImg{
  position: absolute;
  transform: rotate(180deg);
  top: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.uploadDocumentsPage {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 0 15px 75px;
  position: relative;
  z-index: 1;
}

.modalText {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto,Arial,sans-serif;
}

.modalSpinWrapper {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .uploadDocumentsPage {
    max-width: 350px;
    width: 100%;
    margin: auto;
    padding: 24px 15px 75px;
  }
}

.sectionWrapper{
    display: flex;
    padding: 42px 15px 58px;
    flex-wrap: wrap;
}

.wrapper{
    flex: 0 1 50%;
    padding-top: 20px;
}

.content{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding-left: 38px;
    margin-top: 55px;
}

.subtitle{
    font-family: 'Exo 2',Arial,sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: var(--lightBlack);
}

.paragraph{
    font-family: Roboto,Arial,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--lightBlack);
    margin: 16px 0 35px;
    max-width: 427px;
}

.buttonWrapper{
    position: relative;
    width: max-content;
    width: -moz-max-content;
}

.button:global.ant-btn:focus{
    opacity: 1;
}

.button:global.ant-btn{
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.imgContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 0 1 50%;
}

.disclaimer{
    font-weight: 400;
    margin-top: 15px;
}

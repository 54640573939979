.checkBox {
  display: flex;
  align-items: center;
}

.checkBox input {
  display: none;
}

.checkBox label {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.checkBox label span {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin-left: 10px;
}

.box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dce5e7;
  transform: translateY(1px);
}

.box.checked {
  background: var(--limeGreen);
  border: 1px solid var(--limeGreen);
}

.checkMark {
  font-size: 13px;
  color: var(--limeGreen);
  display: none;
}

.checkMark.checked {
  display: block;
  color: white;
}

.checkBoxLabelWrapper {
  display: block;
}

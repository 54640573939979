.coloredDropdown:global.ant-select {
  width: 119px;
  color: var(--lightBlack);
  font-family: Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.coloredDropdown.dropDownWithValues:global.ant-select {
  width: 100%;
}

.coloredDropdown:global.ant-select-open {
  /*opacity: 50%;*/
  color: rgba(0, 0, 0, 0.25);
}

.coloredDropdown :global .ant-select-arrow {
  transition: 0.3s;
  transition-delay: 0.1s;
}

.coloredDropdown:global.ant-select-open :global .ant-select-arrow {
  color: rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  transition-delay: 0.1s;
}

.coloredDropdown :global .ant-select-selection__rendered {
  line-height: 16px;
  overflow: hidden;
}

.coloredDropdown:global.ant-select div.ant-select-selection {
  background-color: var(--white);
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.coloredDropdown :global .ant-select-arrow {
  right: 11px;
  color: #575756;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}

.coloredDropdown :global .ant-select-arrow i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 0;
}

.coloredDropdown:global.ant-select div.ant-select-selection--single {
  height: auto;
}

.dropdownMenu {
  border-radius: 6px;
}

.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  background: var(--white);
}

.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:active,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  color: var(--limeGreen);
}

.dropdownMenu :global .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

/* Track */
.dropdownMenu :global .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.dropdownMenu :global .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  position: relative;
  background: #b8b8b8;
  border-radius: 6px;
  border-right: 4px solid var(--white);
  border-left: 4px solid var(--white);
}

.option {
  font-family: Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.optionValue {
  display: flex;
  justify-content: space-between;
}

.longOption .optionName {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}

.coloredDropdown:global.ant-select .ant-select-selection__rendered {
  width: 100%;
}

.coloredDropdown:global.ant-select .ant-select-selection-selected-value {
  width: 100%;
}

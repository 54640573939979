.dropDownMenu :global .ant-dropdown-menu-item {
  background: inherit;
  text-align: right;
  width: 100%;
}
.dropDownMenu :global .ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 13px;
}

.dropDownMenu :global .ant-dropdown-menu-item a {
  font-family: 'Exo 2', Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  padding: 0;
}
.dropDownMenu :global .ant-dropdown-menu-item a:hover {
  color: var(--limeGreen);
}

.dropDownWindow :global .ant-dropdown-menu {
  background: var(--darkBlue);
  padding: 20px 16px;
  width: max-content;
  margin-left: auto;
}
.dropDownWindow:global.ant-dropdown {
  z-index: 9;
  position: absolute;
}

.dropDownTrigger {
  font-weight: 400;
  font-size: 16px;
  color: var(--spinfiText);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Arial, sans-serif;
}

.dropDownTrigger i {
  /*border: 1px solid red;*/
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightBlack);
  font-size: 10px;
  margin-left: 3px;
  transition: all 0.2s;
}

.dropDownTrigger span {
  max-width: 200px;
  width: max-content;
  display: block;
  word-wrap: break-word;
  white-space: normal;
}

.transparentTrigger i {
  color: rgba(0, 0, 0, 0.35);
}

.userIcon {
  display: none;
}

@media screen and (max-width: 400px) {
  .userIcon {
    display: block;
  }
  .dropDownTrigger span {
    display: none;
  }
  .dropDownTrigger i {
    display: none;
  }
}

.wrapper {
  /*position: relative;*/
  /*z-index: 5;*/
  width: 264px;
  border-radius: 8px;
  display: flex;
  padding: 27px 20px;
  align-items: flex-start;
}

.wrapper.high {
  border: 1px dashed var(--emeraldGreen);
}
.wrapper.medium {
  border: 1px dashed #e67f39;
}
.wrapper.low {
  border: 1px dashed var(--redDashboard);
}

.wrapper.medium .icon img {
  transform: rotate(48deg);
}

.wrapper.low .icon img {
  transform: rotate(90deg);
}

.wrapper.nA {
  border: 1px dashed var(--lightGreyDashBoard);
}

.icon {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  position: relative;
  transform: translateY(3px);
}

.icon:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  z-index: 1;
}

.icon img {
  position: relative;
  z-index: 2;
}

.wrapper.high .icon:after {
  background: var(--emeraldGreen);
}
.wrapper.medium .icon:after {
  background: #e67f39;
}
.wrapper.low .icon:after {
  background: var(--redDashboard);
}

.wrapper.nA .icon:after {
  background: var(--lightGreyDashBoard);
}

.title {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--lightBlack);
  margin-bottom: 4px;
}

.description {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--lightBlack);
}

.tooltip :global .ant-tooltip-inner {
  background: #2d4d72;
  max-width: 262px;
  min-width: 262px;
  font-size: 14px;
  font-family: Roboto, Arial, sans-serif;
  line-height: 16px;
  color: var(--white);
  padding: 12px 16px;
}

.tooltip.hidden {
  display: none;
}

.tooltip :global .ant-tooltip-arrow {
  transform: rotate(180deg);
}

.tooltip:global.ant-tooltip .ant-tooltip-content .ant-tooltip-arrow:before {
  background: #2d4d72;
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.titleWrapper img {
  width: 32px;
  height: 32px;
  margin: 0 16px;
}

.textTitle:global.ant-typography {
  display: block;
  margin: 0;
  color: var(--white);
  font-family: "Exo 2";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.background {
  background: var(--servicesSectionBG);
  position: relative;
  padding: 38px 0 10px;
}

.flowersImg {
  position: absolute;
  transform: rotate(180deg);
  top: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.container {
  max-width: 1170px;
  width: 100%;
  margin: auto;
  padding: 0px 15px 24px;
  position: relative;
  z-index: 2;
}

.title {
  margin-bottom: 32px;
}

.rowWrapper {
  display: flex;
}

.rowWrapper .row {
  flex: 0 1 50%;
  max-width: 50%;
}

.rowWrapper .row:not(:last-of-type) {
  margin-right: 33px;
}

/* col label title */
.formLabel {
  max-width: 29%;
}

/* input */
.formInput {
  min-width: 256px;
}

.formInputFull {
  width: 100%;
}

.formInputNumber {
  min-width: 178px;
}

.rowWrapInputs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  width: 100%;
}

.rowInputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.formItemNumber:global.ant-form-item:not(:last-of-type) {
  margin-right: 16px;
}

.formItem:global.ant-form-item:not(:last-of-type) {
  margin-right: 39px;
}

.formItemNumber:global.ant-form-item,
.formItem:global.ant-form-item {
  margin-bottom: 0;
}

/* button */
.buttonWrapper {
  justify-content: space-between;
  margin-bottom: 48px;
}

.massActionButtonsWrapper {
  display: flex;
  flex-direction: row;
}

.massActionButton {
  margin-right: 20px;
}

.rangeWrapper {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 275px;
  min-width: 275px;
  width: 100%;
}

.rangeUncontrolledDatePicker {
  max-width: 122px;
  width: 100%;
}

:global
  .ant-select-dropdown-menu
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
:global
  .ant-select-dropdown-menu
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(0, 174, 217, 0.2);
}

.rangeWrapper:global > .inputNumberWrapperError input {
  border-color: #ff6743;
}

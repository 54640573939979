.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
  padding: 10px;
}

.link {
  color: var(--buttonDarkBlue) !important;
}

.newButtonLink {
  display: inline-block;
}

.newButton:global.ant-btn {
  background-color: var(--limeGreen);
  height: 44px;
  font-weight: 600;
}

.newButton:global.ant-btn:hover,
.newButton:global.ant-btn:focus {
  background-color: var(--generalGreen);
}

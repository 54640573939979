.rowWrapper {
    display: flex;
}

.rowWrapper .row {
    flex: 0 1 50%;
    max-width: 50%;
}

.rowWrapper .row:not(:last-of-type) {
    margin-right: 33px;
}

/* col label title */
.formLabel {
    max-width: 145px;
}

/* input */
.formInput {
    min-width: 256px;
}

.formInputFull {
    min-width: 544px;
}

.formItemSsNumber1 {
    min-width: 198px;
}

.formItemSsNumber2 {
    max-width: 42px;
}

.formItemNumber:global.ant-form-item:not(:last-of-type) {
    margin-right: 16px;
}

.formItem:global.ant-form-item:not(:last-of-type) {
    margin-right: 39px;
}

.formItemNumber:global.ant-form-item,
.formItem:global.ant-form-item {
    margin-bottom: 0;
}

/* button */
.buttonWrapper {
    justify-content: space-between;
}

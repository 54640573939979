.textTitle:global.ant-typography {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--lightBlack);
}

.textSubtitle:global.ant-typography {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--lightBlack);
}

.textLabel:global.ant-typography {
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
  font-family: "Exo 2", Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--lightBlack);
}

.statisticsWrapper {
  display: flex;
  flex-direction: column;
}

.textStatistics {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: var(--lightBlack);
  display: flex;
  align-items: center;
}

.statisticsNumber {
  color: var(--limeGreen);
  display: block;
}

.statisticsText {
  display: block;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  line-height: 16px;
}

.blockWrapper {
  margin-top: 30px;
}

.textAreaWrapper {
  margin-top: 22px;
}

.buttonsWrapper > button:first-child {
  margin-right: 14px;
}

.modalInput {
  width: 100%;
  height: 70px;
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  color: var(--lightBlack);
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  resize: none;
}

.modalInput::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

/* Track */
.modalInput::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.modalInput::-webkit-scrollbar-thumb {
  position: relative;
  background: #b8b8b8;
  border-radius: 6px;
  border-right: 4px solid white;
  border-left: 4px solid white;
}

.errorModalInput {
  border: 1px solid var(--redDashboard);
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
  flex-wrap: wrap;
}
.button:global.ant-btn {
  max-width: 138px;
  min-width: 138px;
}

.spinnerWrapper {
  margin-top: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 360px) {
  .button:global.ant-btn {
    max-width: 125px;
    min-width: 125px;
  }
  .wrapper:global.ant-modal {
    width: 100%;
    margin: 0;
    max-width: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
  }
  .wrapper :global .ant-modal-content {
    height: 100vh;
    border-radius: 0;
  }
}

.background {
  min-height: 540px;
  background: var(--servicesSectionBG);
}

.newsWrapper {
  display: flex;
  margin-top: 37px;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  gap: 8px;
}

.categoriesTitle {
  font-family: 'Exo 2';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  text-align: left;
}

.paginationWrapper {
  margin-bottom: 37px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .categoriesContainer {
    display: none;
  }
}

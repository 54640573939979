.wrapper {
  font-family: "Exo 2", Roboto, Arial, sans-serif;
}

.list {
  list-style-type: none;
  white-space: nowrap;
  padding-left: 20px;
}

.list li {
  padding: 14px 8px;
  font-size: 16px;
  font-weight: 600;
}

.list li .parent,
.list li .parent:hover {
  color: rgba(255, 255, 255, 0.5);
}

.list ul li {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.button {
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.clickWrapper {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
}

.list .companySelectorWrapper:hover {
  color: var(--white);
}

.listItem {
  display: flex;
  flex-wrap: nowrap;
}

.listItem span {
  font-weight: 600;
}

.companySelector {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  line-height: 19px;
  font-weight: 500;
  padding: 0;
  color: var(--white);
}

.companySelector:hover {
  color: var(--limeGreen);
}

.icon {
  font-size: 18px;
  font-weight: 800;
  min-width: 20px;
  margin-left: 3px;
}

.wrapper li ul {
  padding-left: 50px;
}

.wrapper li ul li span {
  font-weight: 400;
}

.sidebarIcon {
  color: var(--dashboardIconBlue);
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: block;
  margin-right: 14px;
}

.selected .sidebarIcon {
  color: var(--limeGreen);
}

.multiLinkActive {
}
.multiLinkActive .sidebarIcon {
  color: var(--limeGreen);
}

.sidebarLoader {
  margin-top: 0;
  margin-right: 14px;
}
.sidebarLoader :global .ant-spin-dot-item {
  background-color: var(--dashboardIconBlue);
}
.listItem:hover .sidebarLoader :global .ant-spin-dot-item {
  background-color: var(--limeGreen);
}

.listItem a,
.listItem span {
  color: var(--white);
}

.selected a,
.selected span {
  color: var(--limeGreen);
}

.listItem:hover a,
.listItem:hover span,
.listItem:hover .sidebarIcon {
  color: var(--limeGreen);
}

@media screen and (min-width: 661px) {
  .companySelectorWrapper {
    display: none;
  }
}

.title {
    font-family: 'Exo 2', Roboto, Arial, sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    padding-left: 13px;
    border-left: 7px solid var(--success);
    color:var(--lightBlack);
    display: flex;
    align-items: center;
}

.title span{
    transform: translateY(-2px);
}

.default{
    color:var(--lightBlack);
}

.white{
    color:var(--white);
}
.rowWrapper {
  display: flex;
}

.rowWrapper .row {
  flex: 0 1 50%;
  max-width: 50%;
}

.rowWrapper .row:not(:last-of-type) {
  margin-right: 33px;
}

/* col label title */
.formLabel {
  max-width: 145px;
  display: flex;
  align-items: center;
}

/* input */
.formInput:global.ant-input {
  max-width: 256px;
}

.formInputFull:global.ant-input {
  max-width: 448px;
}

.formItemSsNumber1 {
  min-width: 198px;
}

.formItemSsNumber2 {
  max-width: 42px;
}

.formItemNumber:global.ant-form-item:not(:last-of-type) {
  margin-right: 16px;
}

.formItem:global.ant-form-item:not(:last-of-type) {
  margin-right: 39px;
}

.formItemGroup:global.ant-form-item:not(:last-of-type) {
  display: flex;
  flex-wrap: wrap;
}

.formItemNumber:global.ant-form-item,
.formItem:global.ant-typography {
  margin-bottom: 0;
}

/* button */
.buttonWrapper {
  justify-content: space-between;
}

/* radio */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
  border-color: var(--limeGreen);
  color: var(--limeGreen);
}
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
  border-color: var(--limeGreen);
  color: var(--limeGreen);
}

/* hover */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper:hover {
  color: var(--generalGreen);
}

/* radio checked */
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--limeGreen);
  border-color: var(--limeGreen);
  box-shadow: -1px 0 0 0 var(--limeGreen);
  color: var(--white);
}

/* radio checked hover */
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: var(--generalGreen);
  border-color: var(--generalGreen);
  color: var(--white);
  box-shadow: -1px 0 0 0 var(--generalGreen);
}

/* radio common styles */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper,
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  padding: 0 12px;
}

/* radio disabled */
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  background-color: var(--greyDisabled);
  border-color: var(--greyDisabled);
  color: var(--greyDisabledText);
  box-shadow: -1px 0 0 0 var(--greyDisabled);
}

.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
  background-color: var(--white);
  border-color: var(--greyDisabled);
  color: var(--textInTables);
  box-shadow: -1px 0 0 0 var(--greyDisabled);
}

.tooltipOverlay {
  margin-left: 16px;
}

.icon:global.anticon {
  margin-left: 6px;
  color: var(--success);
  cursor: pointer;
}

.formRow {
  display: flex;
  align-items: center;
}

.formItem.formRowMargin {
  margin-left: 32px;
  margin-bottom: 0;
}

.formCol + .formCol {
  margin-top: 8px;
}

.formItem:global.ant-typography {
  width: 100%;
  max-width: 544px;
}

.tooltipOverlay:global.ant-tooltip .ant-tooltip-inner {
  width: 450px;
  max-width: 450px;
  border-radius: 4px;
  background: var(--darkenBlue);
}

.tooltipOverlay:global.ant-tooltip.ant-tooltip-placement-top
  .ant-tooltip-arrow:before,
.tooltipOverlay:global.ant-tooltip.ant-tooltip-placement-topLeft
  .ant-tooltip-arrow:before,
.tooltipOverlay:global.ant-tooltip.ant-tooltip-placement-topRight
  .ant-tooltip-arrow:before {
  background-color: var(--darkenBlue);
}

.formInputBig:global.ant-input {
  max-width: 544px;
}

.formSelect:global.ant-select {
  width: 100%;
  max-width: 448px;
}

.formRow.formRowDouble :global .ant-input,
.formRow.formRowDouble .formItem:global.ant-row {
  width: 100%;
  max-width: 256px;
}

.tooltipText:global.ant-typography {
  display: block;
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  word-wrap: break-spaces;
}
.tooltipText + .tooltipText {
  margin-top: 15px;
}
@media screen and (max-width: 640px) {
  .formRow > div {
    width: 100%;
  }
  .radioGroup:global.ant-radio-group {
    width: 100%;
  }
  .radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
  .tooltipOverlay:global.ant-tooltip .ant-tooltip-inner {
    max-width: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .formLabel:global.ant-typography {
    max-width: 100%;
    width: 100%;
    margin-bottom: 4px;
  }

  .formItem {
    max-width: 100%;
    width: 100%;
  }

  .formInput:global.ant-input {
    max-width: 448px;
  }

  .formInputFull:global.ant-input {
    max-width: 448px;
  }

  .formRow.formRowDouble :global .ant-input,
  .formRow.formRowDouble .formItem:global.ant-row {
    width: 100%;
    max-width: 448px;
  }

  .formCol {
    flex-wrap: wrap;
  }

  .formRow.formRowDouble {
    flex-wrap: wrap;
  }

  .formItem.formRowMargin {
    margin-left: 0;
    margin-top: 10px;
  }

  .formSelect:global.ant-select {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}

.buttonWrapper {
  display: inline-flex;
  margin-top: 16px;
  gap: 16px;
}

.newBranch:global.ant-btn {
  width: 100%;
  min-width: 190px;
  padding: 0px;
  margin: 0px;
  margin-top: 8px;
}

.newBranch:global.ant-btn:hover {
  color: var(--generalBlue);
  background-color: transparent;
}

.select:global.ant-select {
    width: 256px;
    background: var(--white);
    border-radius: 4px;
}

.select:global.ant-select .ant-select-selection {
    /*border-color: var(--lines);*/
    color: var(--textInFields);
}

/* select focuse & active, hover*/
.select:global.ant-select .ant-select-selection:hover,
.select:global.ant-select .ant-select-focused .ant-select-selection,
.select:global.ant-select .ant-select-selection:focus,
.select:global.ant-select .ant-select-selection:active {
    border: 1px solid var(--text);
    box-shadow: none;
}

/* disabled */
.select:global.ant-select-disabled {
    background: #F4F7F9;
    border: 1px solid var(--disabled);
    box-sizing: border-box;
    border-radius: 4px;
}

/* disabled hover reset*/
.select:global.ant-select-disabled .ant-select-selection:hover {
    border-color: #F4F7F9;
}

/* disabled focus and active reset */
.select:global.ant-select-disabled .ant-select-focused .ant-select-selection,
.select:global.ant-select-disabled .ant-select-selection:focus,
.select:global.ant-select-disabled .ant-select-selection:active {
    border: none;
}

/* hint */
.hint:global.ant-select .ant-select-selection {
    color: var(--text);
}

/* arrow */
.select:global.ant-select .ant-select-arrow {
    color: var(--textInFields);
}
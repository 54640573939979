.page {
  background: var(--servicesSectionBG);
  min-height: 75vh;
}
.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

.contentRow {
  background: var(--white);
  border-radius: 6px;
  width: 100%;
  margin-top: 22px;
}

.filtersWrapper:global.ant-col {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filtersWrapper:global.ant-col:after {
  position: absolute;
  content: "";
  width: 99%;
  height: 1px;
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
  background: #dce5e7;
}

.dateSwitchAndMonthSelector {
  display: flex;
  align-items: center;
}

.currentYear {
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--lightBlack);
  display: flex;
  align-items: center;
}

.exercice {
  color: #959595;
  margin-right: 8px;
  margin-left: 8px;
}

.evolution {
  color: var(--darkGreyDashboard);
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-left: 18px;
  align-self: center;
}

.monthSelector {
  font-family: Roboto, Arial, sans-serif;
  margin-left: 40px;
}

.chartFilters {
  display: flex;
  align-items: center;
}

.cumulCheckbox {
  margin-right: 44px;
}

.chartLegends {
  margin-left: 150px;
}

.loaderWrapper:global.ant-col {
  padding: 25px 60px 50px 60px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorText {
  margin-top: 20px;
  color: #959595;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
}

.contentCol:global.ant-col {
  padding: 25px 60px 50px 60px;
}

.chartWrapper {
  height: 400px;
  padding: 23px;
}

.turnoverSummary {
  padding-left: 55px;
}

.updateDate {
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
  color: var(--darkGreyDashboard);
  margin-top: 16px;
  padding-bottom: 52px;
}

.updateDate span {
  color: var(--lightBlack);
}

.yearMonthSelector:global.ant-select {
  display: none;
}

.chartSelect:global.ant-select {
  display: none;
}

.tableFiltersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.tableTitle {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--lightBlack);
  display: block;
}

.tableFilter {
  display: flex;
  align-items: center;
}

.tableCumulCheckbox {
  margin-right: 50px;
}

.table:global.ant-table-wrapper {
  width: 100%;
  margin-top: 26px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.table :global .ant-table-tbody {
  background: white;
}

.table :global .ant-table-thead {
  background: #f8f8f8;
}

.table :global .ant-table-thead th {
  background: #f8f8f8;
}

.table :global .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.table :global .ant-table-tbody > tr > td {
  padding: 0;
  overflow-wrap: break-word;
}
.table :global .ant-table-tbody > tr > td:first-child {
  padding-left: 20px;
}

.rowName {
  max-width: 150px;
  white-space: normal;
  color: var(--lightBlack);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Roboto, Arial, sans-serif;
}

.monthColumnHeader {
  display: flex;
}

.table :global .ant-table-row-cell-break-word .ant-table-header-column {
  display: block;
  width: 100%;
}

.table :global .ant-table-row-cell-break-word {
  word-break: keep-all;
}

.table
  :global
  .ant-table-row-cell-break-word
  .ant-table-header-column
  .ant-table-column-title,
.table :global .ant-table-thead > tr > th .ant-table-header-column {
  display: flex;
  justify-content: flex-end;
  padding: 8px 20px 8px 0;
}

.table :global .ant-table-thead > tr > th,
.table :global .ant-table-tbody > tr > td {
  padding: 0;
}

.table :global .ant-table-fixed {
  table-layout: auto;
}

.table
  :global
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table
  :global
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table
  :global
  .ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table
  :global
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: white;
}

.table
  :global
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  .currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  .currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  .currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  .currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word {
  background: #f5faef;
}

.table
  :global
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  th.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  th.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  th.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word,
.table
  :global
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  :local
  th.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word {
  background: #f8f8f8;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  height: 7px;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-track {
  background-color: white;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background-color: var(--skyBlue);
  border-radius: 4px;
}

.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word {
  background: #f5faef;
}

th.currentMonth.monthValuesTdClass:global.ant-table-row-cell-break-word {
  background: #f8f8f8;
}

.monthValuesCell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 10px 20px 10px 0;
  height: 100%;
}

.annualValue {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 2px;
  display: flex;
}

.currentCellYear {
  color: #0aa9bc;
  font-size: 16px;
  line-height: 19px;
}

.prevCellYear {
  color: var(--limeGreen);
}

.prevPrevCellYear {
  color: #a7a7a9;
}

.monthValueNumber {
  display: block;
  margin-right: 5px;
}

.monthValueSuffix {
  display: block;
  font-size: 14px;
}

.emptyColumn {
  padding: 0 !important;
}

.errorButton {
  display: block;
  margin-top: 20px;
}

.displayNone {
  display: none;
}

@media screen and (max-width: 980px) {
  .chartWrapper {
    padding: 0;
  }
  .contentCol:global.ant-col {
    padding: 10px;
  }
}

@media screen and (max-width: 820px) {
  .chartFilters {
    margin-top: 10px;
    width: 100%;
    padding-left: 8px;
  }
  .chartLegends {
    margin-left: auto;
  }
}

@media screen and (max-width: 700px) {
  .chartWrapper {
    height: 200px;
  }
  .monthSelector {
    margin-left: auto;
  }
  .chartTabs {
    display: none;
  }
  .chartSelect:global.ant-select {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .contentRow:global.ant-row {
    margin-top: 5px;
  }
  .tableFiltersWrapper {
    display: none;
  }
  .table {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .chartFilters {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .chartLegends {
    margin-left: 0;
    margin-top: 5px;
  }
  .cumulCheckbox {
    margin-right: auto;
  }
  .chartViewSwitch {
    margin-right: 10px;
    margin-left: 10px;
  }
  .yearMonthSelector:global.ant-select {
    display: block;
    margin-right: 5px;
  }
  .yearMonthSwitcher {
    display: none;
  }
  .tableFiltersWrapper {
    flex-wrap: wrap;
  }
  .tableFilter {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
  .tableCumulCheckbox {
    margin-right: 0;
  }
}

.Title {
    font-family: 'Exo 2', Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 19px;
    color: var(--lightBlack);
    margin-bottom: 17px;
}

.TopicLabel {
    font-weight: 500;
}

.TopicSelect:global.ant-select {
    width: 100%;
    height: 40px;
    margin-bottom: 13px;
}

.Message {
    resize: none;
}

.buttonWrapper{
    display: flex;
    margin-top: 24px;
    padding: 0 11px;
    justify-content: flex-end;
}

.CancelButton{
    margin-right: 11px;
}

.button:global.ant-btn{
    max-width: 136px;
    min-width: 136px;
}

.card {
  background: var(--white);
  width: 360px;
  border-radius: 6px;
  color: var(--text);
  padding: 20px 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  transition: all 0.2s;
  min-height: 416px;
}

.card.isMinified {
  min-height: 338px;
  border: 1px solid #dce5e7;
}

.card.notPaid {
  background: #f8f8f8;
}

.card:hover {
  box-shadow: 7px 10px 22px rgba(0, 0, 0, 0.18);
  border: 1px solid #e5e4e4;
  transition: all 0.2s;
}

.multiLinkCard {
  cursor: auto;
}

.titleWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.titleWrapper img {
  width: 100%;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
}

.card.isMinified .titleWrapper img {
  max-width: 67px;
  max-height: 67px;
  min-height: 67px;
}

.title {
  font-family: 'Exo 2', Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
}

.titleBox {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}

.subtitle {
  margin-top: 7px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  display: block;
  color: #87888a;
}

.costSubtitle {
  display: block;
  margin-top: 4px;
  color: #009d85;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.titleCostEuro {
  font-size: 18px;
  margin-right: 4px;
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.buttonsWrapper button {
  display: flex;
}

.mainButton:global.ant-btn {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-right: 5px;
}

.lightBlueButton:global.ant-btn {
  background: #0aa9bc;
}

.lightBlueTransparentButton:global.ant-btn {
  color: var(--buttonDarkBlue);
  font-size: 14px;
  font-weight: 700;
  max-width: 122px;
  min-width: 122px;
}

.lightBlueTransparentButton:global.ant-btn:hover,
.lightBlueTransparentButton:global.ant-btn:active {
  color: var(--buttonLightBlue);
}

.accessButtonSpinner {
  margin: 0;
}
.accessButtonSpinner :global .ant-spin {
  height: 20px;
}

@media screen and (max-width: 404px) {
  .buttonsWrapper {
    justify-content: center;
  }
  .buttonsWrapper button {
    margin-bottom: 5px;
  }
}

/* col label title */
.formLabel {
    max-width: 345px;
}

.formItem:global.ant-form-item:not(:last-of-type) {
    margin-right: 32px;
}

.formItemNumber:global.ant-form-item,
.formItem:global.ant-form-item {
    margin-bottom: 0;
}

/* button */
.buttonWrapper {
    justify-content: space-between;
}

.turnoverDashboardWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
}

.turnoverDashboard {
  min-width: 335px;
  flex-shrink: 1;
}
.treasuryChart {
  min-width: 335px;
  flex-shrink: 1;
}

.pillarLoader {
  margin-bottom: 24px;
}

.treasuryDashboardWrapper {
  margin: 30px 0;
}

.turnoverForecastWrapper {
  max-width: 363px;
  min-width: 290px;
  width: 100%;
  background: var(--white);
  height: auto;
  padding: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 15px;
  flex-shrink: 2;
}

.forecastDescription {
  color: var(--darkGreyDashboard);
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.forecastButtonLink {
  margin: 38px auto 0;
  max-width: max-content;
  display: block;
}

@media screen and (max-width: 850px) {
  .turnoverDashboardWrapper {
    flex-direction: column;
  }

  .turnoverDashboard {
    width: 100%;
  }

  .treasuryDashboard {
    flex-direction: column;
  }
  .treasuryDashboardWrapper {
    margin: 15px 0;
  }
  .turnoverDashboardWrapper {
    margin: 15px 0;
  }
}

.wrapper {
  width: 264px;
  border-radius: 8px;
  color: var(--white);
  padding: 20px 0 15px 16px;
  text-decoration: none;
  display: block;
}
.wrapper:hover,
.wrapper:active {
  color: var(--white);
}

.wrapper.BALANCE {
  background: #0aa9bc;
}
.wrapper.DEBTOR {
  background: var(--limeGreen);
}
.wrapper.CREDITOR {
  background: #ffc000;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  position: relative;
  margin-right: 10px;
}

.icon img {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 2;
}

.icon:before {
  position: absolute;
  z-index: 1;
  content: "";
  opacity: 0.3;
  border-radius: 9999px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.icon.BALANCE:before {
  background: #007a89;
}
.icon.DEBTOR:before {
  background: #5ca407;
}
.icon.CREDITOR:before {
  background: #f57228;
}
.icon.CREDITOR img {
  transform: rotate(180deg);
}

.descriptionWrapper {
  display: flex;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
}

.descriptionText {
  overflow: hidden;
}

.arrow {
  width: 16px;
  height: 16px;
}

.amount {
  font-weight: 700;
  font-family: "Exo 2", Arial, sans-serif;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 1100px) {
  .wrapper.BALANCE .descriptionText {
    font-size: 13px;
  }
}

.titleAndViewAll {
  display: flex;
  justify-content: space-between;
}

.viewAllButton {
  max-width: none !important;
}

.lastNewsWrapper {
  display: flex;
  margin-top: 16px;
  gap: 24px;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  .lastNewsWrapper {
    display: flex;
    flex-direction: column;
  }
}

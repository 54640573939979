.Icon:global.anticon-download,
.Icon:global.anticon-export,
.Icon:global.anticon-euro,
.Icon:global.anticon-mail {
  color: var(--darkBlue);
}

.Icon:global.anticon-edit {
  color: var(--textInTables);
}
.Icon:global.anticon-check-circle {
  color: var(--limeGreen);
}

.Icon:global.anticon-check-close {
  color: var(--lightBlack);
}

.Icon.disabled {
  color: var(--disabled);
}

.Icon:global.anticon-download,
.Icon:global.anticon-exception,
.Icon:global.anticon-file-done,
.Icon:global.anticon-euro,
.Icon:global.anticon-mail {
  margin-left: 22px;
}

.spinSubstitute :global .ant-spin {
  margin-left: 22px;
}

.Normal:global.anticon-exception {
  color: var(--orange);
}
.Overdue:global.anticon-exception {
  color: var(--red);
}

.Normal:global.anticon-file-done {
  color: var(--limeGreen);
}
.Overdue:global.anticon-file-done {
  color: var(--yellow);
}

.IconDisabled:global.anticon-euro,
.IconDisabled:global.anticon-exception,
.IconDisabled:global.anticon-file-done {
  color: var(--disabled);
}

.paymentIcon-readyForPayment:global.anticon-euro {
  color: var(--accent);
}

.paymentIcon-accepted:global.anticon-euro {
  color: var(--limeGreen);
}

.paymentIcon-rejected:global.anticon-euro {
  color: var(--red);
}

.paymentIcon-inProgress:global.anticon-euro {
  color: var(--yellow);
}

.errorModalText {
  color: var(--white);
}

.noWrapCell {
  white-space: nowrap;
}

.table tr.red {
  color: var(--red);
}

.table td:nth-of-type(2) {
  color: var(--lightBlack);
}

.table:global.ant-table-wrapper
  div.ant-pagination-options-size-changer.ant-select {
  width: 100px;
}

.table:global.ant-table-wrapper a {
  color: var(--buttonDarkBlue);
  font-weight: 500;
}

.title {
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 24px;
  line-height: 28.8px;
  color: var(--lightBlack);
}

.tooltipExport:global.ant-tooltip .ant-tooltip-content {
  margin-left: -9px;
}

.tooltipExport:global.ant-tooltip .ant-tooltip-arrow {
  left: -2px;
}

.tooltipDownload:global.ant-tooltip .ant-tooltip-content {
  margin-left: 12px;
}

.tooltipDownload:global.ant-tooltip .ant-tooltip-arrow {
  left: 19px;
}

.tooltipSendByEmail:global.ant-tooltip .ant-tooltip-arrow {
  right: 5px;
}

.tooltipSendByEmail:global.ant-tooltip .ant-tooltip-content {
  margin-right: -7px;
}

.checkbox:global.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.checkbox:global.ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--lines);
  border: 1px solid rgba(88, 88, 90, 0.3);
}

.checkbox:global.ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: 1px solid var(--textInTables);
}

.table {
  width: 100%;
}

input[type='text'].input {
  border-color: transparent;
  background: transparent;
}

input[type='text'].input:focus {
  border-color: #d9d9d9;
  background: white;
}

.clientsTable {
  padding: 10px;
  border-radius: 6px;
  width: 100%;
}

.clientCell {
  color: var(--buttonDarkBlue);
}

.tableFilters {
  position: absolute;
  right: 12px;
  top: 5px;
  display: flex;
  flex-wrap: nowrap;
}

.filterName {
  font-weight: 400;
  color: var(--darkGreyDashboard);
  margin-right: 10px;
}

.totalName,
.totalCell {
  color: var(--lightBlack);
  font-weight: 700;
}

.table:global.ant-table-wrapper th .ant-table-header-column {
  color: var(--lightBlack);
}

.table:global.ant-table-wrapper .ant-table-thead tr > th {
  background: var(--tableHead);
}

.table:global.ant-table-wrapper {
  background: white;
}

.table :global .ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: transparent;
}

.table:global.ant-table-wrapper .ant-table-tbody tr > td {
  border-top: 1px solid var(--linesTable);
}

.table:global.ant-table-wrapper .ant-table-thead tr > th {
  border: 0;
}

.table:global.ant-table-wrapper div.ant-table-footer {
  background: white;
  padding: 10px 16px;
  border-top: 1px solid var(--linesTable);
}

.tableFooter {
  display: flex;
  place-content: space-between;
}

.table:global.ant-table-wrapper ul.ant-table-pagination {
  float: right;
  margin: 16px 20px;
}

@media screen and (max-width: 700px) {
  .title {
    margin-bottom: 75px;
  }
  .tableFilters {
    left: 15px;
    top: 50px;
    display: flex;
    flex-wrap: wrap;
  }
}

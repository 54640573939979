.wrapper {
  position: absolute;
  top: 0;
  left: calc(100% + 13px);
  background: white;
  width: 445px;
  padding: 14px 21px 14px 15px;
  border-radius: 6px;
  display: flex;
  gap: 6px;
  align-items: center;
  box-shadow: -1px 3px 12px rgba(0, 0, 0, 0.1);
}

.wrapper:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 20%;
  border-right: 7px solid var(--white);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.wrapper img {
  max-width: 14px;
  width: 100%;
  height: auto;
}

.text {
  font-family: Roboto, Arial, sans-serif;
  color: var(--lightBlack);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.returnLink {
  color: var(--success);
}

.returnLink:hover {
  color: var(--success);
  opacity: 0.7;
}

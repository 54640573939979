.functionalWrapper {
  margin-bottom: 20px;
}

.multiLinkFunctionalWrapper {
  margin-bottom: 0;
}

.functionalItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.functionalItem span {
  display: block;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-right: 15px;
}

.functionalItem img {
  width: 100%;
  height: auto;
  max-width: 17px;
  max-height: 17px;
  margin-right: 5px;
  transform: translateY(1px);
}

.LinkAndTextWrapper {
  display: flex;
  flex-direction: column;
}

.LinkAndTextWrapper a {
  font-family: "Exo 2", Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--limeGreen);
  text-decoration: underline;
  margin-top: 5px;
  width: max-content;
  width: -moz-max-content;
}

.LinkAndTextWrapper a:active,
.LinkAndTextWrapper a:hover {
  text-decoration: none;
  color: var(--limeGreen);
}

@media screen and (max-width: 404px) {
  .functionalWrapper {
    margin-bottom: 20px;
  }
}

/*Main logo*/
.headline {
  color: var(--emeraldGreen);
  font-size: 16px;
  font-weight: 700;
  font-family: "Exo 2", Arial, sans-serif;
  line-height: 22px;
}
.headline:hover {
  color: var(--lightEmeraldGreen);
}

.header {
  border-top: 1px solid var(--lines);
  padding: 10px !important;
  flex-wrap: wrap;
}

.headerMenu:global.ant-menu {
  margin-left: 26px;
  flex-wrap: wrap;
  padding: 0 !important;
}

/* menu item */
.menuItem:global.ant-menu-item:hover,
.menuItem:global.ant-menu-submenu:hover,
.menuItem:global.ant-menu-submenu-active,
.menuItem:global.ant-menu-submenu,
.menuItem:global.ant-menu-item,
.menuItem:global.ant-menu-item-open,
.menuItem:global.ant-menu-item-selected,
.menuItem:global.ant-menu-submenu-selected {
  font-family: "Exo 2", Arial, sans-serif;
  /*color: var(--limeGreen);*/
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  padding: 0 7px;
  font-size: 12px;
  line-height: 14px;
}

@media screen and (max-width: 1140px) {
  .menuItem:global.ant-menu-item:hover,
  .menuItem:global.ant-menu-submenu:hover,
  .menuItem:global.ant-menu-submenu-active,
  .menuItem:global.ant-menu-submenu,
  .menuItem:global.ant-menu-item,
  .menuItem:global.ant-menu-item-open,
  .menuItem:global.ant-menu-item-selected,
  .menuItem:global.ant-menu-submenu-selected {
    padding: 0 7px;
  }
}

.menuItem:global.ant-menu-item a:hover,
.menuItem:global.ant-menu-submenu a:hover,
.menuItem:global.ant-menu-item-selected a,
.menuItem:global.ant-menu-submenu-active .ant-menu-submenu-title,
.menuItem:global.ant-menu-item-open .ant-menu-submenu-title {
  color: var(--limeGreen);
}

.btn:global.ant-btn {
  height: 32px;
  width: auto;
  line-height: 1;
  margin-left: 10px;
  padding: 9px 13px;
}
@media screen and (max-width: 1100px) {
  .btn:global.ant-btn {
    width: auto;
  }
}

@media screen and (max-width: 796px) {
  .header {
    display: none;
  }
}

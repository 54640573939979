.wrapper {
  display: flex;
}

.wrapper.vertical {
  flex-direction: column;
  border-left: 1px solid var(--lightGreyDashBoard);
  margin-left: 20px;
}

.wrapper.vertical .monthDataWrapper {
  margin-bottom: 20px;
}

.monthDataWrapper {
  margin-right: 45px;
}

.title {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--lightBlack);
  margin-bottom: 5px;
}

.value {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.valueNumber {
  display: block;
  margin-left: 7px;
}

.current {
  color: var(--lightBlack);
}

.prev {
  color: #959595;
}

.prevPrev {
  color: #b8b8b8;
}

.valueWrapper {
  display: flex;
  width: 100%;
}

.differenceWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  justify-self: flex-end;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}

.arrow svg {
  font-size: 12px;
}

.diffValue {
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.differenceWrapper.negative .arrow {
  transform: rotate(180deg);
}

.differenceWrapper.positive .arrow svg,
.differenceWrapper.positive .diffValue {
  color: var(--emeraldGreen);
}

.differenceWrapper.negative .arrow svg,
.differenceWrapper.negative .diffValue {
  color: var(--redDashboard);
}

@media screen and (max-width: 450px) {
  .wrapper {
    flex-direction: column;
    margin-left: 20px;
  }

  .wrapper .monthDataWrapper {
    margin-bottom: 20px;
  }
  .monthDataWrapper {
    margin-right: 0;
  }
}

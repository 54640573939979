/* VERTICAL CHART TABS ----------------------------------------------------------------*/
.wrapper {
  background: #f8f8f8;
  width: 380px;
  min-height: 340px;
  max-height: 400px;
  min-width: 290px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.tabItem {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 21px;
  cursor: pointer;
  border-left: 4px solid transparent;
}

.tabItem:last-child {
  border-bottom: none;
}

.tabItem:hover .tabName,
.tabItem:hover .tabValue {
  color: var(--lightGreyDashBoard);
}

.tabItem.active {
  border-left: 4px solid var(--limeGreen);
  background: var(--white);
}

.tabItem.active .tabName {
  font-weight: 500;
}

.tabItem.active .tabValue {
  font-size: 21px;
}

.tabItem.active:hover .tabName,
.tabItem.active:hover .tabValue {
  color: var(--lightBlack);
}

.tabName {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--lightBlack);
  max-width: 200px;
}

.fullTabNamePopup {
  display: none;
}

.tabValue {
  font-family: "Exo 2", Arial, sans-serif;
  color: var(--lightBlack);
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  white-space: nowrap;
}

.greenDot {
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  background: var(--limeGreen);
  margin-right: 7px;
  border-radius: 9999px;
  display: none;
}

.aloneTab {
  align-self: center;
}

.aloneTab .tabName {
  max-width: 120px;
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--lightBlack);
}

.aloneTab .tabValue {
  text-align: center;
  margin-bottom: 24px;
  font-size: 21px;
  line-height: 25px;
  color: var(--lightBlack);
}

.selectTabItem {
  padding: 0 24px 0 0;
  justify-content: space-between;
}

.selectTabItem:hover .tabName {
  color: var(--limeGreen);
}

.selectTabItem:hover .tabValue {
  color: var(--lightGreyDashBoard);
}

.selectTabItem.active:hover .tabName,
.selectTabItem.active:hover .icon {
  color: var(--limeGreen);
}

.selectTabItem .tabValue {
  color: var(--lightBlack);
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}
.selectTabItem.active .tabValue {
  font-size: 21px;
}

.select {
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 200px;
  min-width: 150px;
  padding: 0 24px 0 21px;
}

.select .icon {
  color: var(--limeGreen);
  margin-left: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.select .tabName {
  color: var(--limeGreen);
  font-weight: 500;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  width: 100%;
}

.select .tabNameText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
}

.select:hover .tabName,
.select:hover .icon {
  color: var(--lightGreyDashBoard);
}

.select:global.ant-dropdown-open:hover :local .tabName,
.select:global.ant-dropdown-open :local .tabName {
  color: var(--lightGreyDashBoard);
}

.select:global.ant-dropdown-open :local .icon {
  color: var(--lightGreyDashBoard);
  transform: rotate(180deg);
  transition: all 0.2s;
}

.optionTabItem {
  border-bottom: none;
  padding: 10px 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.optionTabItem:hover .tabName,
.optionTabItem:hover .tabValue,
.optionTabItem.active .tabName,
.optionTabItem.active .tabValue {
  color: var(--limeGreen);
}

.optionTabItem .tabName {
  border-bottom: none;
  font-weight: 500;
  color: #575756;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.optionTabItem .tabValue {
  border-bottom: none;
  font-size: 15px;
  line-height: 18px;
  color: #575756;
}

.popup {
  width: 365px;
  max-height: 240px;
  overflow: auto;
  background: var(--white);
  transform: translate(3px, 4px);
  z-index: 6;
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
}

.popup::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

/* Track */
.popup::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.popup::-webkit-scrollbar-thumb {
  position: relative;
  background: #b8b8b8;
  border-radius: 6px;
  border-right: 4px solid var(--white);
  border-left: 4px solid var(--white);
}

@media screen and (max-width: 1150px) {
  .popup {
    width: 270px;
  }
  .optionTabItem .tabName {
    max-width: 100px;
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    width: 100%;
    background: var(--white);
    border-top-right-radius: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    max-height: 250px;
    min-height: 250px;
  }

  .select {
    max-width: 300px;
    min-width: 100px;
  }

  .optionTabItem .tabName {
    max-width: 300px;
    min-width: 100px;
    padding-right: 10px;
  }
  .optionTabItem .tabValue {
    margin-left: auto;
  }
  .tabItem {
    border-bottom: none;
  }

  .tabItem:last-child {
    border-bottom: none;
  }

  .tabItem:hover .tabName,
  .tabItem:hover .tabValue {
    color: black;
  }
  .tabItem:hover .tabValue {
    font-weight: 700;
    font-size: 21px;
    color: var(--spinfiText);
  }

  .tabItem.active {
    border-left: 4px solid transparent;
    background: var(--white);
  }

  .tabItem.active .tabName {
    font-weight: 500;
    color: black;
  }

  .tabItem.active .tabValue {
    font-size: 21px;
    font-weight: 700;
  }

  .tabItem.active:hover .tabName {
    color: black;
  }
  .tabItem.active:hover .tabValue {
    color: var(--spinfiText);
  }
  .tabName {
    font-weight: 500;
    line-height: 18px;
    color: var(--spinfiText);
  }

  .tabValue {
    color: var(--spinfiText);
    font-weight: 500;
  }
  .selectTabItem {
    padding: 0 24px 0 0;
    justify-content: space-between;
  }
  .selectTabItem .select .tabName {
    color: var(--limeGreen);
  }
  .selectTabItem .tabValue {
    font-weight: 500;
  }
  .selectTabItem:hover .tabName {
    color: var(--limeGreen);
  }
  .selectTabItem.active:hover .tabName {
    color: var(--limeGreen);
  }
  .select:hover .tabName,
  .select:hover .icon {
    color: black;
  }
  .select:global.ant-dropdown-open:hover :local .tabName,
  .select:global.ant-dropdown-open :local .tabName {
    color: black;
  }

  .popup {
    display: flex;
    width: 500px;
    min-width: 100px;
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .popup {
    width: 290px;
  }
}

/* HORIZONTAL CHART TABS ----------------------------------------------------------------*/
.horizontal {
  flex-direction: row;
  width: 100%;
  height: 82px;
  min-height: auto;
  border-radius: 0;
  background: var(--servicesSectionBG);
  overflow: visible;
  justify-content: flex-start;
}

.horizontal .tabItem {
  height: 82px;
  padding: 17px 20px;
  flex-direction: column;
  border-bottom: none;
  align-items: flex-start;
  max-width: 186px;
  min-width: 100px;
}
.horizontal .tabItem:hover .tabValue {
  font-size: 14px;
}

.horizontal .fullWidthTabName {
  width: 100%;
  max-width: max-content;
  padding: 17px 15px 17px 15px;
}

.horizontal .fullWidthTabName .tabName {
  font-size: 14px;
  width: 100%;
  max-width: none;
}

.horizontal .fullWidthTabName .tabNameText {
  text-overflow: initial;
  overflow: auto;
  white-space: normal;
  display: block;
}

.horizontal .tabItem.active {
  border-left: 4px solid transparent;
  border-radius: 6px 6px 0 0;
}

.horizontal .tabItem.active .tabName {
  color: var(--limeGreen);
}

.horizontal .fullWidthTabName.active .tabName {
  font-size: 16px;
}

.horizontal .tabItem.active .tabValue {
  font-size: 14px;
}

.horizontal .tabName {
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.horizontal .tabName .fullTabNamePopup {
  position: absolute;
  max-width: 250px;
  padding: 5px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  top: calc(100% + 35px);
  background: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 1);
  left: 10px;
  visibility: hidden;
  z-index: 3;
  display: block;
  word-wrap: break-word;
  white-space: normal;
  color: var(--darkGreyDashboard);
}

.horizontal .tabItem:hover .tabName .fullTabNamePopup {
  visibility: visible;
  transition-delay: 1s;
}

.horizontal .tabNameText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.horizontal .tabItem.active .tabName {
  font-weight: 700;
}

.horizontal .tabItem.active .greenDot {
  display: block;
}

.horizontal .tabValue {
  color: var(--darkGreyDashboard);
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.horizontal .tabItem.active:hover .tabValue {
  color: var(--darkGreyDashboard);
}

.horizontal .aloneTab {
  background: white;
  width: 294px;
  height: 100%;
  margin-right: auto;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 20px;
}

.horizontal .aloneTab .tabName {
  max-width: none;
  order: -1;
  color: var(--limeGreen);
  display: flex;
  font-weight: 700;
  font-size: 16px;
}

.horizontal .aloneTab .greenDot {
  display: block;
}

.horizontal .aloneTab .tabValue {
  margin: 5px 0 0 0;
}

.horizontal .select {
  padding: 0;
  width: max-content;
  max-width: 100%;
  justify-content: flex-end;
  min-width: auto;
}

.horizontal .selectTabItem {
  align-items: flex-end;
}

.horizontal .select .tabName {
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  min-width: 50px;
}

.horizontalPopup {
  transform: translate(20px, 25px);
  width: 287px;
}

.horizontalPopup .optionTabItem .tabName {
  max-width: 100px;
}

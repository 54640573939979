.wrapper{
    display: flex;
    font-family: Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.text{
    color: var(--darkGreyDashboard);
    margin-right: 8px;
}

.currentYear{
    color:var(--text);
}
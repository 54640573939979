.tooltip {
  white-space: pre-line;
}

.tooltip:global.ant-tooltip .ant-tooltip-arrow {
  transform: translateX(-40px);
}

.fileItem {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 100px;
  padding: 0px 14px 0px 14px;
}

.fileItem + .fileItem {
  margin-top: 8px;
}

.fileName {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #58585a;
  margin-left: 6px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.download.fileName {
  cursor: pointer;
}

.fileIcon {
  z-index: 10;
}

.disabled,
.fileIcon.disabled {
  color: var(--disabled) !important;
}

.inputWrapper {
  width: 100%;
}

.input {
  border: 1px solid #e3e3e3;
  outline: none;
  background: white;
  color: #242428;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  width: 520px;
  min-width: 100px;
  height: 32px;
  margin-left: -28px;
  margin-right: -72px;
  padding-left: 33px;
  padding-right: 72px;
  line-height: 32px;
  border-radius: 100px;
}

.input:disabled {
  background: white;
  border: 1px solid #f2f2f2;
}

.inputError {
  border: 1px solid var(--red);
}

.editIcon:global.anticon {
  position: relative;
  color: var(--darkBlue);
  margin-left: 5px;
  cursor: pointer;
}

.checkIcon:global.anticon {
  position: relative;
  color: var(--success);
  margin-left: 5px;
  cursor: pointer;
}

.closeIcon:global.anticon {
  position: relative;
  color: var(--red);
  margin-left: 5px;
  cursor: pointer;
}
.removeIcon:global.anticon {
  position: relative;
  color: var(--darkBlue);
  cursor: pointer;
  margin-left: 5px;
}

@media screen and (max-width: 600px) {
  .input {
    margin-left: -27px;
    margin-right: -85px;
    padding-right: 85px;
  }

  .editIcon:global.anticon {
    margin-left: 10px;
    font-size: 16px;
  }
  .editIcon::after {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: -8px;
    left: -8px;
  }

  .checkIcon:global.anticon {
    margin-left: 10px;
    font-size: 16px;
  }
  .checkIcon::after {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: -8px;
    left: -8px;
  }

  .closeIcon:global.anticon {
    margin-left: 10px;
    font-size: 16px;
  }
  .closeIcon::after {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: -8px;
    left: -8px;
  }

  .removeIcon:global.anticon {
    margin-left: 10px;
    font-size: 16px;
  }
  .removeIcon::after {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: -8px;
    left: -8px;
  }
}

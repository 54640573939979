.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
  padding: 10px;
}

.pageTitle:global.ant-typography {
  display: flex;
  justify-content: space-between;
  font-family: 'Exo 2', Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--spinfiText);
  margin-bottom: 25px;
}

.titleButtonsWrapper {
  display: flex;
}

.downloadButton:global.ant-btn {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', Arial, sans-serif;
  color: var(--lightBlack);
  margin-right: 16px;
}

.downloadButton:global.ant-btn:hover {
  background-color: var(--secondary);
  color: var(--lightBlack);
}

.downloadIcon:global.anticon-download {
  font-size: 18px;
  color: var(--limeGreen);
}

.filtersRow {
  display: flex;
}

.select:global.ant-select {
  width: 302px;
  margin-left: 8px;
}

.searchInput:global.ant-input-affix-wrapper {
  max-width: 268px;
  margin-left: 8px;
}

.searchIconPrefix svg {
  fill: #e3e3e3;
}

.checkBox {
  margin-left: 30px;
}

.table:global.ant-table-wrapper .ant-table-thead th span {
  min-width: 16px;
}

.table:global.ant-table-wrapper {
  width: 100%;
  margin-top: 26px;
  border-top: 1px solid #e3e3e3;
  padding-top: 10px;
}

.table:global.ant-table-wrapper .ant-table-thead th {
  padding: 0;
}

.table:global.ant-table-wrapper .ant-pagination {
  width: 100%;
}

.table:global.ant-table-wrapper .ant-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
}

.table:global.ant-table-wrapper .ant-pagination-prev {
  margin-left: auto;
}

.table:global.ant-table-wrapper .ant-pagination .ant-pagination-total-text {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  color: var(--spinfiText);
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.table:global.ant-table-wrapper .ant-table-placeholder {
  height: 258px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table:global.ant-table-wrapper
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table:global.ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table:global.ant-table-wrapper
  .ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.table:global.ant-table-wrapper
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: white;
}

.table:global.ant-table-wrapper .ant-table-row-cell-break-word {
  padding: 0;
}

.table:global.ant-table-wrapper .ant-table-row-cell-break-word:last-child {
  padding: 0;
}

.table:global.ant-table-wrapper .ant-table-tbody .ant-table-row:hover td {
  background: white;
}

.table:global.ant-table-wrapper .ant-table-thead th span {
  width: 100%;
  display: block;
}
.table:global.ant-table-wrapper .ant-table-thead th {
  background: white;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  height: 7px;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-track {
  background-color: white;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background-color: var(--skyBlue);
  border-radius: 4px;
}

.table:global.ant-table-wrapper .ant-table-fixed-left {
  z-index: 10;
}

.overflowHidden.table:global.ant-table-wrapper
  .ant-table-body::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 4px;
}

.tablePlaceholder {
  width: 100%;
  height: 268px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 30px;
}

.iconWrapper {
  margin-bottom: 20px;
}

.iconWrapper svg {
  font-size: 31px;
  color: #e3e3e3;
}

.tablePlaceholderText {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--spinfiText);
}

.calendarCell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  cursor: pointer;
  border-left: 1px solid #e3e3e3;
  position: relative;
}

.calendarCell:hover {
  background: #e3e3e3;
}

.sundayCell {
  background: #f4f7f9;
}

.holidayCell {
  background: #fef7eb;
}

.vacation:after {
  position: absolute;
  content: '';
  width: calc(100% + 1px);
  background: #40c97a;
  opacity: 0.6;
  left: -1px;
  z-index: 2;
}
.vacation {
  background: white;
}

.calendarAttachment {
  width: 24px;
  position: absolute;
  height: calc(50% + 1px);
  left: 3px;
  top: -1px;
}
.calendarAttachmentBottom {
  width: 24px;
  position: absolute;
  height: calc(50% + 1px);
  left: 3px;
  bottom: -1px;
}

.calendarAttachmentLoaderWrapper > div {
  margin-top: 0;
}

.calendarAttachmentLoaderWrapper:hover > div {
  background: #e3e3e3;
}

.calendarAttachment:hover {
  background: #e3e3e3;
}

.calendarCellTop:after {
  height: calc(50% + 1px);
  top: -1px;
}

.calendarCellBottom:after {
  height: calc(50% + 1px);
  bottom: -1px;
}

.calendarCellWhole:after {
  height: calc(100% + 1px);
  bottom: -1px;
}

.absence:after {
  position: absolute;
  content: '';
  width: calc(100% + 1px);
  background: #d5241c;
  opacity: 0.6;
  left: -1px;
  z-index: 2;
}
.absence {
  background: white;
}

.crossedAbsence:after {
  position: absolute;
  content: '';
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  background: yellow;
  opacity: 0.2;
  left: -1px;
  top: -1px;
  z-index: 2;
}
.crossedAbsence {
  background: white;
}

.disabledCell {
  overflow: hidden;
  cursor: not-allowed;
}

.disabledCell::after {
  position: absolute;
  content: '';
  height: 200%;
  width: 1px;
  background: var(--textInTables);
  transform: rotate(33deg);
}

.disabledCell::before {
  position: absolute;
  content: '';
  height: 200%;
  width: 1px;
  background: var(--textInTables);
  transform: rotate(-33deg);
}

.yearTodayCell {
  border: 1px solid var(--regularBlue);
}

.todayCell:before {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  bottom: -1px;
  width: 1px;
  background: #0aa9bc;
  z-index: 3;
}

.nameColumn:global.ant-table-row-cell-ellipsis {
  margin: 0;
  background: white;
  position: relative;
  z-index: 4;
}

.userNameCell {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  max-width: 160px;
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 4;
  background: white;
  height: 52px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.userNameCellTruncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.overlay {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 156px;
  padding: 17px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.overlayEvent:global.ant-typography {
  color: var(--limeGreen);
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto, Arial, sans-serif;
  display: block;
  cursor: pointer;
}

.searchAndCheckbox {
  display: flex;
  flex-grow: 1;
}

.monthPickerAndSelect {
  display: flex;
}

.onlyAbsencesSwitch {
  display: none;
}

.iconsWrapper {
  display: none;
  flex-wrap: wrap;
  align-items: center;
}

.filterIconWrapper {
  position: relative;
}

.filterIconBadge {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--redDashboard);

  position: absolute;
  top: 2px;
  right: -5px;
}

.searchIconWrapper {
  display: flex;
  margin-left: 15px;
}
.searchIcon svg {
  width: 19px;
  height: 19px;
  fill: var(--textInTables);
}

.searchIcon.completed:global.anticon-check-circle {
  color: var(--limeGreen);
}

.filterIconBadge {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--redDashboard);

  position: absolute;
  top: 2px;
  right: -5px;
}

.searchInputMobile:global.ant-input-affix-wrapper {
  margin-bottom: 10px;
}

.reasonTextWrapper:global.ant-typography {
  max-width: 200px;
  white-space: normal;
  word-break: normal;
  display: block;
}

.reasonText {
  margin-left: 5px;
}

@media screen and (max-width: 1050px) {
  .filtersRow {
    flex-wrap: wrap;
  }
  .monthPickerAndSelect {
    width: 100%;
  }
  .searchAndCheckbox {
    width: 100%;
    margin-top: 15px;
  }
  .searchInput:global.ant-input-affix-wrapper {
    margin-left: 0;
  }
  .select:global.ant-select {
    margin-left: 30px;
  }
}

@media screen and (max-width: 650px) {
  .iconsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: 15px;
  }
  .select:global.ant-select {
    display: none;
  }
  .searchInput:global.ant-input-affix-wrapper {
    display: none;
  }
  .onlyAbsencesSwitch {
    display: none;
  }
  .monthPicker {
    max-width: none;
    width: 100%;
    min-width: 50px;
  }
  .checkBox {
    display: none;
  }
  .monthPicker :global .ant-calendar-picker > div {
    padding: 0;
  }
  .table:global.ant-table-wrapper {
    margin-top: 0;
  }
  .userNameCell {
    max-width: 140px;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .table:global.ant-table-wrapper .ant-pagination {
    justify-content: flex-start;
  }
  .table:global.ant-table-wrapper .ant-pagination-prev {
    margin-top: 10px;
    margin-left: 0;
  }
  .table:global.ant-table-wrapper .ant-pagination .ant-pagination-total-text {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .table:global.ant-table-wrapper .ant-pagination {
    justify-content: center;
  }
  .table:global.ant-table-wrapper .ant-pagination.mini .ant-pagination-prev {
    margin-top: 10px;
    margin-left: 0;
  }
  .table:global.ant-table-wrapper .ant-pagination .ant-pagination-total-text {
    width: 100%;
    justify-content: center;
  }
}

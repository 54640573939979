.container {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin-top: 34px;
    padding: 30px 20px;
    border-radius: 6px;
    min-height: 255px;
    font-family: Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.col:global.ant-col {
    padding: 0 20px;
}

.rowText:global.ant-typography {
    font-family: Roboto,Arial,sans-serif;
    margin-bottom: 20px;
    max-width: 100%;
    font-weight: 300;
    color: var(--lightBlack);
    font-size: 16px;
    line-height: 20px;
}

.rowText:global.ant-typography p {
    margin-bottom: 7px;
}

.bold:global.ant-typography p:not(:first-child) {
    font-weight: 500;
}

.hubPageBackground{
    background: var(--servicesSectionBG);
    padding: 38px 0 10px;
    width: 100%;
    min-height: 550px;
}

.flowersImg{
    width: 100%;
    height: auto;
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.formEntryWrapper{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.formQuestion{
    font-size: 16px;
    color: var(--lightBlack);
    margin: 29px 0;
}

.formEntryLabel{
    font-size: 16px;
    color: var(--lightBlack);
}

.formLabelSecondary{
    font-size: 13px;
    color: #de6a19;
}

.formLabelWrapper{
    display: flex;
    flex-direction: column;
}

.formCheckboxLabel{
    font-size: 14px;
    color: var(--lightBlack);
    margin-bottom: 9px;
}

.formCheckbox{
    border: none;
    outline: none;
    background: #F2F2F2;
    color: #242428;
    margin-right: 9px;
}

.formEntry:disabled{
    background: white;
    border: 1px solid #F2F2F2;
}

.mandatory{
    color: #de6a19;
}

.formEntryError{
    font-size: 13px;
    margin: -19px 0 0;
    color: #de6a19;
}

.formButtonWrapper{
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.formRow {
    display: flex;
    align-items: center;
}

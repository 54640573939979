.card{
    width: 100%;
    min-height: 324px;
    flex-flow: wrap column;
}

.row{
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lines);
    padding-bottom: 12px;
    margin-bottom: 22px;
}
.img{
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 100px;
    margin-right: 16px;
}
.title{
    font-family: "Exo 2",Arial,sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--lightBlack);
    line-height: 24px;
    margin: 0;
}

.paragraph{
    font-size: 16px;
    color: var(--lightBlack);
}

.buttonWrapper{
    margin-top: auto;
}

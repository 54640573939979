.returnHomeWrapper {
  background: var(--lightGrey);
}

.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

.textDivider {
  position: relative;
  margin: 100px auto 79px;
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--text);
  text-align: center;
  z-index: 1;
}
.textDivider.simplified {
  margin: 85px auto 70px;
}
.textDivider:before,
.textDivider:after {
  content: "";
  position: absolute;
  width: 107px;
  height: 1px;
  background: #c7c7c7;
  opacity: 0.8;
  border-radius: 6px;
  left: 50%;
  transform: translateX(-50%);
}
.textDivider:before {
  top: -22px;
}
.textDivider:after {
  bottom: -22px;
}

.textDivider span {
  color: var(--limeGreen);
  font-weight: 800;
}

.lowerBlockWrapper {
  background: var(--tableRow);
  position: relative;
}

.flowersImg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

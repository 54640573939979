.footer {
    position: relative;
    background: var(--darkBlue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    z-index: 1;
    padding: 43px 80px 5px;
    font-family: Roboto,Arial,sans-serif;
}

.footerWrapper{
    padding: 0 !important;
}

.addressWrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.column{

}

.footerLogo{
    width: 190px;
    height: auto;
}

.columnTitle{
    font-family: 'Exo 2',sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--white);
    line-height: 24px;
    display: inline-block;
    transform: translateY(5px);
}

.linksWrapper{
    margin-top: 34px;
    display: flex;
    flex-direction: column;
}

.linksWrapper a{
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}

.linksWrapper a:not(:last-child){
    margin-bottom: 14px;
}

.bottomFooter{
    margin-top: 80px;
    padding: 20px 0 18px;
    border-top: 1px solid rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    width: 100%;
}

.copyRight{
    font-family: Roboto,Arial,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.iconsWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: auto 0;
}

.iconsWrapper a{
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: 40px;
}

.iconsWrapper a:first-child{
    max-width: 18px;
}

.iconsWrapper a:last-child{
    max-width: 22px;
}

.iconsWrapper img{
    width: 100%;
    height: auto;
}

.iconsWrapper a:not(:last-child){
    margin-right: 20px;
}

@media screen and (max-width: 1200px) {
    .column{
        text-align: center;
        margin-bottom: 10px;
    }
    .addressWrapper{
        text-align: center;
    }
    .bottomFooter{
        margin-top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .footer{
        padding: 50px 20px;
    }
    .linksWrapper{
        margin-top: 15px;
    }
    .addressWrapper{
        margin-top: 10px;
    }
    .column{
        margin-bottom: 35px;
    }
    .footerWrapper{
        display: flex;
        flex-direction: column;
    }
    .footerLogo{
        width: 250px;
    }
    .addressWrapper{
        font-size: 13px;
    }
    .columnTitle{
        font-size: 21px;
    }
    .logoAndAddressContainer{
        border-top: 1px solid rgba(255,255,255,0.2);
        padding: 50px 0;
        margin-bottom: 0;
    }
    .bottomFooter{
        flex-direction: column;
    }
    .copyRight{
        order: 2;
        text-align: center;
    }
    .iconsWrapper{
        justify-content: center;
        margin-bottom: 45px;
    }
    .iconsWrapper a {
        height: 35px;
        max-width: 55px;
    }
    .iconsWrapper a:not(:last-child){
        margin-right: 30px;
    }
}

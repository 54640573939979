.lastNewsSection {
  background: var(--servicesSectionBG);
  width: 100%;
  padding: 38px 0 10px;
}

.dashboardSection {
  background: var(--servicesSectionBG);
  width: 100%;
  padding: 38px 0 10px;
}

.deactivatedCompanyMessage {
  min-height: 50vh;
  font-family: 'Exo 2', Roboto, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  color: #de6a19;
}

.serviceCardsBackground {
  background: var(--servicesSectionBG);
  padding: 38px 0 10px;
  position: relative;
  width: 100%;
}

.servicesSectionHeader {
  display: flex;
  justify-content: space-between;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 38px;
  flex-wrap: wrap;
}

.dashboardTitle {
  margin-right: 55px;
  align-items: center;
}

.titleAndMonthYearSwitch {
  display: flex;
  align-items: center;
}

.chartFilters {
  display: flex;
  align-items: center;
}

.monthSelectorAndViewSwitch {
  display: flex;
  align-items: center;
}

.monthSelector {
  margin-right: 40px;
}

.yearMonthSelector:global.ant-select {
  display: none;
}

.monthYearSwitch {
  margin-right: 20px;
}

.serviceCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 32px;
  gap: 24px 20px;
  position: relative;
  z-index: 1;
}

.cardsLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.dashboardCard:not(:last-child) {
  margin-right: 15px;
}

.advantagesBackground {
  padding-top: 70px;
}

.updateDate {
  color: var(--darkGreyDashboard);
  font-size: 12px;
}

@media screen and (max-width: 772px) {
  .chartFilters {
    margin-top: 20px;
    justify-content: center;
  }
}

@media screen and (max-width: 925px) {
  .flowersImg {
    width: 1000px;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
}

@media screen and (max-width: 1000px) {
  .cardsLine {
    flex-wrap: wrap;
  }
  .dashboardCard {
    width: 100%;
    margin: 10px 0;
    margin-right: 0;
  }
  .dashboardCard:not(:last-child) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1140px) {
  .serviceCardsWrapper {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 820px) {
  .titleAndMonthYearSwitch {
    flex-direction: column;
  }

  .titleAndMonthYearSwitch .monthYearSwitch {
    margin-top: 25px;
    margin-left: -50px;
  }
}

.background {
  background: var(--servicesSectionBG);
  position: relative;
  padding: 38px 0 0;
}

.flowersImg {
  position: absolute;
  transform: rotate(180deg);
  top: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.title {
  margin-bottom: 32px;
}

.contentWrapper {
  padding: 15px;
  border-radius: 6px;
}

.documentsCabinetPage {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 0px 15px 75px;
  position: relative;
  z-index: 1;
}

.sidebarWrapper {
  display: flex;
  flex-direction: column;
}

.sidebarSearchRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebarSearchRow :global .ant-input {
  min-width: 256px;
}

.sidebarSearchRow .searchIcon {
  font-size: 24px;
  color: var(--darkBlue);
  margin-left: 12px;
}

.documentsCabinetPage :global .ant-layout {
  background-color: white;
}

.content {
  padding-left: 12px;
  background: var(--white);
}

.documentsTextWrapper {
  padding: 40px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.documentsTextTitle {
  margin-bottom: 16px;
}

.documentsText {
  margin-bottom: 8px;
}

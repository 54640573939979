.dropDownMenu :global .ant-dropdown-menu-item {
  background: inherit;
  text-align: right;
  width: 100%;
}

.dropDownWindow {
  background: white;
  border-radius: 6px;
  padding: 13px 20px;
  min-width: 100px;
  max-width: 200px;
  width: max-content;
  margin-left: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.dropDownWindow:global.ant-dropdown {
  z-index: 10;
  position: absolute;
}

.dropDownTrigger {
  font-weight: 400;
  font-size: 16px;
  color: var(--spinfiText);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Arial, sans-serif;
}

.cartDropdownText {
  display: block;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #979797;
}

.packagesWrapper {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.packageItem {
  font-family: Roboto, Arial, sans-serif;
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}

.packageItem:last-child {
  margin-bottom: 0;
}

.shoppingCartWrapper {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
}

.shoppingCartWrapper img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .dropDownTrigger span {
    display: none;
  }
}

.redDot {
  position: absolute;
  width: 7px;
  height: 7px;
  background: red;
  right: 1px;
  top: 1px;
  border-radius: 99999px;
  border: 1px solid white;
}

.hidden {
  display: none;
}

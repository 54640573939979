.container {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  border-radius: 6px;
  min-height: 255px;
}

.col:global.ant-col {
  width: 95%;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 20px 0px 30px 0;
}

.rowText:global.ant-typography {
  margin-bottom: 0;
  min-width: 300px;
  font-weight: 300;
  color: black;
  font-size: 14px;
  line-height: 20px;
}

.accented:global.ant-typography {
  color: var(--darkBlue);
  font-weight: 500;
}

.hubPageBackground {
  background: var(--servicesSectionBG);
  padding: 7px 0 38px 0;
  width: 100%;
  min-height: 75vh;
}

.updateDate {
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
  color: var(--darkGreyDashboard);
  margin-bottom: 20px;
  margin-left: 10px;
}

.updateDate span {
  color: var(--lightBlack);
}

.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

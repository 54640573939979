.newsCategory {
  display: inline-block;
  width: fit-content;
  max-width: 200px;
  min-width: 40px;

  height: 21px;
  background: #28598f1f;
  border-radius: 3px;
  padding: 6px 4px 6px 4px;
  font-size: 10px;

  color: var(--Design-colors-Dark-blue, #005286);
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 10px;
  text-align: center;
}

.categoryActive {
  background: #0089bc;
  color: #ffffff;
}

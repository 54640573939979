.wrapper{
    display: flex;
    align-items: center;
    max-width: max-content;
}

.wrapper.bordered{
    border: 1px solid #E3E3E3;
    border-radius: 6px;
}

.labelText{
    font-size: 16px;
    line-height: 20px;
    color: var(--darkGreyDashboard);
    margin-right: 20px;
}

.switchWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
}

.dateWrapper{
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text);
    margin: 0 5px;
}

.bordered .dateWrapper{
    color: var(--lightBlack);
}

.monthName{
    margin-right: 5px;
}

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
}

.button:hover{
    background: var(--buttonDarkBlue);
}

.button:hover .icon{
    color: var(--white);
}

.iconWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon{
    font-size: 16px;
}

@media screen and (max-width: 500px){
    .bordered .switchWrapper{
        width: 149px;
    }
}

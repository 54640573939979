.select:global.ant-select {
    width: 100%;
    margin-bottom: 24px;
}

.item, .timeWrapper {
    display: flex;
    align-items: center;
}

.timeWrapper {
    flex: 1;
}

.item {
    padding: 10px;
    border-bottom: 1px solid var(--linesList);
}

.label, .totalItem {
    width: 100%;
}

.label {
    max-width: 110px;
    padding-right: 16px;
}

.item .totalItem {
    max-width: 110px;
    padding-left: 16px;
    text-align: right;
    color: var(--lightBlack);
}

.separator {
    padding: 0 10px;
}

.toNext {
    padding: 0 16px;
}

.total {
    font-size: 16px;
    text-align: right;
    margin-top: 16px;
    color: var(--lightBlack);
}

.temeRangeWrapper {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 680px) {
    .input{
        max-width: 74px;
    }

    .temeRangeWrapper {
        max-width: 50%;
        flex: 0 0 auto;
    }
}

@media screen and (max-width: 679px) {
    .temeRangeWrapper {
        max-width: calc(50% - 19px);
    }

    .item {
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 0 10px;
        margin-bottom: 16px;
    }

    .label, .totalItem {
        width: auto;
        max-width: none;
        line-height: 1.2;
        margin-bottom: 14px;
    }

    .timeWrapper {
        order: 1;
        width: 100%;
        flex: 0 0 auto;
    }

    .separator {
        padding: 0 3px;
    }

    .toNext {
        padding: 0 13px;
    }
}

.dateTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dayNumber {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--lightBlack);
}

.dayNumberInYear {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--lightBlack);
  margin-bottom: 8px;
}

.weekDay {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: var(--lightBlack);
}

.sundayTitle .dayNumber,
.sundayTitle .weekDay {
  color: #d5241c;
}

.holidayTitle .dayNumber,
.holidayTitle .weekDay {
  color: #f49d00;
}

.passedCurrentDayTitle .dayNumber,
.passedCurrentDayTitle .weekDay {
  color: #87888a;
}

.passedCurrentDayTitle.sundayTitle .dayNumber,
.passedCurrentDayTitle.sundayTitle .weekDay {
  color: #d5241c;
  opacity: 0.5;
}

.passedCurrentDayTitle.holidayTitle .dayNumber,
.passedCurrentDayTitle.holidayTitle .weekDay {
  color: #f49d00;
  opacity: 0.5;
}

.closeButton {
    margin-left: 5%;
    padding: 0;
    border: none;
    background-color: transparent;
    color: #dadada;
    outline: none;
}

.closeButton:hover {
    color: var(--white);
    cursor: pointer;
}


.content:global.ant-modal-wrap .ant-modal{
    max-width: 100%;
    height: calc(100vh - 100px);
    padding: 0;
    display: flex;
    flex-flow: wrap column;
    margin: 0px;
}

.content:global.ant-modal-wrap .ant-modal .ant-modal-content{
    width: 100%;
    min-height: 60vh;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    margin-top: auto;
}

.content:global.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body{
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    margin-top: auto;
}

.textTitle:global.ant-typography {
    margin-bottom: 24px;
}

.select:global.ant-select {
    width: 100%;
    margin-bottom: 15px;
}
.buttonsWrapper{
    display: flex;
    flex-flow: column wrap;
    margin-top: auto;
}
.btn:global.ant-btn {
    display: flex;
    max-width: 100%;
}

.btn:last-child {
    margin-top: 16px;
}

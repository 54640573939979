.termsOfUseWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: none;
}

.wrapper{
    padding-top: 60px;
    padding-bottom: 40px;
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.modalWrapper{
    max-width: 731px;
    width: 100%;
    margin: 42px auto 0;
    min-height: 574px;
    padding: 36px 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: relative;
    z-index: 10;
    background: white;
}

.termsSection{
    height: 363px;
    overflow: auto;
    margin-top: 33px;
    padding-right: 20px;
}

.textPart{
    display: block;
    margin-top: 5px;
    font-family: Roboto, Arial,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--lightBlack);
}

.paragraph{
    display: block;
    margin-bottom: 10px;
}

.termsSection::-webkit-scrollbar {
    width: 8px;
    position: relative;
}

/* Track */
.termsSection::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.termsSection::-webkit-scrollbar-thumb {
    position: relative;
    background: #D1E6B7;
    border-radius: 6px;
}

.buttonsWrapper{
    display: flex;
    margin-top: 24px;
}

.agreeButton:global.ant-btn {
    font-weight: 800;
}

.declineButton:global.ant-btn {
    background: transparent;
    color: #28598F;
    outline: none;
    border: none;
    box-shadow: none;
    font-size: 14px;
}

.declineButton:global.ant-btn:focus {
    color: #28598F;
    background: transparent;
}

.declineButton:global.ant-btn:hover {
    background: transparent;
    color: #6996c6;
}

.declineButton:global.ant-btn:disabled {
    background: transparent;
    color: #6996c6;
}

.declineButton:global.ant-btn:disabled:hover {
    color: #6996c6;
    background: transparent;
}

.flowersImg{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 3;
}

/*Main logo*/
.mainLogo{
    max-height: 40px;
    height: 40px;
}

.emptyBlock{
    margin-top: auto;
}

@media screen and (max-width: 400px){
    .modalWrapper{
        padding: 20px 20px;
    }
    .buttonsWrapper{
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .declineButton{
        margin-top: 5px;
    }
}
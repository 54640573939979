.textTitle:global.ant-typography {
  white-space: pre;
}
.textParagraph:global.ant-typography {
  color: var(--white);
  opacity: 0.8;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}
.textMail:global.ant-typography {
  display: flex;
  color: var(--limeGreen);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 35px;
}
.textMail:global.ant-typography::before {
  content: url("../../assets/icons/mail-small.svg");
  margin-right: 11px;
  width: 16px;
  height: 16px;
}

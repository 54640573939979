.page {
  background: var(--servicesSectionBG);
}
.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

.contentRow {
  background: var(--white);
  border-radius: 6px;
  width: 100%;
  margin-bottom: 25px;
}

.filtersWrapper:global.ant-col {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filtersWrapper:global.ant-col:after {
  position: absolute;
  content: "";
  width: 99%;
  height: 1px;
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
  background: #dce5e7;
}

.dateSwitchAndMonthSelector {
  display: flex;
  align-items: center;
}

.currentYear {
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--lightBlack);
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  margin-left: 45px;
  padding: 0 16px;
  height: 34px;
  display: flex;
  align-items: center;
}

.monthSelector {
  font-family: Roboto, Arial, sans-serif;
  margin-left: 40px;
}

.chartFilters {
  display: flex;
  align-items: center;
}

.cumulCheckbox {
  margin-right: 44px;
}

.chartLegends {
  display: flex;
  margin-left: 150px;
  min-width: 150px;
  justify-content: flex-end;
}

.contentCol:global.ant-col {
  padding: 25px 60px 50px 60px;
}

.chartWrapper {
  height: 400px;
  padding: 23px;
}

.turnoverSummary {
  padding-left: 55px;
}

.updateDate {
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
  color: var(--darkGreyDashboard);
  margin-top: 16px;
  padding-bottom: 52px;
}

.updateDate span {
  color: var(--lightBlack);
}

.loaderWrapper:global.ant-col {
  padding: 25px 60px 50px 60px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorText {
  margin-top: 20px;
  color: #959595;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
}

@media screen and (max-width: 830px) {
  .chartWrapper {
    padding: 0;
  }
  .contentCol:global.ant-col {
    padding: 10px;
  }
}

@media screen and (max-width: 750px) {
  .chartWrapper {
    height: 250px;
  }
  .filtersWrapper:global.ant-col {
    flex-direction: column;
    align-items: flex-start;
  }
  .chartFilters {
    margin-top: 15px;
    width: 100%;
  }
  .chartLegends {
    margin-left: auto;
  }
  .currentYear {
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .chartWrapper {
    height: 200px;
  }
  .filtersWrapper:global.ant-col {
    padding: 10px;
  }
}

.wrapper {
  max-width: 100%;
}

.info {
  margin-top: 22px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row .removeAll {
  margin-left: 10px;
}

.totalText:global.ant-typography {
  font-weight: 500;
  color: var(--text);
}

.removeAll {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: var(--red);
  cursor: pointer;
}

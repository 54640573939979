.background {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f2f2f2;
  height: 100%;
  min-height: 553px;
  max-height: none;
}

.contentWrapper {
  width: 100%;
  margin: 42px auto 0;
}

.cartItemsWrapper {
  background: white;
  border-radius: 8px;
  padding: 32px;
}

.cartItemsTitle {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  color: var(--lightBlack);
  font-size: 18px;
  margin-bottom: 40px;
}

.itemsWrapper {
  margin-top: 28px;
}

.cartItem {
  display: flex;
  font-family: Roboto, Arial, sans-serif;
  color: var(--text);
  padding: 12px 0;
  border-bottom: 1px solid #f1f1f1;
}

.cartItemName {
  width: 453px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.cartItemCost {
  width: 200px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.cartItemDeleteIcon {
  flex: 1 1 auto;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.bucketImgWrapper {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bucketImg {
  width: 100%;
  height: 100%;
}

.bucketDisabled {
  opacity: 0.4;
}

.totalRow {
  display: flex;
  width: 100%;
  margin-top: 17px;
}

.totalTitle {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--text);
  width: 453px;
  display: flex;
  align-items: flex-end;
}

.totalCost {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: var(--lightBlack);
  display: block;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkBoxLink {
  display: block;
  margin-left: 11px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--limeGreen);
  transform: translateY(1px);
}
.checkBoxLink:hover,
.checkBoxLink:active,
.checkBoxLink:visited {
  color: var(--limeGreen);
}

.tableWrapper {
  margin-top: 32px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;
}

.cartTableWrapper {
  background: white;
  padding: 20px;
  border-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableTitle {
  display: block;
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  color: var(--lightBlack);
  font-size: 18px;
}

.subscriptionInfo {
  margin-top: 17px;
}

.checkboxWrapper {
  margin-top: 26px;
}

.tableWrapper {
  margin-top: 31px;
}

.toolTip:global.ant-tooltip .ant-tooltip-inner {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.successBlock {
  width: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 39px;
}

.successBlockTitle {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 19px;
  color: var(--lightBlack);
  display: block;
  margin-bottom: 20px;
}

.successBlockInfo {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--spinfiText);
  margin: 0 15px 39px 15px;
  min-width: 350px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.successBlockLine {
  text-align: center;
}

.emptyCartWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.searchIconImg {
  display: block;
  margin-bottom: 24px;
}

.emptyCartText {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 500;
  color: var(--spinfiText);
  margin-bottom: 20px;
  font-size: 19px;
}

.dropzoneWrapper {
  padding: 40px;
  background: var(--tableRow);
  border-radius: 8px;
  display: flex;
  height: 100%;
  min-height: 310px;

  flex-wrap: wrap;
}

.dropzone__area {
  width: 50%;
  background: #f4f7f9;
  border-radius: 8px;
  height: 262px;
  padding-right: 15px;
}

.dragger:global.ant-upload.ant-upload-drag {
  background: var(--tableRow);
  border: 2px dashed var(--disabled);
}

.dragger:global.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover,
.dragger:global.ant-upload.ant-upload-drag:focus {
  border-color: var(--accent);
}

.iconUpload {
  margin-bottom: 28px;
}

.iconUpload img {
  margin: 0 auto;
}

.dropzoneTitle:global.ant-typography {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--text);
  margin-bottom: 8px;
}

.dropzoneDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--textInTables);
  margin-top: 20px;
  padding: 0px 70px 0px 70px;
}

.dropzone__uploadList {
  width: 50%;
}

.emptyList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyListText:global.ant-typography {
  color: var(--disabled);
}

.dropzone__upload {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.dropzone__uploadButton {
  width: 160px;
  height: 40px;
  background: var(--darkBlue);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropzone__uploadButton.disabled {
  cursor: not-allowed;
  background: var(--disabled);
}

.dropzone__uploadButtonIcon {
  margin-right: 10px;
}

.buttonText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.emailText {
  width: 50%;
  margin-top: 20px;
}

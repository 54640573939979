.notification:global.ant-notification-notice{
    width: 50vw;
    left: 50%;
    position: fixed;
    top: 20px;
    transform: translateX(-50%);
    background: #72D49C;
}

.notification.darkGreen:global.ant-notification-notice{
    background: #008573;
}

.notification.wide:global.ant-notification-notice{
    width: 90vw;
}

.notification :global .anticon-check-circle-o{
    display: none;
}

.notification :global .ant-notification-notice-message{
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    color: white;
    align-items: center;
    font-family: Roboto,Arial,sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-left: 0;
}

.notification :global .anticon-close{
    color: white;
}
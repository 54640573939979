.textPart {
  display: block;
  margin-top: 5px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--lightBlack);
}

.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

.content {
  margin-top: 44px;
  margin-bottom: 20px;
  padding-bottom: 50px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: var(--lightBlack);
}

.termsTitle {
  display: block;
  text-align: center;
  font-weight: 600;
  margin: 20px 0;
}

.boldTermsOfUseText {
  font-weight: 600;
}

.boldTermsOfUseLink {
  font-weight: 600;
  color: var(--limeGreen);
}

.paragraph {
  display: block;
  margin-bottom: 10px;
}

.content::-webkit-scrollbar {
  width: 8px;
  position: relative;
}

/* Track */
.content::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  position: relative;
  background: #d1e6b7;
  border-radius: 6px;
}

.logoWrapper {
  padding: 60px 0;
}

.logo {
  max-height: 40px;
  height: 40px;
  display: block;
}

.miniContainer {
  max-width: 800px;
  margin: 0 auto;
}

.iframeWrapper {
  display: flex;
  align-items: center;
  width: 520px;
  height: 800px;
}

.iframeLoader {
  width: 100%;
  margin: 0;
  position: absolute;
}
.iframeLoader.spinnerWrapper {
  margin: 0;
}

.iframe {
  width: 100%;
  height: 100%;
}

.container {
    max-width: 1150px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    padding: 10px;
}

.link {
    color: var(--buttonDarkBlue) !important;
}

.inputWrap:global.ant-form-item {
    margin-bottom: 0;
}

.inputWrap:global.ant-form-item .ant-form-explain{
    display: none;
}

.title:global.ant-typography {
    margin-bottom: 36px;
}

.row:global.ant-row-flex {
    margin-bottom: 24px;
}

.label:global.ant-typography {
    font-size: 16px;
    font-weight: 600;
    color: var(--lightBlack);
}

.select:global.ant-select {
    width: 100%;
}
.btnWrap:global.ant-col {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 991px){
    .btnWrap:global.ant-col {
        margin-top: 10px;
    }
}
.btn:global.ant-btn {
    height: 40px;
    margin-left: 10px;
}
.inputError:global.ant-input {
    border-color: var(--redError);
}

.titleMessage {
    margin-left: 0;
}

.newsCard {
  display: flex;
  max-width: 779px;
  min-width: 779px;
  width: 100%;
  min-height: 149px;

  padding: 12px 16px 16px 16px;

  background-color: var(--white);
  border-radius: 6px;
  user-select: none;
}

.newsThumbnailWrapper {
  display: flex;
  align-items: center;
  min-width: 72px;
  max-width: 72px;
  width: 72px;
  height: 100%;
}

.newsThumbnail {
  height: 72px;
  width: 72px;
  border-radius: 50%;
}

.newsCardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 16px;
}

.newsDate {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--grey, #87888a);
}

.newsTitleWrapper {
  display: flex;
  align-items: center;
  font-family: 'Exo 2';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  width: fit-content;
  text-align: left;
  color: var(--Design-colors-Dark-blue, #005286);
  cursor: pointer;
}

.newsTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 639px;
}

.newsTitle:hover,
.newsTitle:active,
.newsTitle:focus {
  color: var(--buttonDarkBlue);
}

.newsShortContent {
  display: -webkit-box;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--spinfiText, #58585a);

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 660px;
  height: 40px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.arrowIcon {
  margin-left: 4px;
  min-width: 16px;
}

.newsCategoriesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.newsCategorySkeletonWrapper {
  min-width: 100px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .newsCard {
    min-width: none;
    max-width: none;
  }
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;
  width: fit-content;
  max-width: 360px;
  background: #28598f1f;
  border-radius: 3px;
  padding: 6px 8px 6px 8px;
  font-size: 14px;
  font-weight: 500;

  color: var(--Design-colors-Dark-blue, #005286);
  cursor: pointer;
}

.newsCategoryLabel {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 100%;

  margin-right: 4px;
}

.categoryActive {
  background: #0089bc;
  color: #ffffff;
}

.wrapper {
  display: flex;
  align-items: center;
}

.viewText {
  font-weight: 400;
  color: var(--darkGreyDashboard);
  line-height: 20px;
  margin-right: 8px;
  font-size: 16px;
  font-family: Roboto, Arial, sans-serif;
}

.chartIcon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--limeGreen);
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}

.chartIcon.active {
  background: var(--limeGreen);
}

.lineChartsIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.lineChartsIcon.active .lineChartsBrokenLine {
  stroke: var(--white);
}
.lineChartsIcon.active .lineChartsBottomLine {
  background: var(--white);
}

.lineChartsBrokenLine {
  width: 110%;
  margin-bottom: 1px;
  stroke: var(--limeGreen);
}

.lineChartsBottomLine {
  width: 100%;
  height: 2px;
  background: var(--limeGreen);
}

.barChartsIcon {
  display: flex;
  justify-content: center;
}

.barChartsMiddleLine {
  width: 4px;
  height: 100%;
  background: var(--limeGreen);
  position: relative;
}

.barChartsMiddleLine:before,
.barChartsMiddleLine:after {
  background: var(--limeGreen);
  bottom: 0;
  content: "";
  position: absolute;
  width: 4px;
}

.barChartsIcon.active .barChartsMiddleLine,
.barChartsIcon.active .barChartsMiddleLine:before,
.barChartsIcon.active .barChartsMiddleLine:after {
  background: var(--white);
}

.barChartsMiddleLine:before {
  height: 65%;
  left: -7px;
}
.barChartsMiddleLine:after {
  height: 80%;
  left: 7px;
}

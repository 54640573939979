.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 6;
  background-color: white;
  padding: 20px 0;
}

.headerMenu {
  display: flex;
  margin: 0 auto;
  align-items: center;
  border: none;
  padding: 0 !important;
}

.headerMenu:global.ant-menu-horizontal {
  border-bottom: none;
}

.headerMenu:global.ant-menu {
  margin: auto;
}

.headerMenu .companySelect:global.ant-menu-item {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
}

.headerMenu .companySelect:global.ant-menu-item,
.headerMenu .companySelect:global.ant-menu-item:hover {
  border: none;
}

.disconnectButton {
  float: right;
}

.disconnectButtonIcon {
  color: black;
  margin-right: 15px;
}

.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 0 15px;
}

/* text */
.colorMuted {
  color: var(--textInTables);
}

.textSmall {
  font-size: 13px;
}

.textError {
  color: var(--redError);
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

:global .ant-select-lg,
:global .ant-input-lg,
:global .ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 14px !important;
}

/* link */
.link {
  color: var(--buttonDarkBlue);
}

/* headings  */
.heading:global.ant-typography {
  margin: 0;
  font-family: 'Exo 2', Arial, sans-serif;
  font-weight: 600;
  color: var(--lightBlack);
}

.headingH1:global.ant-typography {
  font-size: 24px;
  line-height: 1.225;
  font-weight: 700;
  color: var(--spinfiText);
}

.headingH3:global.ant-typography {
  font-size: 18px;
  line-height: 1.225;
}

/* card */
.row {
  margin-bottom: 16px;
  padding: 32px 40px;
  background: #f4f7f9;
  border-radius: 8px;
}

/* column  */
.col:global.ant-col {
  display: flex;
  align-items: center;
}

.col2:global.ant-col {
  display: flex;
}

.col:global.ant-col:not(:last-of-type) {
  margin-bottom: 10px;
}

.col2:global.ant-col:not(:last-of-type) {
  margin-bottom: 16px;
}

/* label */
.formLabel:global.ant-typography {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--text);
  width: 100%;
  margin-bottom: 0;
}

/* button */
.buttonWrapper {
  margin-top: 16px;
  display: flex;
}

/* h1 */
.title:global.ant-typography {
  margin-bottom: 32px;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: var(--text);
}

/* table */
.table:global.ant-table-wrapper tr {
  font-size: 14px;
  line-height: 16px;
  color: var(--textInTables);
}

.table:global.ant-table-wrapper .ant-table-thead tr > th {
  padding: 15px 16px;
  border-top: 1px solid var(--lines);
  border-bottom: 1px solid var(--lines);
}

.tableCustomRow:global.ant-table-wrapper .ant-table-thead tr > th {
  border-color: var(--linesList);
}

.table:global.ant-table-wrapper .ant-table-tbody tr > td {
  padding: 13px 15px;
  border: none;
}

.table :global .ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: transparent;
}

.tableCustomRow:global.ant-table-wrapper .ant-table-thead tr > th,
.tableCustomRow:global.ant-table-wrapper .ant-table-tbody tr > td {
  color: var(--lightBlack);
  padding: 5px 15px;
  height: 48px;
}

.tableCustomRow:global.ant-table-wrapper
  .ant-table-bordered
  .ant-table-tbody
  tr
  > td {
  border-right: 1px solid var(--linesList);
}

.tableCustomRow:global.ant-table-wrapper
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.tableCustomRow:global.ant-table-wrapper
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: var(--lightBlack);
}

.table:global.ant-table-wrapper .ant-table-thead tr > th,
.table:global.ant-table-wrapper .ant-table-tbody tr:nth-child(even) > td {
  background: var(--tableRow);
}

.table:global.ant-table-wrapper
  .ant-table-tbody
  tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: rgba(0, 174, 217, 0.15);
}

.table:global.ant-table-wrapper .anticon-download,
.table:global.ant-table-wrapper .anticon-message,
.table:global.ant-table-wrapper .anticon-export,
.table:global.ant-table-wrapper .anticon-file-done,
.table:global.ant-table-wrapper .anticon-exception,
.table:global.ant-table-wrapper .anticon-euro,
.table:global.ant-table-wrapper .anticon-mail {
  font-size: 21px;
}

.table:global.ant-table-wrapper .ant-table-body {
  overflow-x: auto !important;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
  height: 7px;
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-track {
  background-color: var(--linesList);
}

.table:global.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background-color: var(--skyBlue);
  border-radius: 4px;
}

/* pagination */
:global .ant-pagination .ant-pagination-item,
:global .ant-pagination-prev .ant-pagination-item-link,
:global .ant-pagination-next .ant-pagination-item-link {
  border-color: var(--lines);
}

:global .ant-pagination .ant-pagination-item a,
:global .ant-pagination .ant-pagination-item:focus a,
:global .ant-pagination .ant-pagination-item:hover a,
:global .ant-pagination .ant-pagination-prev a,
:global .ant-pagination .ant-pagination-next a,
:global .ant-pagination .ant-pagination-prev:focus a,
:global .ant-pagination .ant-pagination-next:hover a {
  color: var(--text);
}

:global .ant-pagination .ant-pagination-item:focus,
:global .ant-pagination .ant-pagination-item:hover,
:global .ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
:global .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: var(--text);
}

:global .ant-pagination .ant-pagination-disabled a,
:global .ant-pagination .ant-pagination-disabled:hover a,
:global .ant-pagination .ant-pagination-disabled:focus a,
:global .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
:global
  .ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link,
:global
  .ant-pagination
  .ant-pagination-disabled:focus
  .ant-pagination-item-link {
  border-color: var(--lines);
  color: var(--lines);
}

:global .ant-pagination .ant-pagination-item-active,
:global .ant-pagination .ant-pagination-item-active:focus,
:global .ant-pagination .ant-pagination-item-active:hover {
  border-color: var(--accent);
}

:global .ant-pagination .ant-pagination-item-active a,
:global .ant-pagination .ant-pagination-item-active:focus a,
:global .ant-pagination .ant-pagination-item-active:hover a {
  color: var(--accent);
}

.pagination:global.ant-pagination .ant-pagination-item,
.pagination:global.ant-pagination-prev .ant-pagination-item-link,
.pagination:global.ant-pagination-next .ant-pagination-item-link {
  border-color: var(--linesList);
}

.pagination:global.ant-pagination .ant-pagination-item a,
.pagination:global.ant-pagination .ant-pagination-item:focus a,
.pagination:global.ant-pagination .ant-pagination-item:hover a,
.pagination:global.ant-pagination .ant-pagination-prev a,
.pagination:global.ant-pagination .ant-pagination-next a,
.pagination:global.ant-pagination .ant-pagination-prev:focus a,
.pagination:global.ant-pagination .ant-pagination-next:hover a {
  color: var(--spinfiText);
}

.pagination:global.ant-pagination .ant-pagination-item:focus,
.pagination:global.ant-pagination .ant-pagination-item:hover,
.pagination:global.ant-pagination
  .ant-pagination-prev:focus
  .ant-pagination-item-link,
.pagination:global.ant-pagination
  .ant-pagination-next:hover
  .ant-pagination-item-link {
  border-color: var(--spinfiText);
}

.pagination:global.ant-pagination .ant-pagination-disabled a,
.pagination:global.ant-pagination .ant-pagination-disabled:hover a,
.pagination:global.ant-pagination .ant-pagination-disabled:focus a,
.pagination:global.ant-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link,
.pagination:global.ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link,
.pagination:global.ant-pagination
  .ant-pagination-disabled:focus
  .ant-pagination-item-link {
  border-color: var(--linesList);
  color: var(--linesList);
}

.pagination:global.ant-pagination .ant-pagination-item-active,
.pagination:global.ant-pagination .ant-pagination-item-active:focus,
.pagination:global.ant-pagination .ant-pagination-item-active:hover {
  border-color: var(--buttonDarkBlue);
}

.pagination:global.ant-pagination .ant-pagination-item-active a,
.pagination:global.ant-pagination .ant-pagination-item-active:focus a,
.pagination:global.ant-pagination .ant-pagination-item-active:hover a {
  color: var(--buttonDarkBlue);
}

/* tooltip */
/*:global .ant-tooltip .ant-tooltip-inner {*/
/*    padding: 8px 16px;*/
/*    background: var(--accent);*/
/*    border-radius: 4px;*/
/*    font-size: 14px;*/
/*    line-height: 16px;*/
/*    color: var(--white);*/
/*    max-width: 224px;*/
/*    width: 100%;*/
/*}*/

/*:global .ant-tooltip .ant-tooltip-arrow {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*}*/

/*:global .ant-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow {*/
/*    left: 16px;*/
/*}*/

/*:global .ant-tooltip .ant-tooltip-content .ant-tooltip-arrow:before {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    background: var(--accent);*/
/*    box-shadow: none;*/
/*    border-radius: 0 0 4px 0;*/
/*    transform: rotate(45deg) skew(20deg, 20deg) translateY(-50%);*/
/*}*/

:global .ant-tooltip .ant-tooltip-arrow::before {
  background: var(--accent);
  width: 7px;
  height: 7px;
}

/* modal */
.modal {
  max-width: 384px;
}

.modal:global.ant-modal-confirm .ant-modal-body {
  padding-bottom: 32px;
}

.modal :global .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: var(--fontSizeBigger);
  line-height: 21px;
  color: var(--lightBlack);
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
}

.modal :global .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 16px;
  color: var(--spinfiText);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.modal :global .ant-modal-confirm-btns {
  width: 100%;
  float: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.modalOkButton:global.ant-btn,
.modalCancelButton:global.ant-btn {
  font-family: 'Exo 2', 'Roboto', Arial, sans-serif;
  border-radius: 6px;
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  font-weight: 700;
  font-size: var(--fontSize);
  line-height: 1.25;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  transition: all 0.2s;
}

.modalOkButton:global.ant-btn {
  max-width: 151px;
  background: var(--buttonDarkBlue);
  color: var(--white);
}

.modalOkButton:global.ant-btn:focus,
.modalOkButton:global.ant-btn:hover {
  background: var(--buttonLightBlue);
  color: var(--white);
}

.modalCancelButton:global.ant-btn {
  max-width: 125px;
  min-width: 125px;
  background: var(--secondary);
  color: var(--buttonDarkBlue);
  font-size: 14px;
}

.modalCancelButton:global.ant-btn:focus,
.modalCancelButton:global.ant-btn:hover {
  background: var(--secondary);
  color: var(--buttonDarkBlue);
  transition: all 0.2s;
  opacity: 0.7;
}

@media screen and (max-width: 370px) {
  .modal :global .ant-modal-confirm-btns {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal:global.ant-modal-confirm .ant-modal-confirm-btns button + button {
    margin-left: 0;
    margin-top: 5px;
  }

  .modalOkButton:global.ant-btn {
    margin-left: 0;
  }

  .modalCancelButton:global.ant-btn {
    max-width: 151px;
    min-width: 151px;
  }
}

@media screen and (max-width: 660px) {
  .headerMenu .companySelect:global.ant-menu-item {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .headerMenu :global .ant-menu-item {
    padding: 0 5px;
  }
  .headerMenu :global .userMenu {
    margin-left: 0;
    margin-right: 10px;
  }
}

/* radio */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
  border-color: var(--limeGreen);
  color: var(--limeGreen);
}

/* hover */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper:hover {
  color: var(--generalGreen);
}

/* radio checked */
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--limeGreen);
  border-color: var(--limeGreen);
  box-shadow: -1px 0 0 0 var(--limeGreen);
}

/* radio checked hover */
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: var(--generalGreen);
  border-color: var(--generalGreen);
  color: var(--white);
  box-shadow: -1px 0 0 0 var(--generalGreen);
}

/* radio common styles */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper,
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  padding: 0 12px;
}

/* list items */
.listItems:global.ant-list {
  margin-bottom: 10px;
}

.listItems:global.ant-list-split .ant-list-item {
  padding: 0;
}

.listItems:global.ant-list-split .ant-list-item a {
  color: var(--buttonDarkBlue);
  flex: 1;
  padding: 10px 0;
}

.listItems:global.ant-list-split .ant-list-item a:hover {
  color: var(--buttonLightBlue);
}

.appSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
}

.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--white);
    border-color: var(--darkBlue);
}

/* checkbox span after */
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
    border-width: 1px;
}

/* checkbox span after */
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after,
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked::after,
/* hover */
.checkbox:global.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox:global.ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
/* focus */
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--darkBlue);
}

/* focus */
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-input:focus+.ant-checkbox-inner {
    border: 1px solid var(--text);
}
.wrapper {
    display: flex;
    align-items: baseline;
    flex-direction: column;
}

.textWrapper,
.textWrapper h4 {
    width: 234px;
    color: var(--text);
}

.title {
    font-family: 'Exo 2', Roboto, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
    vertical-align: top;
    min-height: 44px;
}

.list {
    padding-left: 0;
}

.list li::marker {
    color: #0EA28A;
}

.description {
    font-family: Roboto,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.imageWrapper {
    width: 100%;
    max-height: 59px;

}

.icon {
    width: 32px;
    margin-bottom: 27px;
    align-self: flex-start;
}

.linksWrap {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex: 1 0 0;
  flex-wrap: wrap;
  width: 100%;
}

.link {
  position: relative;
  display: flex;
  padding: 8px 12px 8px 8px;
  background-color: var(--white);
  align-items: center;
  border-radius: 6px;
  line-height: 14px;
  width: 548px;
  min-width: 320px;
  gap: 8px;
}

.linkTitle {
  font-family: 'Exo 2';
  color: var(--Design-colors-Dark-blue, #005286);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.linkDesc {
  overflow: hidden;
  color: var(--grey, #87888a);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 50px;
}

.linkActions {
  position: absolute;
  right: 11px;
  top: 11px;
  display: flex;
  width: 46.5px;
  justify-content: space-between;
  align-items: center;
}

.icon {
  position: relative;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
}

.icon:after {
  content: '';
  position: absolute;
  left: -8px;
  top: -8px;
  width: 32px;
  height: 32px;
}

.wrapper {
  display: flex;
  align-items: center;
  max-width: max-content;
}

.itemText {
  font-weight: 400;
  line-height: 19px;
  color: var(--lightGreyDashBoard);
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
}

.itemText.active {
  color: var(--lightBlack);
}

.switch:global.ant-switch {
  background: var(--limeGreen);
  margin: 0 8px;
  height: 24px;
  width: 41px;
}
.switch:global.ant-switch:after {
  height: 20px;
  width: 20px;
}

.switch:global.ant-switch .ant-click-animating-node {
  display: none;
}

.switch:global.ant-switch-checked {
  background: var(--emeraldGreen);
}

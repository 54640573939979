.wrapper {
  background: var(--white);
  padding: 10px;
  margin-top: 40px;
  border-radius: 6px;
}

.history {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.searchInput:global.ant-input-search {
  margin-left: 10px;
  width: 200px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--text);
}

.searchPanel {
  display: flex;
  justify-content: flex-end;
}

.historyTable {
  margin-top: 25px;
}

.dateColumn {
  white-space: nowrap;
}

.wrapper{
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    background: var(--skyBlue);
    padding: 39px 65px 39px 85px;
    color: var(--white);
    font-family: Roboto,Arial,sans-serif;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0 auto 40px;
    border-radius: 6px;
    z-index: 1;
    position: relative;
}

.costBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:0;
    min-width: 160px;
}

.cost{
    font-size: 80px;
    font-weight: 900;
    line-height: 94px;
    white-space: nowrap;
}

.textCost{
    font-size: 24px;
    font-weight: 900;
    white-space: pre-line;
}

.tax{
    font-size: 24px;
    line-height: 28px;
}

.infoBlock{
    margin: 0 52px;
}

.text{
    font-size: 24px;
    line-height: 28px;
}

.buttonWrapper{
    width: max-content;
    width: -moz-max-content;
    margin-top: 21px;
    position: relative;
}

.button:global.ant-btn:focus{
    opacity: 1;
}

.simplify .wrapper{
    justify-content: center;
}
.simplify .infoBlock{
    margin-left: 50px;
}
.simplify .costBlock{
    transform: translateY(8px);
}
.simplify .buttonWrapper{
    margin-top: 0;
}

@media screen and (max-width: 755px){
    .wrapper{
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        padding: 39px 85px;
        /*justify-content: center;*/
    }
    .costBlock{
        margin: 0 auto;
    }
    .simplify .infoBlock{
        margin-left: 0;
    }
    .buttonWrapper{
        margin: 21px auto 0;
    }
    .infoBlock{
        margin-top: 15px;
        margin-left: 0;
    }
    .simplify .infoBlock{
        margin-top: 0;
    }
}

@media screen and (max-width: 440px){
    .wrapper{
        padding: 39px 40px;
    }
}

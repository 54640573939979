/* progress bar */
.progress {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progressWrapper {
  width: 80%;
}

.progressLabel {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: var(--text);
}

:global .ant-progress-outer .ant-progress-inner,
:global .ant-progress-inner .ant-progress-bg {
  border-radius: 0;
}

:global .ant-progress-outer .ant-progress-inner {
  background-color: rgba(0, 174, 217, 0.3);
}

/* progress bar bg complited */
:global .ant-progress-inner .ant-progress-bg {
  background-color: var(--accent);
}

.registrationPage {
  max-width: 1178px;
  width: 100%;
  margin: auto;
}

.containerWrapper {
  padding: 24px 15px;
}

/* h1 */
.title {
  margin-bottom: 32px;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: var(--text);
}

/* steps */
.steps:global.ant-steps {
  margin-bottom: 27px;
}

:global
  .ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  margin-right: 0;
}

/* steps circle size */
.steps:global.ant-steps .ant-steps-item-icon {
  width: 40px;
  height: 40px;
}

/* steps icon color */
.steps:global.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon,
.steps:global.ant-steps
  .ant-steps-item-current
  .ant-steps-item-icon
  > .ant-steps-icon,
.steps:global.ant-steps
  .ant-steps-item-error
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: var(--white);
  top: 3px;
  padding: 11px;
}

/* steps bg - finish */
.steps:global.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--limeGreen);
  background-color: var(--limeGreen);
}

/* steps bg - error */
.steps:global.ant-steps .ant-steps-item-error .ant-steps-item-icon {
  border-color: var(--red);
  background-color: var(--red);
}

/* steps text color - error */
.steps:global.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.steps:global.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: var(--text);
  position: relative;
}

/* steps check  description */
.steps:global.ant-steps
  .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description::before {
  content: "\2713";
  margin-right: 5px;
}

/* steps check  description - error & current*/
.steps:global.ant-steps
  .ant-steps-item-current
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description::before,
.steps:global.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description::before,
.steps:global.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description::before {
  color: var(--red);
}

/* steps check  description - error */
.steps:global.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description::before {
  color: var(--limeGreen);
}

/* steps bg - wait */
.steps:global.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border-color: var(--text);
  color: var(--text);
}

.steps:global.ant-steps .ant-steps-item-wait .ant-steps-icon {
  color: var(--text);
}

.steps:global.ant-steps .ant-steps-item-wait .ant-steps-item-title::after {
  border-top: 1px dashed var(--text);
  top: 20px;
  left: -8px;
}

/* steps bg - current */
.steps:global.ant-steps .ant-steps-item-current .ant-steps-item-icon {
  border-color: var(--accent);
  background-color: var(--accent);
}

.steps:global.ant-steps
  .ant-steps-item-current
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.steps:global.ant-steps
  .ant-steps-item-current
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: var(--accent);
}

/* steps title */
.steps:global.ant-steps .ant-steps-item-title {
  font-weight: 300;
  line-height: 19px;
  color: var(--text);
}

/* steps line */
.steps:global.ant-steps .ant-steps-item-icon > .ant-steps-icon {
  top: 3px;
}

.steps:global.ant-steps .ant-steps-item-title::after {
  border-top: 1px dashed var(--text);
  top: 20px;
  left: -8px;
}

.steps:global.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: transparent;
}

/* steps icon hover ,focus, active*/
.steps:global.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon,
.steps:global.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:focus
  .ant-steps-item-icon,
.steps:global.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:active
  .ant-steps-item-icon {
  border-color: var(--textInFields);
}

.steps:global.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--textInFields);
}

.dropdownMenu {
    overflow: auto;
    max-height: 300px;
}

.dropdownMenu::-webkit-scrollbar {
    width: 10px;
    position: relative;
}

/* Track */
.dropdownMenu::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
.dropdownMenu::-webkit-scrollbar-thumb {
    position: relative;
    background: #B8B8B8;
    border-radius: 6px;
    border-right: 4px solid var(--white);
    border-left: 4px solid var(--white);
}

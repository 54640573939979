.bigCardWrapper{
    padding: 29px;
    border-radius: 4px;
    background: #F8F8F8;
    width: 100%;
    margin-bottom: 28px;
}

.bigCardTitleWrapper{
    margin-bottom: 19px;
    display: flex;
    align-items: flex-end;
}

.bigCardTitle{
    display: block;
    font-family: "Exo 2",Arial,sans-serif;
    font-weight: 600;
    color: var(--lightBlack);
    font-size: 21px;
}

.moduleActive{
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    color: #87888A;
    font-size: 16px;
    margin-left: 10px;
    display: block;
}

.costWrapper{
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #009D85;
    display: block;
    margin-left: 10px;
}

.cost{
    font-size: 18px;
    margin-right: 4px;
}

.bigCardContent{
    display: flex;
    gap: 20px;
    margin-bottom: 24px;
}

.buttonsWrapper{
    display: flex;
}
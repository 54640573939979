.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
  padding: 10px;
}

.table:global {
  margin-top: 24px;
}

.text:not(:last-child) {
  color: inherit;
  display: inline-block;
  margin-right: 16px;
}

.text:global.ant-typography.ant-typography-disabled {
  color: var(--linesList);
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.newButton:global.ant-btn {
  min-height: 40px;
}

.select:global.ant-select {
  margin-bottom: 10px;
  width: 100%;
  min-width: 300px;
  max-width: 327px;
}

.modalText {
  display: block;
}

.modalText + .modalText {
  margin-top: 10px;
}

.modalText:last-child {
  margin-bottom: 10px;
}
.removeBtn:global.anticon {
  color: var(--limeGreen);
}

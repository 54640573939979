.select:global.ant-select {
  width: 100%;
  min-width: 80px;
  max-width: 80px;
}

.input:global.ant-input {
  margin-bottom: 20px;
}

.checkbox:global.ant-checkbox-wrapper {
  color: var(--lightBlack);
}

.checkbox:global.ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: var(--limeGreen);
}

.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
.checkbox:global.ant-checkbox-wrapper .ant-checkbox-checked::after {
  border-color: var(--linesList);
}

.item,
.timeWrapper {
  display: flex;
  align-items: center;
}

.flexNoWrap:global.ant-row-flex {
  flex-wrap: nowrap;
}

.timeWrapper {
  flex: 1;
}

.timePeriodLeft {
  width: 46.85%;
}

.timePeriodRight {
  width: 53.15%;
}

.item {
  padding: 13px 0;
  border-bottom: 1px solid var(--linesList);
}

.labelWrapper,
.totalItem {
  width: 100%;
}

.totalItem.totalItemHidden {
  visibility: hidden;
}

.labelWrapper {
  max-width: 140px;
  padding-right: 16px;
}

.label:global.ant-typography {
  display: inline-block;
  color: var(--lightBlack);
}

.labelMd {
  font-weight: 500;
  font-size: 16px;
}

.switch:global.ant-switch {
  margin-right: 10px;
  width: 48px;
  min-width: auto;
  height: 25px;
  background-color: var(--linesList);
}

.switch:global.ant-switch:after {
  width: 21px;
  height: 21px;
}

.switch:global.ant-switch-checked {
  background-color: var(--limeGreen);
}

.totalItem {
  max-width: 140px;
  padding-left: 16px;
  text-align: right;
}

.separator {
  padding: 0 10px;
}

.toNext {
  padding: 0 16px;
}

.total {
  font-size: 16px;
  text-align: right;
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .item {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 10px;
    margin-bottom: 16px;
  }

  .label,
  .totalItem {
    width: auto;
    max-width: none;
    line-height: 1.2;
  }

  .totalItem {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
  }

  .label {
    font-size: 16px;
  }

  .checkboxWrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
  }

  .labelWrapper {
    margin-bottom: 16px;
    max-width: none;
    width: auto;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  .timeWrapper {
    order: 1;
    width: 100%;
    flex: 0 0 auto;
  }

  .timePeriodLeft {
    width: 44.6%;
  }

  .timePeriodRight {
    width: 55.4%;
  }

  .separator {
    padding: 0 3px;
  }

  .toNext {
    padding: 0 13px;
  }

  .switch:global.ant-switch {
    width: 40px;
    height: 21px;
    margin-right: 16px;
  }

  .switch:global.ant-switch:after {
    width: 17px;
    height: 17px;
  }
}

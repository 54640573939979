.Icon:global.anticon-download,
.Icon:global.anticon-message,
.Icon:global.anticon-export,
.Icon:global.anticon-euro,
.Icon:global.anticon-mail {
  color: var(--darkBlue);
}

.Icon.disabled {
  color: var(--disabled);
}

.Icon.animation {
  animation: iconAnimation 0.5s;
  color: var(--limeGreen);
}

@keyframes iconAnimation {
  0% {
    transform: rotate(0deg) scale(1);
  }
  40% {
    transform: rotate(-15deg) scale(1.1);
  }
  80% {
    transform: rotate(15deg) scale(1.2);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

.Icon:global.anticon-download,
.Icon:global.anticon-message,
.Icon:global.anticon-exception,
.Icon:global.anticon-file-done,
.Icon:global.anticon-euro,
.Icon:global.anticon-mail {
  margin-left: 18px;
}

.spinSubstitute:not(:first-child) :global .ant-spin {
  margin-left: 18px;
}

.Normal:global.anticon-exception {
  color: var(--orange);
}

.Overdue:global.anticon-exception {
  color: var(--red);
}

.Normal:global.anticon-file-done {
  color: var(--limeGreen);
}

.Overdue:global.anticon-file-done {
  color: var(--yellow);
}

.IconDisabled:global.anticon-euro,
.IconDisabled:global.anticon-exception,
.IconDisabled:global.anticon-file-done {
  color: var(--disabled);
}

.IconCanValidateAndPay:global.anticon-euro {
  color: var(--lightGreyDashBoard);
}

.paymentIcon-readyForPayment:global.anticon-euro {
  color: var(--accent);
}

.paymentIcon-accepted:global.anticon-euro {
  color: var(--limeGreen);
}

.paymentIcon-rejected:global.anticon-euro {
  color: var(--red);
}

.paymentIcon-inProgress:global.anticon-euro {
  color: var(--yellow);
}

.paymentIcon-paidOutside:global.anticon-euro {
  color: var(--orange);
}

/**
Yes, colors do not match for same statuses
see https://trello.com/c/Xelqitol/836-pay-modification-of-filters-in-invoice-search-client-front-office-interface
 */
.validationIcon-accepted:global.anticon-file-done,
.validationIcon-accepted:global.anticon-exception {
  color: var(--limeGreen);
}

.validationIcon-inProgress:global.anticon-file-done,
.validationIcon-inProgress:global.anticon-exception,
.validationIcon-rejected:global.anticon-file-done,
.validationIcon-rejected:global.anticon-exception {
  color: var(--yellow);
}

.validationIcon-validated:global.anticon-file-done,
.validationIcon-validated:global.anticon-exception,
.validationIcon-readyForPayment:global.anticon-file-done,
.validationIcon-readyForPayment:global.anticon-exception {
  color: var(--orange);
}

.validationIcon-paidOutside:global.anticon-file-done,
.validationIcon-paidOutside:global.anticon-exception {
  color: var(--accent);
}

.infoModal :global .ant-modal-content {
  margin-top: 16px;
  background: var(--darkBlue);
}

.infoModal :global .ant-modal-confirm-btns {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.infoModal :global .ant-modal-confirm-btns .ant-btn {
  min-width: 150px;
  background-color: var(--white);
  color: var(--darkBlue);
  font-weight: normal;
}

.infoModal :global .ant-modal-confirm-btns .ant-btn:hover {
  color: var(--accent);
}

.infoModalRow {
  display: flex;
  color: var(--white);
  margin-bottom: 3px;
}

.infoModalLabel {
  min-width: 150px;
  max-width: 150px;
}

.infoModalComment {
  width: 65%;
  margin-left: 1.5%;
}

.infoModalComment :global .ant-typography {
  margin-bottom: 0;
}

.infoModalRow :global .ant-typography {
  color: var(--white);
}

.infoModalValueError :global .ant-typography {
  color: var(--red);
}

.errorModalText {
  color: var(--white);
}

.noWrapCell {
  white-space: nowrap;
}

.table tr.red {
  color: var(--red);
}

.table tr.strikethrough {
  text-decoration: line-through;
}

.table:global.ant-table-wrapper .ant-table-tbody tr > td {
  padding: 13px 9px;
}
.table:global.ant-table-wrapper .ant-table-thead tr > th {
  padding: 15px 9px;
}

.table td:nth-of-type(2) {
  color: var(--darkBlue);
}

.table:global.ant-table-wrapper
  div.ant-pagination-options-size-changer.ant-select {
  width: 100px;
}

.title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--text);
}

.tooltipValidatedNormal:global.ant-tooltip .ant-tooltip-arrow::before,
.tooltipValidatedNormal:global.ant-tooltip .ant-tooltip-inner {
  background-color: var(--limeGreen);
}

.tooltipNotValidatedNormal:global.ant-tooltip .ant-tooltip-arrow::before,
.tooltipNotValidatedNormal:global.ant-tooltip .ant-tooltip-inner {
  background-color: var(--orange);
}

.tooltipNotValidatedOverdue:global.ant-tooltip .ant-tooltip-arrow::before,
.tooltipNotValidatedOverdue:global.ant-tooltip .ant-tooltip-inner {
  background-color: var(--red);
}

.tooltipValidatedOverdue:global.ant-tooltip .ant-tooltip-arrow::before,
.tooltipValidatedOverdue:global.ant-tooltip .ant-tooltip-inner {
  background-color: var(--yellow);
}

/* .tooltipSendByEmail:global.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  white-space: nowrap;
} */

.checkbox:global.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.checkbox:global.ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--lines);
  border: 1px solid rgba(88, 88, 90, 0.3);
}

.checkbox:global.ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: 1px solid var(--textInTables);
}

.resultsTable {
  background: var(--white);
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 6px;
}

.resultsTable
  :global
  .ant-table-fixed-columns-in-body.ant-table-row-cell-ellipsis.ant-table-row-cell-break-word {
  z-index: 0;
}

.resultsTable :global .ant-checkbox .ant-checkbox-inner,
.resultsTable :global .ant-checkbox.ant-checkbox-checked {
  border-color: 1px solid var(--textInTables);
  border: 1px solid rgba(88, 88, 90, 0.3);
  background-color: var(--linesTable);
  border-radius: 3px;
}

.resultsTable :global .ant-checkbox.ant-checkbox-checked {
  background-color: var(--textInTables);
}

.resultsTable
  :global
  .ant-checkbox.ant-checkbox-indeterminate
  .ant-checkbox-inner::after,
.resultsTable :global .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--textInTables);
}

.totalFooterWrapper {
  display: flex;
  justify-content: space-between;
}

.totalFooterTitle {
  min-width: 130px;
  width: 100%;
  max-width: 210px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 500;
  color: var(--lightBlack);
}

.totalFooterTitle span {
  display: block;
}

.totalFooterCell {
  min-width: 90px;
  max-width: 90px;
  text-align: right;
  color: var(--textInTables);
}
.totalFooterCell:first-child,
.totalFooterCell:last-child {
  min-width: 94px;
  max-width: 94px;
}

.resultsTableTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.resultsTableButton {
  max-width: none !important;
  font-size: var(--fontSize) !important;
  font-weight: 400 !important;
}

.resultsTableButton:focus,
.resultsTableButton:hover {
  color: var(--darkblue, #0081b7) !important;
  border: 1px solid var(--darkblue, #0081b7) !important;
  background-color: var(--white) !important;
  opacity: 0.6;
}

.resultsTableSpinner {
  margin-right: 11px;
}

.wrapper {
  width: 40px;
  height: 35px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.wrapper:after,
.wrapper:before {
  position: absolute;
  width: 10px;
  content: "";
  background: var(--darkGreyDashboard);
  border-radius: 4px;
}

.wrapper:before {
  height: 16px;
  left: 0;
}

.wrapper:after {
  height: 100%;
  right: 0;
}

.centralPillar {
  width: 10px;
  height: 28px;
  background: var(--darkGreyDashboard);
  border-radius: 4px;
}

.wrapper.colored:after {
  background: var(--accent);
}
.wrapper.colored:before {
  background: #ffc000;
}

.colored .centralPillar {
  background: var(--limeGreen);
}

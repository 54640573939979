.invoiceApproveModalButtonRow :global .ant-btn {
  min-width: 150px;
  margin: 0 18px;
  background-color: var(--white);
  color: var(--darkBlue);
  font-weight: 500;
}

.invoiceApproveModalButtonRow {
  display: flex;
  justify-content: flex-end;
  padding: 24px 50px 0 0;
}
.invoiceApproveModalRow {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

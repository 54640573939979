.imageSlider {
  height: 546px;
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textTitle {
  margin: 0;
  padding: 0 6px;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--darkBlue);
  background-color: var(--white);
  box-shadow: 0 0 6px 6px var(--white);
  border-radius: 5px;
  max-width: 486px;
  max-height: 58px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}

.textDescription {
  margin: 19px auto;
  padding: 6px 0px;
  width: 428px;
  height: 106px;
  overflow: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize);
  line-height: 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--lightBlack);
  background-color: var(--white);
  box-shadow: 0 0 6px 6px var(--white);
  border-radius: 5px;
}

.bannerMarkWrapper {
  display: flex;
  justify-content: center;
  margin: 13px 24px;
  gap: 9px;
  background-color: var(--white);
  box-shadow: 0 0 9px 9px var(--white);
  border-radius: 5px;
  cursor: pointer;
}
.bannerMark {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--darkBlue);
  opacity: 0.4;
}
.bannerMarkCurrent {
  opacity: 1;
}

.bannerImage {
  max-height: 304px;
  max-width: 390px;
}
.bannerImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 304px;
  width: 390px;
  margin: 14px;
}

.bannerButtonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchBannerButton {
  display: none;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--blue);
  box-shadow: 0 0 6px 6px var(--white);
  background-color: var(--white);
}
.switchBannerButton:hover {
  background-color: var(--limeGreen);
  box-shadow: none;
}
.switchBannerButton:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(294deg)
    brightness(104%) contrast(104%);
}

@media screen and (max-width: 540px) {
  .switchBannerButtonWideScreen {
    display: none;
  }
  .switchBannerButtonSmallScreen {
    display: flex;
  }
}
@media screen and (min-width: 540px) {
  .switchBannerButtonWideScreen {
    display: flex;
  }
  .switchBannerButtonSmallScreen {
    display: none;
  }
}

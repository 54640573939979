.fileNameWrapper {
  max-width: 870px;
}

.fileName {
  color: var(--darkenBlue);
  cursor: pointer;
}
.fileName:hover {
  color: var(--darkBlue);
}

.loader {
  max-height: 16px;
  font-size: 14px;
}

/* col label title */
.formLabel {
  max-width: 343px;
  min-width: 343px;
}

.formItem:global.ant-form-item {
  margin-bottom: 0;
  display: flex;
  flex: 1;
}

.formItem:global.ant-form-item .ant-form-item-control-wrapper {
  width: 100%;
}

.formItem :global .ant-input {
  resize: none;
}

.commentLabel {
  align-self: start;
  max-width: 370px;
  min-width: 370px;
}

/* info */
.infoWrapper:global.ant-row {
  display: flex;
  align-items: center;
}

.info:global.ant-typography {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text);
}

/* icon info */
.infoIcon:global.anticon-exclamation-circle {
  color: var(--darkBlue);
  margin-right: 15px;
}

/* icon label */
.labelIcon:global.anticon-check-circle {
  color: var(--disabled);
  margin-right: 15px;
}

.labelIcon.completed:global.anticon-check-circle {
  color: var(--limeGreen);
}

/* button */
.buttonWrapper {
  justify-content: space-between;
}

/* input */
.formInput {
  justify-self: center;
  max-width: 95%;
  width: 100%;
  display: flex;
}

.uploadFile {
  overflow: hidden;
}

.page {
  background: var(--servicesSectionBG);
  min-height: 75vh;
}
.returnHome {
  display: flex;
  align-items: center;
  color: #696969;
  padding: 14px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

.contentRow {
  background: var(--white);
  border-radius: 6px;
  width: 100%;
  margin-top: 22px;
}

.filtersWrapper:global.ant-col {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filtersWrapper:global.ant-col:after {
  position: absolute;
  content: "";
  width: 99%;
  height: 1px;
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
  background: #dce5e7;
}

.dateSwitchAndMonthSelector {
  display: flex;
  align-items: center;
}

.currentYear {
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--lightBlack);
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  margin-left: 45px;
  padding: 0 16px;
  height: 34px;
  display: flex;
  align-items: center;
}

.yearMonthSelector:global.ant-select {
  display: none;
}

.yearMonthSwitcher {
  display: flex;
}

.monthSelector {
  font-family: Roboto, Arial, sans-serif;
  margin-left: 40px;
}

.chartFilters {
  display: flex;
  align-items: center;
}

.cumulCheckbox {
  margin-right: 44px;
}

.chartLegends {
  margin-left: 150px;
}

.loaderWrapper:global.ant-col {
  padding: 25px 60px 50px 60px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorText {
  margin-top: 20px;
  color: #959595;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
}

.contentCol:global.ant-col {
  padding: 25px 60px 50px 60px;
}

.chartWrapper {
  height: 400px;
  padding: 23px;
}

.turnoverSummary {
  padding-left: 55px;
}

.updateDate {
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto, Arial, sans-serif;
  color: var(--darkGreyDashboard);
  margin-top: 16px;
  padding-bottom: 52px;
}

.updateDate span {
  color: var(--lightBlack);
}

@media screen and (max-width: 1073px) {
  .chartFilters {
    /*margin-top: 10px;*/
    /*width: 100%;*/
    flex: 1 1 auto;
    margin-left: 15px;
  }
  .chartLegends {
    margin-left: auto;
  }
}

@media screen and (max-width: 980px) {
  .chartWrapper {
    padding: 0;
  }
  .contentCol:global.ant-col {
    padding: 10px;
  }
  .turnoverSummary {
    margin-top: 15px;
    padding-left: 35px;
  }
  .chartLegends {
    margin-left: auto;
  }
  .chartViewSwitch {
    margin-right: 5px;
  }
}

@media screen and (max-width: 943px) {
  .chartFilters {
    margin-top: 10px;
  }
}

@media screen and (max-width: 820px) {
  .chartFilters {
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .chartWrapper {
    height: 200px;
  }
  .monthSelector {
    margin-left: auto;
  }
  .currentYear {
    margin-left: auto;
  }
  .dateSwitchAndMonthSelector {
    width: 100%;
  }
  .chartTabs {
    display: none;
  }
  .chartSelect:global.ant-select {
    display: block;
    margin-top: 20px;
  }
  .contentRow:global.ant-row {
    margin-top: 5px;
  }
  .chartFilters {
    align-items: center;
  }
}

@media screen and (max-width: 520px) {
  .chartFilters {
    flex-wrap: wrap;
  }
  .cumulCheckbox {
    margin-right: auto;
  }
  .chartViewSwitch {
    margin-left: 10px;
  }
  .yearMonthSelector:global.ant-select {
    display: block;
    margin-right: 5px;
  }
  .yearMonthSwitcher {
    display: none;
  }
  .chartViewSwitch {
    margin-bottom: 7px;
  }
}

@media screen and (max-width: 482px) {
  .chartFilters {
    align-items: flex-start;
  }
}


.titleSearch {
    line-height: 1.4;
    margin-bottom: 8px;
}

.search:global.ant-input-affix-wrapper {
    margin-bottom: 12px;
    margin-top: 8px;
}

.paginationWrapper {
    display: flex;
    justify-content: flex-end;
}

.staffEmptyText {
    display: block;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: var(--spinfiText);
    margin: 20px 0 12px;
}

.staffEmptyLink {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: var(--buttonDarkBlue);
}

.listItems:global.ant-list .ant-list-item {
    border-bottom: 1px solid #E3E3E3;
    padding: 8px 0;
}

.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
  padding: 10px;
}

@media screen and (max-width: 796px) {
  .container {
    padding-bottom: 70px;
  }
}

.pageTitle:global.ant-typography {
  margin-bottom: 20px;
}

/* Tabs */

.tabBar:global.ant-tabs {
  margin-top: 16px;
}

.tabBar:global.ant-tabs .ant-tabs-tab {
  padding-left: 0;
  padding-right: 0;
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--lightBlack);
}
.tabBar:global.ant-tabs .ant-tabs-tab:hover {
  color: var(--limeGreen);
}

.tabBar:global.ant-tabs .ant-tabs-tab-active {
  color: var(--limeGreen);
}

.tabBar:global.ant-tabs .ant-tabs-ink-bar {
  background-color: var(--limeGreen);
}

.formRow:global.ant-row .ant-form-item-children {
  display: flex;
  flex-direction: column;
}

.formRow:global.ant-row {
  margin-bottom: 24px;
}

.titleError {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10px;
  color: var(--redError) !important;
  font-size: 12px;
}

.titleInputError {
  border-color: var(--redError) !important;
}

.textLabel,
.titleSearch {
  display: inline-block;
}

.buttonWrapper {
  display: flex;
  margin-top: 20px;
}
.tabs:global.ant-radio-group .ant-radio-button-wrapper {
  font-weight: 500;
}
.tabs:global.ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: var(--white);
}

.btn + .btn {
  margin-left: 16px;
}

@media screen and (min-width: 640px) {
  .formRow:global.ant-row {
    margin-bottom: 20px;
  }

  .textLabel:global.ant-typography {
    margin-bottom: 9px;
  }

  .buttonWrapper :global .ant-btn:last-child {
    margin-left: 16px;
  }
}

@media screen and (max-width: 639px) {
  .textLabel:global.ant-typography {
    margin-bottom: 6px;
  }

  .buttonWrapper {
    flex-direction: column;
  }

  .buttonWrapper :global .ant-btn {
    max-width: none;
    margin-bottom: 8px;
  }

  .btn + .btn {
    margin-left: 0;
  }
}

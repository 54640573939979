.chartLegends {
  display: flex;
}

.legend {
  color: red;
  margin-left: 5px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.legend:not(:last-child) {
  margin-right: 17px;
}

.legend0 {
  color: var(--skyBlue);
  cursor: auto;
}

.legend1 {
  color: var(--limeGreen);
  border-bottom: 1px solid var(--limeGreen);
}

.legend2 {
  color: var(--lightGreyDashBoard);
  border-bottom: 1px solid var(--lightGreyDashBoard);
}

.legend.hidden {
  border-bottom: none;
  padding-bottom: 1px;
}

.legend1.hidden {
  color: #d1e6b7;
}
.legend2.hidden {
  color: #d3d6d7;
}

/*MODAL ----------------------------------------------------------------------*/

.modal:global.ant-modal .ant-modal-body{
    padding: 24px 0;
}

.modal:global.ant-modal .ant-modal-footer{
    display: none;
}

.modal:global.ant-modal:local.shrinkHeight{
    height: 346px;
    overflow: hidden;
    border-radius: 6px;
}

.modalWrapper{
    position: relative;
}

.commentsWrapper{
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
}

.commentsWrapper::-webkit-scrollbar {
    width: 10px;
    position: relative;
}

/* Track */
.commentsWrapper::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.commentsWrapper::-webkit-scrollbar-thumb {
    position: relative;
    background: #B8B8B8;
    border-radius: 6px;
    border-right: 4px solid var(--white);
    border-left: 4px solid var(--white);
}


.noCommentsYet{
    padding: 0 24px;
    color:var(--lightBlack);
    font-family: "Exo 2",Arial,sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
}


.comment:global.ant-col{
    padding: 24px 24px;
    border-bottom: 1px solid #F1F1F1;
}

.comment:first-child{
    padding-top: 0;
}

/*.comment:global.ant-col:last-child{*/
/*    border-bottom: none;*/
/*    padding-bottom: 0;*/
/*}*/

.commentTitle{
    font-family: "Exo 2",Arial,sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: var(--lightBlack);
}

.commentDate{
    font-family: Roboto,Arial,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--lightGreyDashBoard);
    margin-left: 20px;
}

.commentHeader{
    display: flex;
    align-items: flex-end;
}

.commentHeader span, img{
    display: block;
}

.pencilIcon{
    width: 18px;
    height: 18px;
    align-self: center;
    margin: 0 21px 0 auto;
}

.commentHeader img:last-child{
    width: 20px;
    height: 20px;
    align-self: center;
}

.commentHeader img{
    cursor: pointer;
}

.commentText{
    display: block;
    padding: 0;
    font-family: Roboto,Arial,sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: var(--lightBlack);
    margin-top: 17px;
    margin-bottom: 0;
}

.buttonWrapper{
    display: flex;
    margin-top: 24px;
    padding: 0 24px;
}

.addButton{
    margin-right: 12px;
}

.button:global.ant-btn{
    max-width: 124px;
    min-width: 124px;
}
:root {
  --accent: #00aed9;
  --secondary: #e5ebf1;
  --disabled: #cbdadc;

  --white: #fff;
  --lightBlack: #575756;
  --lightGrey: #f2f2f2;
  --darkGreyDashboard: #959595;
  --lightGreyDashBoard: #b8b8b8;

  --red: #da5b15;
  --redDashboard: #d5241c;
  --redError: #df512f;

  --yellow: #dade66;
  --orange: #eb9f49;
  --brightOrange: #e67f39;
  --brown: #6a4b3a;

  --limeGreen: #a2cd6f;
  --generalGreen: #8ab456;
  --darkGreen: #02695a;
  --spinfiGreen: #40c97a;
  --blandEmeraldGreen: #5a9f74;
  --lightEmeraldGreen: #009d85;
  --emeraldGreen: #008573;

  --darkBlue: #23446c;
  --generalBlue: #0089bc;
  --blue: #5186c0;
  --darkenBlue: #336195;
  --skyBlue: #0aa9bc;
  --dashboardIconBlue: #4f6989;
  --regularBlue: #0081b7;

  --backToHomeButtonGrey: #87888a;
  --backToHomeArrowGrey: #c2c2c2;
  --backToHomeButtonDebtor: #696969;
  --greyDisabled: #e3e3e3;
  --greyDisabledText: #adadad;

  --backToHomeButtonGrey: #979797;
  --backToHomeArrowGrey: #a2a2a2;

  --servicesSectionBG: #f2f2f2;
  --buttonDarkBlue: #28598f;
  --buttonLightBlue: #5186c0;
  --success: #a2cd6f;

  --lines: #dce5e7;
  --linesTable: #f1f1f1;
  --linesList: #e3e3e3;
  --spinfiText: #58585a;
  --text: #484848;
  --textInFields: #242428;
  --textInTables: #87888a;
  --tableRow: #f4f7f9;
  --tableHead: #f8f8f8;
  --tableDefaultHover: #e6f7ff;

  --mainPageText: #484848;

  --fontSize: 16px;
  --fontSizeBigger: 18px;

  --padding: 5px;
  --paddingBigger: 8px;
  --buttonsPadding: 12px 30px;

  --maxMobile: 767px;
  --minTablet: 768px;
}

.datePicker:global.ant-calendar-picker .ant-calendar-picker-input {
  /*border: 1px solid var(--lines);*/
  border-radius: 4px;
  color: var(--text);
}

/* hover,active,focus */
:global .ant-calendar-picker .ant-input:focus,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:hover,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:active,
.datePicker:global.ant-calendar-picker .ant-calendar-picker-input:focus {
  border: 1px solid var(--text);
  box-shadow: none;
}

:global
  .ant-calendar-picker:hover
  .ant-input.ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--text);
}

/* icon */
.datePicker:global.ant-calendar-picker .ant-calendar-picker-icon {
  color: var(--darkBlue);
}

.table:global {
  margin-top: 24px;
}

.textCustom {
  font-weight: 500;
  color: var(--spinfiText) !important;
}

.Icon:global.anticon {
  color: var(--limeGreen);
}

.Action:global {
  display: inline-block;
  cursor: pointer;
}

.Action:global:last-child {
  margin-left: 10px;
}

.ActionAddLeave:global {
  color: var(--redDashboard);
}

.ActionAddVacation:global {
  color: var(--emeraldGreen);
}

.nowrapCell {
  white-space: nowrap;
}

.tooltipAddressWrapper {
  display: flex;
}

.tooltipAddressItem {
  display: block;
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionWrapper i {
  color: var(--limeGreen);
  cursor: pointer;
}

.mainPage{
    position: relative;
    background-color: var(--lightGrey);
    overflow-x: hidden;
}

.flowersImg{
    position: absolute;
    transform: rotate(180deg);
    top: 0;
    width: 100%;
    height: auto;
    z-index: 0;
}

@media screen and (max-width: 796px){
    .flowersImg{
        width: auto;
    }
}



.mainPageContainer {
    max-width: 1150px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 1;
    padding: 44px 46px;

}

.col{
    margin-bottom: 22px;
}

.textTitle {
    margin-bottom: 33px;

}

@media screen and (max-width: 796px){
    .mainPageContainer{
        padding: 24px;
    }
    .col{
        margin-bottom: 20px;
    }
}

.buttonWrapper{
    display: none;
}

@media screen and (max-width: 796px){
    .buttonWrapper{
        display: block;
        margin-bottom: 24px;
    }
}


/* default button */
.button:global.ant-btn {
  font-family: 'Exo 2', 'Roboto', Arial, sans-serif;
  border-radius: 6px;
  max-width: 150px;
  min-width: 150px;
  font-weight: 700;
  font-size: var(--fontSize);
  line-height: 1.25;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  transition: all 0.2s;
}

.button:global.ant-btn:hover,
.button:global.ant-btn:active {
  outline: none;
  border: none;
}

.button:global.ant-btn:disabled {
  background: var(--disabled);
  color: var(--white);
}

.buttonBigger:global.ant-btn {
  padding: var(--paddingBigger) 25px;
  font-size: var(--fontSizeBigger);
  height: 40px;
}

.wide:global.ant-btn {
  padding: 12px 40px;
  max-width: none;
  width: auto;
  min-width: 224px;
}

.iconOnly:global.ant-btn {
  padding: 0;
  width: 100%;
}

.buttonSizeAuto:global.ant-btn {
  max-width: none;
  width: auto;
  height: auto;
}

/* primary */
.primary:global.ant-btn,
.primary:global.ant-btn:focus {
  background: var(--buttonDarkBlue);
  color: var(--white);
}

.primary:global.ant-btn:active,
.primary:global.ant-btn:hover {
  background: var(--buttonLightBlue);
  color: var(--white);
}

.primary:global.ant-btn:disabled {
  background: var(--disabled);
  color: var(--white);
}

.primary:global.ant-btn:disabled:hover,
.primary:global.ant-btn:disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* outlined */
.outlined:global.ant-btn,
.outlined:global.ant-btn:focus {
  background: transparent;
  border: 1px solid var(--buttonDarkBlue);
  color: var(--buttonDarkBlue);
}

.outlined:global.ant-btn:active,
.outlined:global.ant-btn:hover {
  border: 1px solid rgba(0, 129, 183, 0.9);
  color: var(--white);
  background: rgba(0, 129, 183, 0.9);
}

.outlined:global.ant-btn:disabled {
  background: inherit;
  border: 1px solid var(--disabled);
  color: var(--disabled);
}

/* success */
.success:global.ant-btn,
.success:global.ant-btn:focus {
  background: var(--limeGreen);
  color: var(--white);
  transition: all 0.2s;
}

.success:global.ant-btn:active,
.success:global.ant-btn:hover {
  background: var(--limeGreen);
  color: var(--white);
  opacity: 0.7;
  transition: all 0.2s;
}

.success:global.ant-btn:disabled,
.success:global.ant-btn:disabled {
  background: var(--limeGreen);
  color: var(--white);
  opacity: 0.7;
  transition: all 0.2s;
}

.success:global.ant-btn:disabled,
.success:global.ant-btn:disabled:hover,
.success:global.ant-btn:disabled:active {
  background: var(--limeGreen);
  color: var(--white);
  opacity: 0.7;
  transition: all 0.2s;
}

/* secondary */
.secondary:global.ant-btn,
.secondary:global.ant-btn:focus {
  font-size: 14px;
  background: var(--secondary);
  color: var(--buttonDarkBlue);
  padding: 11px 13px;
}

.secondary:global.ant-btn:active,
.secondary:global.ant-btn:hover {
  background: var(--secondary);
  color: var(--buttonDarkBlue);
  transition: all 0.2s;
  opacity: 0.7;
}

/* transparent */
.transparent:global.ant-btn,
.transparent:global.ant-btn:focus {
  background: transparent;
  color: var(--lightBlack);
  outline: none;
  border: none;
  box-shadow: none;
}

.transparent:global.ant-btn:active {
  color: var(--lightBlack);
  background: transparent;
}

.transparent:global.ant-btn:hover {
  background: var(--buttonDarkBlue);
  transition: all 0.2s;
  color: var(--white);
}

.transparent:global.ant-btn:disabled:hover {
  color: var(--lightBlack);
  background: transparent;
}

/* Danger */
.danger:global.ant-btn,
.danger:global.ant-btn:focus {
  background: #da5b19;
  color: white;
  outline: none;
  border: none;
  box-shadow: none;
}

.danger:global.ant-btn:active {
  color: white;
  background: var(--redDashboard);
}

.danger:global.ant-btn:hover {
  transition: all 0.2s;
  color: white;
  background: var(--redDashboard);
}

.danger:global.ant-btn:disabled:hover {
  color: var(--lightBlack);
  background: transparent;
}

/* Secondary outlined*/

.secondaryOutlined:global.ant-btn,
.secondaryOutlined:global.ant-btn:focus {
  background: transparent;
  border: 1px solid var(--disabled);
  color: var(--accent);
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 12px;
}

.secondaryOutlined:global.ant-btn:active,
.secondaryOutlined:global.ant-btn:hover {
  color: var(--white);
  background: var(--accent);
}

.secondaryOutlined:global.ant-btn:disabled {
  background: inherit;
  border: 1px solid var(--disabled);
  color: var(--disabled);
}

/* fullTransparent */
.fullTransparent:global.ant-btn,
.fullTransparent:global.ant-btn:focus {
  background: transparent;
  color: var(--lightBlack);
  outline: none;
  border: none;
  box-shadow: none;
}

.fullTransparent:global.ant-btn:active {
  color: var(--lightBlack);
  background: transparent;
}

.fullTransparent:global.ant-btn:hover {
  background: transparent;
  color: var(--lightGreyDashBoard);
}

.fullTransparent:global.ant-btn:disabled {
  background: transparent;
  color: var(--lightGreyDashBoard);
}

.fullTransparent:global.ant-btn:disabled:hover {
  color: var(--lightGreyDashBoard);
  background: transparent;
}

.fullTransparent:global[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

/*Enable block below if you want to remove button click animation */

/*.transparent:global[ant-click-animating-without-extra-node]:after{*/
/*    -webkit-animation: none !important;*/
/*    -moz-animation: none !important;*/
/*    -o-animation: none !important;*/
/*    -ms-animation: none !important;*/
/*    animation: none !important;*/
/*}*/

.paginateSize:global.ant-btn {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;
}

/* negative */
.negative:global.ant-btn {
  background: var(--red);
  color: var(--white);
}

.negative:global.ant-btn:focus,
.negative:global.ant-btn:hover {
  background: rgba(218, 91, 21, 0.9);
  border-color: var(--red);
  color: var(--white);
}

.wrapper{
    display: flex;
    width: 100%;
    margin: 0;
}

.chartBlock{
    max-width: 740px;
    padding: 24px;
    background: var(--white);
    border-radius: 6px;
    width: 100%;
}

.chartBlock.squared{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.chartBlock.horizontal{
    width: 100%;
    max-width: none;
    display: flex;
}

.chartHeaderAndBodyWrapper{
    max-width: 740px;
    width: 100%;
}

.chartHeader{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.titleWrapper{
    display: block;
}

.title{
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
}

.withoutSubtitle{
    margin-bottom: 20px;
}

.title h2{
    font-size: 21px;
    line-height: 25px;
    font-family: "Exo 2",Arial,sans-serif;
    font-weight: 600;
    color: var(--lightBlack);
    margin: 0;
}

.arrowWrapper{
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2px);
}

.rightArrow{
    display: block;
    font-size: 16px;
    margin-left: 13px;
    color: var(--lightBlack);
}

.subtitle{
    color: var(--darkGreyDashboard);
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto,Arial,sans-serif;
    line-height: 20px;
}

.chartBody{
    width: 100%;
    height: 230px;
    padding: 17px;
}

.loaderWrapper:global.ant-col{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.errorText{
    margin-top: 20px;
    color: #959595;
    font-size: 14px;
    line-height: 20px;
    font-family: Roboto,Arial,sans-serif;
}

.turnoverSummary{
    padding-left: 50px;
    margin-top: 13px;
}

.accederButtonWrapper{
    background: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-left: 24px;
    padding-bottom: 20px;
    width: 100%;
}

.errorButton{
    display: block;
    margin-top: 20px;
}

.underChartButton{
    display: block;
    width: max-content;
}


@media screen and (max-width: 850px){
    .chartBlock{
        max-width: none;
    }
    .chartBody{
        padding: 17px 0 17px 0;
    }
    .chartBlock.squared{
        border-radius: 6px 6px 0 0;
    }
    .HRTabs{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

@media screen and (max-width: 560px){
    .chartHeader{
        flex-direction: column;
    }
    .titleWrapper{
        margin-bottom: 20px;
    }
    .turnoverSummary{
        padding-left: 0;
    }
    .chartBlock{
        padding: 15px;
    }
}


.textWelcome:global.ant-typography {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin-bottom: 24px;
}

.textStrong:global.ant-typography strong {
  font-weight: 500;
}

.text:global.ant-typography {
  font-weight: 300;
}

.emailWrapper:global.ant-typography {
  display: flex;
}

.emailWrapper:global.ant-typography a {
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  color: var(--text);
  margin-bottom: 24px;
}

.emailWrapper:global.ant-typography a:active,
.emailWrapper:global.ant-typography a:focus {
  border: 1px solid var(--text);
}

.emailIcon {
  margin-right: 10px;
}

.exclamationIcon:global.anticon {
  color: var(--darkBlue);
}

.caption:global.ant-typography {
  color: var(--white);
  opacity: 0.44;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.tooltip:global.ant-tooltip .ant-tooltip-content {
  margin-left: -13px;
}

.tooltip:global.ant-tooltip .ant-tooltip-arrow {
  left: -6px;
}

.registrationPage {
  max-width: 1121px;
  width: 100%;
  margin: auto;
}

.containerWrapper {
  padding: 24px;
}

/* h1 */
.title:global.ant-typography {
  margin-bottom: 32px;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: var(--text);
}

/* welcome content wrapper */
.welcomeWrapper {
  display: flex;
  align-items: flex-start;
}

/* image */
.imageWrapper {
  max-width: 225px;
  margin-right: 80px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  max-width: 720px;
  width: 100%;
}

/* Bonjour text */
.text:global.ant-typography {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--text);
}

.hello:global.ant-typography {
  margin-top: 15px;
  margin-bottom: 34px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.unit {
  position: relative;
  margin-bottom: 34px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--text);
}

.listUnit {
  display: flex;
}

/* list icon check */
.checkIconWrapper:global.ant-typography {
  margin-right: 36px;
  line-height: 40px;
  color: var(--darkBlue);
}

.warning {
  margin-bottom: 40px;
}

/* email */
.unit:global.ant-typography a {
  color: var(--accent);
  font-weight: 500;
}

/* email states */
.unit:global.ant-typography a:active,
.unit:global.ant-typography a:focus {
  border: 1px solid var(--text);
}

/* mobile */
@media screen and (max-width: 640px) {
  .welcomeWrapper {
    flex-direction: column;
    align-items: center;
  }

  .imageWrapper {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.customNotification {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    line-height: 16.41px;
    font-weight: 400;
    color: white;
    background: var(--darkBlue);
}

.closeButton:global.ant-btn-link {
    color: white;
    transform: translateY(-30%);
}

.closeButton i {
    width: 16px;
    height: 16px;
}
.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  padding: 0 10px;
}

.returnHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--backToHomeButtonGrey);
  padding: 14px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeArrowGrey);
  transition: 0.3s;
}

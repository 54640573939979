.content:global.ant-modal-wrap .ant-modal .ant-modal-content{
    border-radius: 6px;
}

.content:global.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-close,
.bodyContent :global .ant-typography {
    color: var(--lightBlack);
}

.content:global.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body{
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 35px 0;
}

.content.smallModal:global.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body{
    padding: 32px 0;
}

.content:global.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body{
    padding: 24px 0;
}

.textTitle:global.ant-typography {
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--lightBlack);
    margin-bottom: 0;
}

.isError:global.ant-typography {
    color: var(--redDashboard);
}

.isSmallModal .textTitle:global.ant-typography {
   font-weight: 500;
}

.header {
    margin-bottom: 20px;
}

.isSmallModal .header {
    margin-bottom: 10px;
}

.buttonsWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    width: 100%;
    flex-wrap: wrap;
}

.okAndCancelButtons{
    display: flex;
    justify-self: flex-end;
    margin-left: auto;
}

.btn:global.ant-btn {
    display: inline-block;
    max-width: 127px;
    min-width: 127px;
}

.btn.errorBtn:global.ant-btn {
    display: inline-block;
    max-width: 87px;
    min-width: 87px;
}

.btn:last-child {
    margin-left: 12px;
}

.footer {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.isSmallModal .footer {
    margin-top: 10px;
}

.infoWrapper {
    padding: 0 16px 16px 0;
    margin-right: auto;
}

.header, .footer, .bodyContent {
    padding: 0 35px;
}

.isSmallModal .header,
.isSmallModal .footer,
.isSmallModal .bodyContent {
    padding: 0 32px;
}

@media screen and (max-width: 640px) {
    .header {
        margin-bottom: 24px;
    }

    .footer {
        margin-top: 24px;
    }
}

@media screen and (max-width: 540px) {
    .header, .footer {
        flex: 0 0 auto;
    }

    .bodyContent {
        flex: 1;
        overflow-y: auto;
    }

    .content.fullMobileScreen :global .ant-modal {
        max-width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    .content.smallModal :global .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 0;
        padding: 0;
        height: auto;
        border-radius: 6px;
    }

    .content.fullMobileScreen:global.ant-modal-wrap .ant-modal .ant-modal-content{
        border-radius: 0;
        height: 100%;
    }

    .content.smallModal:global.ant-modal-wrap .ant-modal .ant-modal-content{
        border-radius: 8px;
        height: 100%;
    }

    .buttonsWrapper{
        margin-top: 0;
    }
    .footer{
        margin: 0;
    }
    .okAndCancelButtons{
        display: flex;
        margin-top: 5px;
        margin-left: 0;
    }
    .btn:global.ant-btn {
        max-width: 125px;
        min-width: auto;
    }
}

.messageWrapper {
  display: flex;
  align-items: center;
  color: var(--blue);
}

.leftGutter {
  margin-left: 24px;
}

.noLeftGutter {
  margin-left: 0;
}

.success {
  color: var(--emeraldGreen);
}

.error {
  color: var(--redError);
}

.text:global.ant-typography {
  display: inline-block;
  margin-left: 6px;
  color: inherit;
}

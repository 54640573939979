.container {
  max-width: 1150px;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
  padding: 10px;
}

.buttonWrapper {
  height: 44px;
  display: flex;
  margin-bottom: 40px;
}

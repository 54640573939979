.indicatorsWrapper {
  max-width: 363px;
  min-width: 290px;
  width: 100%;
  background: var(--white);
  height: auto;
  padding: 24px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  margin-left: 15px;
  flex-shrink: 2;
}

.indicatorsTitle {
  color: var(--lightBlack);
  margin: 0;
  font-size: 18px;
  font-family: "Exo 2";
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
}
.descriptionSubscribed,
.indicatorsPillars {
  align-self: center;
}
.indicatorsDescription {
  display: flex;
  flex-direction: column;
  gap: 30px;

  color: var(--darkGreyDashboard);
  font-family: Roboto, Arial, sans-serif;
  font-family: "Exo 2";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: left;
}
.indicatorsButton {
  margin: 0 9px;
  max-width: max-content;
  min-height: 44px;
  display: block;
}
.indicatorsButtonSpinner {
  margin: 0;
}
.indicatorsButtonSpinner :global .ant-spin {
  height: 20px;
}

@media screen and (max-width: 850px) {
  .indicatorsWrapper {
    height: 430px;
    margin: 10px 0;
    width: 100%;
    max-width: none;
  }
}

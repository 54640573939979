.table:global {
  margin-top: 24px;
  max-width: 730px;
}

.Icon:global.anticon {
  color: var(--limeGreen);
  margin-right: 6px;
}

.action:global.ant-typography {
  display: inline-block;
  cursor: pointer;
  color: var(--lightBlack);
  white-space: nowrap;
}

.card{
    background: var(--white);
    border-radius: 6px;
    color:var(--text);
    padding: 20px 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    transition: all .2s;
    margin-right: 13px;
}

.card:hover{
    box-shadow: 7px 10px 22px rgba(0, 0, 0, 0.18);
    border: 1px solid #E5E4E4;
    transition: all .2s;
}

.multiLinkCard{
    cursor: auto;
}

.titleWrapper{
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.titleWrapper img{
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 100px;
    min-height: 100px;
}

.title{
    font-family: 'Exo 2',Roboto,Arial,sans-serif;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    margin-left: 14px;
    display: inline-block;
    word-wrap: break-word;
    white-space: normal;
}

.buttonsWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.buttonsWrapper button{
    display: flex;
}

@media screen and (max-width: 404px){
    .buttonsWrapper{
        justify-content: center;
    }
    .buttonsWrapper button{
        margin-bottom: 5px;
    }
}

.row {
  align-items: center;
  margin-bottom: 20px;
}

.labelWrapper {
  display: flex;
  align-items: baseline;
  padding-right: 8px;
}

.errorOverlap {
  color: var(--redError);
  margin-bottom: 20px;
}

.select:global.ant-select,
.date:global {
  width: 100%;
}

.errorField :global .ant-select-selection {
  border-color: var(--redError) !important;
}

@media screen and (min-width: 576px) {
  .labelWrapper {
    flex-direction: column;
  }

  .textRightSm {
    text-align: right;
  }

  .date {
    max-width: 126px;
  }
}

.formLabel:global.ant-typography {
  color: var(--spinfiText);
  font-weight: 500;
}

@media screen and (max-width: 575px) {
  .formHelp {
    margin-left: 10px;
  }

  .col:not(:last-child):not(.noMargin) {
    margin-bottom: 10px;
  }

  .row {
    margin-bottom: 24px;
  }
}

.icon:global.anticon {
  margin-left: 6px;
  color: var(--success);
  cursor: pointer;
}

.tooltipOverlay:global.ant-tooltip {
  max-width: 304px;
}

.tooltipOverlay:global.ant-tooltip .ant-tooltip-inner {
  background: #336195;
}

.tooltipOverlay:global.ant-tooltip .ant-tooltip-arrow:before {
  /*max-width: none;*/
  background: #336195;
}

.formHelp {
  display: block;
  color: var(--spinfiText);
  font-size: 12px;
  font-weight: 300;
}

.fileItem {
  background-color: var(--tableRow);
  border-radius: 6px;
}

.removeIcon:global.anticon,
.calendarIcon:global.anticon {
  color: var(--limeGreen);
  cursor: pointer;
}

/* radio */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
  border-color: var(--limeGreen);
  color: var(--limeGreen);
  font-weight: 500;
}

/* hover */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper:hover {
  color: var(--generalGreen);
}

/* radio checked */
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--limeGreen);
  border-color: var(--limeGreen);
  box-shadow: -1px 0 0 0 var(--limeGreen);
  color: var(--white);
}

/* radio checked hover */
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: var(--generalGreen);
  border-color: var(--generalGreen);
  color: var(--white);
  box-shadow: -1px 0 0 0 var(--generalGreen);
}

/* radio common styles */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper,
.radioGroup:global.ant-radio-group
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  padding: 0 12px;
}

.spinner {
  padding: 10vh;
  margin: 0;
}

.infoMessage {
  margin-left: 35px;
}

.infoMessage :global .ant-typography {
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto, Arial, sans-serif;
}

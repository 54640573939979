.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.textWrapper,
.textWrapper h4 {
    width: 176px;
    color: var(--text);
}

.title {
    font-family: 'Exo 2', Roboto, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

.description {
    font-family: Roboto,Arial,sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.image {
    width: 100%;
    max-width: 138px;
    margin-right: 10px;
    align-self: flex-start;
}

.colTop:global.ant-col {
    align-items: flex-start;
}

.colNoMargin:global.ant-col.ant-col-24 {
    margin-bottom: 0;
}

/* col label title */
.formLabel {
    max-width: 440px;
}

.formItemsColumn {
    display: flex;
    flex-direction: column;
}

.formItemsRow {
    display: flex;
}

.formItem:global.ant-form-item {
    margin-bottom: 0;
    display: flex;
}

.formItemDescribeLeft:global.ant-form-item {
    margin-left: 32px;
}

/* DatePicker & TimePicker*/
.formItemPicker:global.ant-form-item {
    margin-right: 16px;
    max-width: 140px;
    width: 100%;
}

.formItemPicker:global.ant-form-item:first-of-type {
    margin-right: 16px;
}

.formItemPicker:global.ant-form-item .ant-time-picker-input:placeholder-shown {
    min-width: 140px;
}

/* info */
.infoWrapper:global.ant-row {
    display: flex;
    align-items: center;
}

.info {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: var(--text);
}

/* icon info */
.infoIcon:global.anticon-exclamation-circle {
    color: var(--darkBlue);
    margin-right: 15px;
}

/* icon label */
.labelIcon:global.anticon-check-circle {
    color: var(--disabled);
    margin-right: 15px;
}

/* button */
.buttonWrapper {
    justify-content: space-between;
}

/* input */
.formInput {
    min-width: 295px;
    width: 100%;
}

.formInputBig {
    min-width: 448px;
    width: 100%;
}

.formInputDescribe {
    min-width: 352px;
}

/* select */
.formSelect:global.ant-select {
    width: 295px;
}

/* radio */
.formItemRadio:global.ant-form-item {
    display: flex;
    margin-bottom: 10px;
}

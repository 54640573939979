.uploadWrapper {
  padding: 40px;
  background: var(--tableRow);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadIcon {
  margin: 0 auto 10px;
  width: 20px;
}

.uploadButtonTitle:global.ant-typography {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--text);
  margin-bottom: 8px;
}

.uploadDescription {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--textInTables);
  margin-top: 20px;
  max-width: 310px;
  margin-bottom: 20px;
}

.emptyList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyListText:global.ant-typography {
  color: var(--disabled);
}

.upload__controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.uploadButton:global.ant-btn {
  padding: 15px;
  min-height: 52px;
  max-height: 100px;
  height: max-content;
}

.upload__submitButton {
  width: 160px;
  height: 40px;
  background: var(--darkBlue);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload__submitButton.disabled {
  cursor: not-allowed;
  background: var(--disabled);
}

.upload__submitButtonIcon {
  margin-right: 10px;
}

.buttonText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
}

/* Styles for modal windows that uses ModalContainer */
.requestHelp :global .ant-modal-content,
.contactHR :global .ant-modal-content {
  margin: 24px;
  width: 384px;
}

.requestHelp :global .ant-modal-content,
.contactHR :global .ant-modal-footer {
  padding: 0;
}

.invoiceSearchCommentaryModal :global .ant-modal-body form,
.netPromoterScore :global .ant-modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.invoiceSearchCommentaryModal :global .ant-modal-content,
.massInvoiceActionsModal :global .ant-modal-content,
.validateAndPayModal :global .ant-modal-content,
.invoiceSearchInfoModal :global .ant-modal-content {
  width: 416px;
  margin: 16px auto auto;
  background: var(--darkBlue);
}

.invoiceSearchCommentaryModal :global .ant-modal-confirm-btns,
.massInvoiceActionsModal :global .ant-modal-confirm-btns,
.validateAndPayModal :global .ant-modal-confirm-btns,
.invoiceSearchInfoModal :global .ant-modal-confirm-btns {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.invoiceSearchCommentaryModal :global .ant-modal-confirm-btns .ant-btn,
.massInvoiceActionsModal :global .ant-modal-confirm-btns .ant-btn,
.validateAndPayModal :global .ant-modal-confirm-btns .ant-btn,
.invoiceSearchInfoModal :global .ant-modal-confirm-btns .ant-btn {
  min-width: 150px;
  background-color: var(--white);
  color: var(--darkBlue);
  font-weight: normal;
}

.invoiceSearchCommentaryModal :global .ant-modal-confirm-btns .ant-btn:hover,
.massInvoiceActionsModal :global .ant-modal-confirm-btns .ant-btn:hover,
.validateAndPayModal :global .ant-modal-confirm-btns .ant-btn:hover,
.invoiceSearchInfoModal :global .ant-modal-confirm-btns .ant-btn:hover,
.approveModal :global .ant-modal-confirm-btns .ant-btn:hover {
  color: var(--accent);
}

.acceptedSuccessfully :global .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: var(--darkBlue);
}

.iframeModal :global .ant-modal-content .ant-modal-body {
  padding: 0;
}

/* TODO: Manage approveModal styles */

.approveModal :global .ant-modal-content {
  margin-top: 16px;
  color: var(--white);
  background: var(--darkBlue);
}
.approveModal :global .ant-modal-footer {
  border-top: 1px solid var(--darkBlue);
  display: flex;
  justify-content: center;
}
.approveModal :global .ant-modal-footer .ant-btn {
  min-width: 150px;
  margin: 0 18px;
  background-color: var(--white);
  color: var(--darkBlue);
  font-weight: 500;
}
.approveModal :global .ant-modal-close,
.approveModal :global .ant-modal-close:hover {
  color: var(--white);
}
.approveModal :global .ant-input {
  max-width: 183px;
  resize: none;
}

/*Main logo*/
.mainLogo {
  max-height: 40px;
  height: 40px;
  margin: 0;
}

/* menu item */
.menuItem:global.ant-menu-item:hover,
.menuItem:global.ant-menu-submenu:hover,
.menuItem:global.ant-menu-submenu-active,
.menuItem:global.ant-menu-submenu,
.menuItem:global.ant-menu-item,
.menuItem:global.ant-menu-item-open,
.menuItem:global.ant-menu-item-selected,
.menuItem:global.ant-menu-submenu-selected {
  border: none;
}

.menuItem:global.ant-menu-item a:hover,
.menuItem:global.ant-menu-submenu a:hover,
.menuItem:global.ant-menu-submenu-active .ant-menu-submenu-title,
.menuItem:global.ant-menu-item-open .ant-menu-submenu-title {
  /*color: var(--accent);*/
}

/* subMenu item */
.subMenuItem:global.ant-menu-item {
  padding: 0;
}

.coloredDropdown:global.ant-select {
  width: 200px;
  color: var(--lightBlack);
  font-family: Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.coloredDropdown:global.ant-select-open {
  /*opacity: 50%;*/
  color: rgba(0, 0, 0, 0.25);
}

.coloredDropdown :global .ant-select-arrow {
  transition: 0.3s;
  transition-delay: 0.1s;
}

.coloredDropdown:global.ant-select-open :global .ant-select-arrow {
  color: rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  transition-delay: 0.1s;
}

.coloredDropdown :global .ant-select-selection__rendered {
  line-height: 16px;
  overflow: hidden;
  width: 100%;
}

.coloredDropdown :global .ant-select-search__field {
  color: #000;
}

.coloredDropdown:global.ant-select div.ant-select-selection {
  background-color: var(--white);
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.coloredDropdown :global .ant-select-arrow {
  right: 11px;
  color: #575756;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}

.coloredDropdown :global .ant-select-arrow i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 0;
}

.coloredDropdown:global.ant-select div.ant-select-selection--single {
  height: auto;
}

.option {
  font-family: Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.dropdownMenu {
  border-radius: 6px;
}

.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  background: var(--white);
}

.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:active,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdownMenu
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  color: var(--limeGreen);
}

/* link */
.subMenuItem:global.ant-menu-item a {
  padding: 0 16px;
}

.subMenuItem:global.ant-menu-item a:hover {
  background: rgba(0, 174, 217, 0.2);
  color: var(--textInFields);
}

.subMenuItem:global.ant-menu-item-selected a,
.subMenuItem:global.ant-menu-item-selected a:hover {
  color: var(--accent);
}

.apiKeyButton:global.ant-btn {
  padding: 5px;
  margin-right: 10px;
  color: var(--white);
}

.apiKeyButton:global.ant-btn i {
  margin: 0;
}

.apiKeySet:global.ant-btn {
  background-color: var(--limeGreen);
}

.apiKeyNotSet:global.ant-btn {
  background-color: var(--red);
}

.profileDropdown:global.ant-menu-item {
  margin-left: auto;
}

.dropdownArea > div > div .dropdownMenu {
  position: fixed;
}

@media screen and (max-width: 660px) {
  .profileDropdown:global.ant-menu-item {
    margin-left: auto;
  }
}

@media screen and (max-width: 800px) {
  .userMenu {
    margin-left: 0 !important;
    margin-right: 10px;
  }
}

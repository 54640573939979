.returnHome {
  display: flex;
  align-items: center;
  color: var(--backToHomeButtonDebtor);
  padding: 14px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  width: -moz-max-content;
}

.returnHome:active,
.returnHome:hover {
  color: var(--limeGreen);
}
.returnHome:active .arrowIcon,
.returnHome:hover .arrowIcon {
  color: var(--limeGreen);
  transition: 0.3s;
}

.returnHome span {
  margin-left: 10px;
}

.arrowIcon {
  font-size: 16px;
  color: var(--backToHomeButtonDebtor);
  transition: 0.3s;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  border-radius: 6px;
  min-height: 255px;
}

.col:global.ant-col {
  width: 95%;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.hubPageBackground {
  background: var(--servicesSectionBG);
  padding: 10px 0 10px;
  width: 100%;
  min-height: 550px;
}

.table td {
  color: var(--lightBlack);
}

.emboldened {
  font-weight: 500;
}

.table:global.ant-table-wrapper .ant-table-thead > tr > th {
  color: var(--lightBlack);
}

.table:global.ant-table-wrapper .ant-table-tbody tr:nth-child(even) > td {
  background: white;
}

.table:global.ant-table-wrapper .ant-table-tbody tr > td {
  border-bottom: 1px solid var(--linesTable);
}

.debtorsTable {
  padding: 10px;
  border-radius: 6px;
  width: 100%;
}

.debit,
.debitSum {
  color: var(--redDashboard);
}

.credit,
.creditSum {
  color: var(--emeraldGreen);
}

.creditSum,
.debitSum,
.saldoSum {
  width: 100px;
  font-weight: 700;
}

.creditSum {
  padding-left: 10px;
}

.saldoSum {
  padding-left: 18px;
  color: var(--textInFields);
}

.totalName {
  color: var(--lightBlack);
  font-weight: 700;
  margin-right: auto;
}

.table:global.ant-table-wrapper {
  background: white;
}

.table:global.ant-table-wrapper div.ant-table-footer {
  background: white;
  padding: 10px 16px;
}

.tableFooter {
  display: flex;
  place-content: flex-end;
}

.table:global.ant-table-wrapper ul.ant-table-pagination {
  float: right;
  margin: 16px 20px;
}

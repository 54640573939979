.textDefault {
  margin-bottom: 10px;
  font-size: 16px;
}

.textTitle {
  margin: 20px auto 10px;
  font-size: 18px;
  text-align: center;
}

.textQuestion {
  padding: 0 24px;
  margin-bottom: 0;
}

.modalButtonWrapper {
  width: 100%;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 11px;
}

.icon {
  font-size: 36px;
}

.table {
  margin-bottom: 10px;
}

.table thead > tr > th,
.table tbody > tr > td {
  text-align: center;
  padding: 16px 0;
}

.table tbody > tr > td {
  border-right: 1px solid var(--linesList);
  cursor: pointer;
  padding: 0;
  height: 54px;
}

.table tbody > tr > td:hover,
.tableCell:hover {
  background-color: var(--disabled) !important;
}

.table tbody > tr :last-child {
  border-right: none;
}

.tableCell {
  padding: 16px 0;
  height: 100%;
}

.tableCellChecked {
  background-color: var(--tableDefaultHover);
}

.col:global.ant-col {
    width: 60%;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 30px 20px;
}

.rowText:global.ant-typography {
    margin-bottom: 0;
    min-width: 300px;
    font-weight: 300;
    color: black;
    font-size: 14px;
    line-height: 20px;
}

.accented:global.ant-typography {
    color: var(--darkBlue);
    font-weight: 500;
}

.list {
    display: flex;
    flex-direction: column;
}

.line {
    display: flex;
    flex-direction: row;
}

.formCard:global.ant-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 60px;
  background: var(--darkBlue);
  color: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: fit-content;
  max-width: 470px;
  min-width: 470px;
  min-height: 506px;
}

.formCard:global.ant-form .ant-form-item-label {
  line-height: 1;
}
.formCard:global.ant-form .ant-form-item-label label::after {
  content: '';
}

.formCard:global.ant-form .ant-form-item div.ant-typography {
  margin-bottom: 0;
  font-weight: 500;
}

.formCard:global.ant-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
  font-weight: 500;
}

.formCard :global .has-error .ant-input,
.formCard :global .has-error .ant-input:not([disabled]):hover,
.formCard :global .has-error .ant-input:focus,
.formCard :global .has-error .ant-input-affix-wrapper .ant-input,
.formCard :global .has-error .ant-input-affix-wrapper .ant-input:hover,
.formCard :global .has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: var(--red);
}
.formCard :global .has-error .ant-form-explain,
.formCard :global .has-error .ant-form-split {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: var(--red);
}
.formCard :global .has-error .ant-form-explain::before,
.formCard :global .has-error .ant-form-split::before {
  content: url('../../assets/icons/error.svg');
  margin-right: 6px;
}

.textTitle:global.ant-typography {
  color: var(--white);
  margin: 0 0 24px;
  font-size: 24px;
  font-family: 'Exo 2';
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.textLabel:global.ant-typography {
  display: inline-flex;
  color: var(--white);
  opacity: 0.44;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  color: var(--white);
  margin-bottom: 16px;
}
.linkWrapper:only-of-type {
  margin-bottom: 0px;
}
.linkWrapper:active,
.linkWrapper:focus {
  outline: 1px solid var(--white);
}

.bottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questions {
  position: absolute;
  bottom: 30px;
  right: 60px;
}

.termsOfUseWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: none;
}

.wrapper {
  padding-top: 60px;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.modalWrapper {
  max-width: 731px;
  width: 100%;
  margin: 42px auto 0;
  padding: 36px 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
  z-index: 10;
  background: white;
}

.buttonsWrapper {
  display: flex;
  margin-top: 24px;
}

.flowersImg {
  width: 100%;
  height: auto;
  z-index: 3;
}

/*Main logo*/
.mainLogo {
  max-height: 40px;
  height: 40px;
}

.descriptionWrapper {
  width: 95%;
  margin: 30px auto 0;
}

.optionsTitle {
  font-family: 'Exo 2', Arial, sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: var(--lightBlack);
  display: block;
}

.secondSubtitle {
  margin-top: 27px;
}

.freePeriodOptionsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.freePeriodOption {
  flex: 0 1 100%;
  display: flex;
  margin-top: 17px;
}

.checkMarkImage {
  display: block;
}

.freePeriodOptionText {
  display: block;
  margin-left: 17px;
  font-family: Roboto, Arial, sans-serif;
  color: var(--lightBlack);
  font-weight: 400;
  font-size: 16px;
}

.cost {
  margin-top: 10px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #929292;
}

.checkBox {
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.checkBox input {
  display: none;
}

.checkBox label {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.checkBox label span {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--lightBlack);
  margin-left: 10px;
}

.box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dce5e7;
  transform: translateY(1px);
}

.box.checked {
  background: var(--limeGreen);
  border: 1px solid var(--limeGreen);
}

.checkMark {
  font-size: 13px;
  color: var(--limeGreen);
  display: none;
}

.checkMark.checked {
  display: block;
  color: white;
}

.emptyBlock {
  margin-top: auto;
}

.agreeButton:global.ant-btn {
  font-weight: 800;
  margin-top: 33px;
}

.FAQ {
  width: 731px;
  margin: 15px auto 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  color: #aaaaaa;
}

.FAQitem {
  margin-bottom: 10px;
}

.FAQTitle {
  margin-bottom: 15px;
}

.question {
  font-weight: 700;
}

.answer {
  font-weight: 400;
}

.checkBoxLabelWrapper {
  display: block;
}
.link {
  color: var(--limeGreen);
}
.link:hover {
  color: var(--limeGreen);
}

.subOptionsWrapper {
  margin-left: 36px;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin-top: 34px;
  padding: 5px 15px;
  border-radius: 6px;
  min-height: 255px;
}

.hubPageBackground {
  background: var(--servicesSectionBG);
  padding: 38px 0 10px;
  width: 100%;
  min-height: 550px;
}

.flowersImg {
  width: 100%;
  height: auto;
  transform: rotate(180deg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.dropdown {
  border-radius: 6px;
}

.dropdown :global .ant-select-dropdown-menu {
  max-height: 300px;
}

.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  background-color: var(--white) !important;
}

.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:active,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  color: var(--limeGreen);
}

.dropdown :global .ant-select-dropdown-menu-item-selected,
.dropdown :global .ant-select-dropdown-menu-item:hover {
  font-weight: 400;
}

.select:global.ant-select {
  width: 256px;
  background: var(--white);
  border-radius: 4px;
}

.select :global .anticon {
  font-size: 10px;
  color: var(--lightBlack);
}

.select:global.ant-select .ant-select-selection {
  color: var(--lightBlack);
  border-color: var(--linesList);
  border-radius: 6px;
}

/* select focuse & active, hover*/
.select:global.ant-select .ant-select-selection:hover,
.select:global.ant-select .ant-select-focused .ant-select-selection,
.select:global.ant-select .ant-select-selection:focus,
.select:global.ant-select .ant-select-selection:active {
  border: 1px solid var(--text);
  box-shadow: none;
}

/* disabled, disabled hover reset, disabled focus and active reset*/
.select:global.ant-select-disabled .ant-select-selection,
.select:global.ant-select-disabled .ant-select-selection:hover,
.select:global.ant-select-disabled .ant-select-focused .ant-select-selection,
.select:global.ant-select-disabled .ant-select-selection:focus,
.select:global.ant-select-disabled .ant-select-selection:active {
  border-color: var(--greyDisabled);
}

.select:global.ant-select-disabled .ant-select-selection {
  /*border: none !important;*/
}

/* hint */
.hint:global.ant-select .ant-select-selection {
  color: var(--text);
}

/* arrow */
.select:global.ant-select .ant-select-arrow {
  color: var(--textInFields);
}

/* if content need to do wider then selectbox */
.offsetContent :global {
  width: 100% !important;
  max-width: 320px !important;
}

.showSearch
  :global
  .ant-select-selection--multiple
  .ant-select-search--inline
  .ant-select-search__field {
  padding: 0;
}

.showSearch
  :global
  .ant-select-selection--multiple
  .ant-select-selection__placeholder {
  padding-left: 24px;
  color: var(--lightBlack);
}

.showSearch:global.ant-select-show-arrow
  .ant-select-selection--multiple
  .ant-select-selection__rendered {
  padding-left: 30px;
  position: relative;
}

.showSearch:global.ant-select-show-arrow
  .ant-select-selection--multiple
  .ant-select-selection__rendered:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M15.7656 14.6895L10.6934 9.61719C11.4805 8.59961 11.9062 7.35547 11.9062 6.04688C11.9062 4.48047 11.2949 3.01172 10.1895 1.9043C9.08398 0.796875 7.61133 0.1875 6.04688 0.1875C4.48242 0.1875 3.00977 0.798828 1.9043 1.9043C0.796875 3.00977 0.1875 4.48047 0.1875 6.04688C0.1875 7.61133 0.798828 9.08398 1.9043 10.1895C3.00977 11.2969 4.48047 11.9062 6.04688 11.9062C7.35547 11.9062 8.59766 11.4805 9.61523 10.6953L14.6875 15.7656C14.7024 15.7805 14.72 15.7923 14.7395 15.8004C14.7589 15.8084 14.7797 15.8126 14.8008 15.8126C14.8218 15.8126 14.8427 15.8084 14.8621 15.8004C14.8815 15.7923 14.8992 15.7805 14.9141 15.7656L15.7656 14.916C15.7805 14.9011 15.7923 14.8835 15.8004 14.864C15.8084 14.8446 15.8126 14.8238 15.8126 14.8027C15.8126 14.7817 15.8084 14.7609 15.8004 14.7414C15.7923 14.722 15.7805 14.7043 15.7656 14.6895ZM9.14062 9.14062C8.3125 9.9668 7.21484 10.4219 6.04688 10.4219C4.87891 10.4219 3.78125 9.9668 2.95312 9.14062C2.12695 8.3125 1.67188 7.21484 1.67188 6.04688C1.67188 4.87891 2.12695 3.7793 2.95312 2.95312C3.78125 2.12695 4.87891 1.67188 6.04688 1.67188C7.21484 1.67188 8.31445 2.125 9.14062 2.95312C9.9668 3.78125 10.4219 4.87891 10.4219 6.04688C10.4219 7.21484 9.9668 8.31445 9.14062 9.14062Z' fill='%23E3E3E3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  top: 11px;
  left: 6px;
  width: 16px;
  height: 16px;
  content: '';
}

.paleLabelWrapper {
  display: flex;
}

.paleLabel {
  color: var(--darkGreyDashboard);
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected
  :local
  .paleLabel,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover
  :local
  .paleLabel,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:active
  :local
  .paleLabel,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover
  :local
  .paleLabel,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active
  :local
  .paleLabel {
  color: var(--limeGreen);
}

.selectTooltipWrapper {
  display: flex;
  flex-wrap: wrap;
}

.selectTooltipItem {
  display: block;
  white-space: nowrap;
  margin-left: 3px;
}

.tooltip:global.ant-tooltip .ant-tooltip-arrow {
  display: none;
}

.deleteButton:global.ant-btn {
  right: 16px;
  position: absolute;
  color: var(--text);
  width: 16px;
  height: 16px;
  line-height: 16px;

  & > i {
    width: 16px;
    height: 16px;
  }
}

.deleteButton:global.ant-btn:hover {
  color: var(--red);
}

.icon:global.anticon {
  color: var(--linesList);
  font-size: 32px;
  margin-bottom: 22px;
}

.content:global {
  text-align: center;
  line-height: 1.2;
  padding: 75px 0;
}

.text:global.ant-typography {
  color: var(--spinfiText);
  margin-bottom: 20px;
  font-size: 16px;
}

.button:global.ant-btn {
  display: inline-block;
  background-color: var(--limeGreen);
  min-width: auto;
  max-width: none;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.button:global.ant-btn:hover,
.button:global.ant-btn:focus {
  background-color: var(--generalGreen);
}

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: white;
  min-height: 100%;
  padding: 24px;
  border-radius: 6px;
}

.commentTextarea {
  outline: none;
  border: 1px solid transparent;
  padding: 14px;
  width: 336px;
  height: 151px;
  background: #f2f2f2;
  border-radius: 6px;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  color: #a4a4a4;
  display: block;
}

.commentTextarea::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

/* Track */
.commentTextarea::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.commentTextarea::-webkit-scrollbar-thumb {
  position: relative;
  background: #b8b8b8;
  border-radius: 6px;
  border-right: 4px solid #f2f2f2;
  border-left: 4px solid #f2f2f2;
}

.textareaError {
  border: 1px solid #df512f;
}

.textAreaWrapper {
  position: relative;
}

.error {
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.errorText {
  color: var(--redError);
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  margin-top: 40px;
}

.backButton {
  margin-left: 12px;
}

.button:global.ant-btn {
  max-width: 124px;
  min-width: 124px;
}

.header {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  display: block;
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: var(--lightBlack);
}

.editCommentaryDate {
  color: var(--lightGreyDashBoard);
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  align-self: flex-end;
}

/* DATE PICKER -------------------------------------------------------*/

.datePicker {
  width: 132px;
}

.datePicker :global .anticon-calendar {
  color: var(--limeGreen);
}

.datePicker :global .ant-calendar-picker-input {
  border: 1px solid #e3e3e3;
  color: var(--lightBlack);
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.datePicker:global.ant-calendar-picker:hover
  .ant-input.ant-calendar-picker-input:not(.ant-input-disabled) {
  border: 1px solid #e3e3e3;
}

.dateDrop {
  width: 132px;
  background: transparent;
}

.dateDrop :global .ant-calendar {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 102px !important;
}

.dateDrop :global .ant-calendar-panel {
  position: relative;
  width: 102px;
}

.dateDrop :global .ant-calendar-panel .ant-calendar-input-wrap {
  width: 102px;
  border-bottom: none;
}

.dateDrop :global .ant-calendar-panel .ant-calendar-date-panel {
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.19);
  border-radius: 6px;
}

.dateDrop :global .ant-calendar {
  width: auto;
}

.dateDrop :global .ant-calendar-date-panel {
  width: 391px;
}

.dateDrop :global .ant-calendar-header {
  background: var(--white);
  transform: translateX(-1px);
  border-radius: 6px 6px 0 0;
  height: auto;
  padding-top: 20px;
}

.dateDrop :global .ant-calendar-header .ant-calendar-my-select a {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--lightBlack);
}

.dateDrop :global .ant-calendar-header .ant-calendar-prev-year-btn {
  display: none;
}
.dateDrop :global .ant-calendar-header .ant-calendar-next-year-btn {
  display: none;
}

.dateDrop :global .ant-calendar-header .ant-calendar-prev-month-btn {
  left: auto;
  right: 64px;
}
.dateDrop :global .ant-calendar-header .ant-calendar-next-month-btn {
  right: 33px;
}
.dateDrop :global .ant-calendar-header .ant-calendar-prev-month-btn:before,
.dateDrop :global .ant-calendar-header .ant-calendar-next-month-btn:before {
  height: 13px;
  width: 13px;
  border-color: var(--lightBlack);
  border-width: 2px 0 0 2px;
}
.dateDrop
  :global
  .ant-calendar-header
  .ant-calendar-prev-month-btn:hover:before,
.dateDrop
  :global
  .ant-calendar-header
  .ant-calendar-next-month-btn:hover:before {
  height: 13px;
  width: 13px;
  border-color: var(--lightGreyDashBoard);
  border-width: 2px 0 0 2px;
}

.dateDrop :global .ant-calendar-input-wrap {
  width: 91px;
}

.dateDrop :global .ant-calendar-header {
  text-align: start;
  border-bottom: none;
}

.dateDrop :global .ant-calendar-header .ant-calendar-my-select {
  margin-left: 29px;
  pointer-events: none;
}

.dateDrop :global .ant-calendar-body {
  background: var(--white);
  border: none;
  outline: none;
  overflow: hidden;
  transform: translateX(-1px);
  border-radius: 0 0 6px 6px;
  height: auto;
  padding-bottom: 20px;
}

.dateDrop :global .ant-calendar-body .ant-calendar-table thead {
  background: #fafafa;
}

.dateDrop
  :global
  .ant-calendar-body
  .ant-calendar-selected-day
  .ant-calendar-date {
  border-radius: 4px;
  border: 1px solid var(--limeGreen);
  background: var(--limeGreen);
  color: var(--white);
}

.dateDrop :global .ant-calendar-body .ant-calendar-date {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateDrop :global .ant-calendar-body .ant-calendar-today .ant-calendar-date {
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}

.dateDrop
  :global
  .ant-calendar-body
  .ant-calendar-selected-day.ant-calendar-today
  .ant-calendar-date {
  color: var(--white);
  font-weight: 400;
}

.dateDrop :global .ant-calendar-body .ant-calendar-date:hover {
  border: 1px solid var(--limeGreen);
  background: var(--limeGreen);
  color: var(--white);
  transition: all 0s;
}

.dateDrop :global .ant-calendar-body .ant-calendar-date {
  transition: all 0s;
}

.dateDrop
  :global
  .ant-calendar-body
  .ant-calendar-selected-day.ant-calendar-disabled-cell
  .ant-calendar-date {
  background: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.25);
}

.dateDrop
  :global
  .ant-calendar-body
  .ant-calendar-selected-day.ant-calendar-disabled-cell
  .ant-calendar-date:before {
  display: none;
}

.dateDrop :global .ant-calendar-footer {
  display: none;
}

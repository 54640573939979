/* default */
.input:global.ant-input-password .ant-input,
.input:global.ant-input {
    border-radius: 4px;
    padding: 8px 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: var(--textInFields);
}

/* input active, focused & hover*/
.input:global.ant-input-password .ant-input:focus,
.input:global.ant-input-password .ant-input:active,
.input:global.ant-input-password .ant-input:hover,
.input:global.ant-input-password .ant-input:focus,
.input:global.ant-input-password .ant-input:active,
.input:global.ant-input:hover,
.input:global.ant-input:active,
.input:global.ant-input:focus {
    border: 1px solid var(--text);
    box-shadow: none;
}

.input:global.ant-input:disabled {
    color: var(--text);
    opacity: 0.5;
}

.error:global.ant-input {
    border: 1px solid var(--red);
}

.error:hover {
    border: 1px solid var(--text);
}

.hint {
    color: var(--lines);
}
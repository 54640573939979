.breadcrumb.breadcrumb {
    padding-left: 3px;
    font-size: 12px;
    line-height: 14px;
}

.breadcrumb.breadcrumb :global span > span > span {
    color: var(--textInTables);
    cursor: pointer;
}

.breadcrumb.breadcrumb :global span > span > span:hover {
    color: var(--accent);
}

.breadcrumb.breadcrumb :global .ant-breadcrumb-separator {
    vertical-align: bottom;
    font-size: 16px;
}

.breadcrumb.breadcrumb :global .anticon {
    vertical-align: bottom;
}

.breadcrumb.breadcrumb.breadcrumb :global .anticon-down {
    display: none;
}

.dropdownMenu.dropdownMenu {
    padding: 0;
}

.dropdownMenu.dropdownMenu :global .ant-dropdown-menu-item {
    line-height: 18px;
    padding-left: 10px;
}

.dropdownMenu.dropdownMenu :global span {
    margin-left: 6px;
    font-size: 12px;
    line-height: 14px;
    color: var(--textInTables);
}

.icon.icon {
    color: var(--darkBlue);
}

.textTitle {
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--white);
}
.textDefault {
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--white);
}

.field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.radioField {
  margin-bottom: 20px;
}
.commentaryField {
  display: flex;
  flex-direction: column;
}

.modalButtonWrapper {
  width: 100%;
  justify-content: flex-end;
}
.cancelButton {
  margin-right: 11px;
}

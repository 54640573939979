.searchPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.searchTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--text);
}

.searchInput:global.ant-input-search {
    margin-left: 10px;
    width: 352px;
}

.dropdownMenu:global.ant-dropdown-menu {
    padding: 0;
}

.dropdownMenu:global.ant-dropdown-menu .ant-dropdown-menu-item {
    line-height: 18px;
    padding-left: 10px;
}

.dropdownMenu:global.ant-dropdown-menu span {
    margin-left: 6px;
    font-size: 12px;
    line-height: 14px;
    color: var(--textInTables);
}

.table :global .ant-table-tbody tr > td {
    padding: 14px 16px;
}

.table :global .ant-table-thead th:nth-child(n+2) {
    width: 15%;
}

.table :global .ant-table-thead th:last-child,
.table :global .ant-table-tbody td:last-child {
    text-align: center;
}

.table :global .anticon-folder + span {
    font-weight: bold;
    color: var(--text);
}

.Button_download {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}
.Button_download:hover {
    background-color: transparent;
}

.Icon:global.anticon-folder,
.Icon:global.anticon-download {
    color: var(--darkBlue);
    font-size: 17px;
}

.Icon:global.anticon-file {
    color: var(--text);
    font-size: 17px;
}

.tableRow {
    height: 17px;
    display: flex;
    align-items: center;
}

.iconImage {
    height: 17px;
}

.fileName {
    margin-left: 10px;
    color: var(--darkBlue);
    vertical-align: center;
}

.folderName {
    margin-left: 10px;
    color: var(--darkBlue);
    cursor: pointer;
}

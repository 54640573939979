/* radio */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper {
    border-color: var(--lines);
}

/* hover */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper:hover {
    color: var(--darkBlue);
}

/* radio checked */
.radioGroup:global.ant-radio-group .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--accent);
    border-color: var(--accent);
    box-shadow: -1px 0 0 0 var(--accent);
}

/* radio checked hover */
.radioGroup:global.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: var(--darkBlue);
    border-color: var(--darkBlue);
    color: var(--white);
    box-shadow: -1px 0 0 0 var(--darkBlue);
}
.filtersRow {
  display: flex;
}

.select:global.ant-select {
  max-width: 256px;
  min-width: 256px;
  margin-left: 8px;
}

.searchInput:global.ant-input-affix-wrapper {
  max-width: 268px;
  margin-left: 8px;
}

.searchIconPrefix svg {
  fill: #e3e3e3;
}

.checkBox {
  margin-left: 18px;
}

.searchAndCheckbox {
  display: flex;
  flex-grow: 1;
}

.yearMonthSwith:global.ant-radio-group {
  display: flex;
  margin-right: 8px;
  width: max-content;
}

.yearMonthSwith:global.ant-radio-group .ant-radio-button-wrapper-checked {
  color: #fff !important;
}

.yearMonthSwith label {
  height: 38px;
  line-height: 38px;
}

.monthPickerAndSelect {
  display: flex;
}

.onlyAbsencesSwitch {
  display: none;
}

.iconsWrapper {
  display: none;
  flex-wrap: wrap;
  align-items: center;
}

.filterIconWrapper {
  position: relative;
}

.filterIconBadge {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--redDashboard);

  position: absolute;
  top: 2px;
  right: -5px;
}

.searchIconWrapper {
  display: flex;
  margin-left: 15px;
}
.searchIcon svg {
  width: 19px;
  height: 19px;
  fill: var(--textInTables);
}

.searchIcon.completed:global.anticon-check-circle {
  color: var(--limeGreen);
}

.filterIconBadge {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--redDashboard);

  position: absolute;
  top: 2px;
  right: -5px;
}

.searchInputMobile:global.ant-input-affix-wrapper {
  margin-bottom: 10px;
}

.selectEmployee:global.ant-select {
  margin-left: 8px;
}

.selectEmployeeMobile:global.ant-select {
  margin-bottom: 8px;
  width: 100%;
}

@media screen and (max-width: 1050px) {
  .filtersRow {
    flex-wrap: wrap;
  }
  .monthPickerAndSelect {
    width: 100%;
  }
  .searchAndCheckbox {
    width: 100%;
    margin-top: 15px;
  }
  .searchInput:global.ant-input-affix-wrapper {
    margin-left: 0;
  }
  .select:global.ant-select {
    margin-left: 30px;
  }
}

@media screen and (max-width: 650px) {
  .iconsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: 15px;
    margin-right: 15px;
  }
  .select:global.ant-select {
    display: none;
  }
  .searchInput:global.ant-input-affix-wrapper {
    display: none;
  }
  .onlyAbsencesSwitch {
    display: none;
  }
  .monthPicker {
    max-width: none;
    width: 100%;
    min-width: 50px;
  }
  .checkBox {
    display: none;
  }
  .monthPicker :global .ant-calendar-picker > div {
    padding: 0;
  }
  .monthPickerAndSelect {
    margin-bottom: 20px;
  }
}

.table:global {
  margin-top: 24px;
  max-width: 640px;
}

.Icon:global.anticon {
  color: var(--limeGreen);
}

.action:global.ant-btn {
  display: inline-block;
  cursor: pointer;
  border: none;
  padding: 0 5px;
}

.action + .action {
  margin-left: 6px;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
}

.customColumn:global.ant-table-row-cell-break-word {
  color: var(--textInTables) !important;
}

.select:global.ant-select {
  width: 100%;
}

.dropdown {
  border-radius: 6px;
}

.dropdown :global .ant-select-dropdown-menu {
  max-height: 300px;
}

.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  background-color: var(--white) !important;
}

.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-selected,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item:active,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:hover,
.dropdown
  :global
  .ant-select-dropdown-menu
  :global
  .ant-select-dropdown-menu-item-active:active {
  color: var(--limeGreen);
}

.dropdown :global .ant-select-dropdown-menu-item-selected,
.dropdown :global .ant-select-dropdown-menu-item:hover {
  font-weight: 400;
}
.darkIcon:global.anticon {
  color: var(--emeraldGreen);
}
.blackIcon:global.anticon {
  color: var(--lightBlack);
}
.greenIcon:global.anticon {
  color: var(--limeGreen);
}

.input:global.ant-form-item .ant-form-explain {
  display: none;
}

.section {
    display: flex;
    color: var(--mainPageText);
    padding-bottom: 56px;
    align-items: center;
    margin-top: 73px;
}

.section img{
    height: auto;
    max-width: 312px;
    width: 100%;
}

.advantagesWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.advantagesItem {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 0 1 50%;
}

.advantagesItem img {
    width: 100%;
    max-width: 120px;
    height: auto;
    margin-right: 10px;
}

.advantageText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 170px;
    padding-top: 20px;
}

.advantageTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
}

.advantageDescription {
    font-family: Roboto,Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
}


@media screen and (max-width: 945px){
    .section{
        flex-direction: column;
        margin-top: 40px;
    }
}

@media screen and (max-width: 560px){
    .section{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 30px;
    }
    .advantagesWrapper{
        order: -1;
    }
    .advantagesItem{
        flex: 0 1 100%;
        margin-bottom: 25px;
    }
}
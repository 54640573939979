.wrapper {
    display: flex;
    line-height: 32px;
}

.button:global.ant-btn{
    border-color: #28598F;
    margin-right: 32px;
    font-weight: 600;
    max-width: 115px;
    min-width: 115px;
    width: 115px;
}

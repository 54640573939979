.loaderWrap {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table:global {
  margin-top: 24px;
}

.overflowHidden.table:global.ant-table-wrapper
  .ant-table-body::-webkit-scrollbar {
  height: 0px;
}

.Icon:global.anticon {
  color: var(--limeGreen);
  cursor: pointer;
  padding: 0;
  width: 10px;
  /*display: flex;*/
  align-items: center;
}

.Icon:global.anticon svg {
  transform: translateX(-5px);
}

.Action:global {
  display: inline-block;
  cursor: pointer;
}

.columnTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.subTitle {
  font-size: 12px;
  color: var(--textInTables);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

th.editableCell:global {
  height: 65px !important;
}

th.editableCell:global,
.input:global.ant-input-affix-wrapper .ant-input,
.input:global.ant-input-number .ant-input-number-input {
  text-align: right !important;
}

.input:global.ant-input-number {
  width: 100%;
}
.input:global.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}

.input:global.ant-input-number .ant-input-number-input-wrap,
.input:global.ant-input-number .ant-input-number-input {
  height: 100%;
}

td.editableCell:global {
  padding: 0 !important;
}

.input:global.ant-input-affix-wrapper .ant-input,
.input:global.ant-input-number {
  border: none;
  border-radius: 0;
  height: 48px;
}

.input:global.ant-input-number .ant-input-number-input {
  padding-right: 30px;
}

.input:global.ant-input-affix-wrapper .ant-input:focus,
.input:global.ant-input-number:focus {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid var(--textInTables);
}

.input:global.ant-input-number.ant-input-number-focused {
  box-shadow: none;
  border: 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--skyBlue);
}

.input:global.ant-input-affix-wrapper .ant-input-suffix,
.customCellSuffix:global.ant-typography {
  color: var(--linesList);
}

.customCell {
  padding-right: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.customCellDisabled {
  cursor: not-allowed;
}

.customCellSuffix {
  margin-left: 10px;
}

.inputWrap {
  position: relative;
  width: 100%;
  height: 48px;
}
.inputWrap .inputSuffix {
  position: absolute;
  right: 12px;
  top: 17px;
  color: var(--linesList);
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../assets/images/loginNewBg.svg") no-repeat bottom
    fixed;
  background-size: contain;
  width: 100%;
  height: 100%;
  z-index: 0;
  gap: 94px;
  transition: gap 0.1s;
}

@media screen and (max-width: 1185px) {
  .background {
    gap: 20px;
  }
}

@media screen and (max-width: 1030px) {
  .background {
    padding: 160px 0;
    gap: 94px;
    flex-direction: column;
    overflow: auto;
    height: auto;
    background-size: auto;
  }
}

.logoLink {
  position: absolute;
  z-index: 0;
  margin-top: 56px;
  margin-left: 80px;
}

@media screen and (max-height: 815px) {
  .logoLink {
    display: none;
  }
}
@media screen and (max-width: 1030px) {
  .logoLink {
    display: block;
  }
}

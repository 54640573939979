.serviceCardsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;
    position: relative;
    z-index: 1;
}

.backgroundWrapper{
    background: #F2F2F2;
    padding-top: 47px;
}

.defaultCardsWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;
    margin-bottom: 28px;
}
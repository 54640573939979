.newsCard {
  display: flex;
  height: 90px;
  width: 100%;
  background-color: var(--white);
  padding: 12px 12px 12px 8px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
}

.newsThumbnailWrapper {
  display: flex;
  align-items: center;
  min-width: 56px;
  max-width: 56px;
  width: 56px;
  height: 100%;
}

.newsThumbnail {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.newsEmptyThumbnail {
  transform: translateX(6px);
}

.newsCardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.newsDate {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--grey, #87888a);
}

.newsTitleWrapper {
  display: flex;
  font-family: 'Exo 2';
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: var(--Design-colors-Dark-blue, #005286);
}

.newsTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 452px;
}

.newsTitle:hover,
.newsTitle:active,
.newsTitle:focus {
  color: var(--buttonDarkBlue);
}

.newsShortContent {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: var(--spinfiText, #58585a);

  text-overflow: ellipsis;
  overflow: hidden;
  width: 440px;
  height: 14px;
  white-space: nowrap;
}

.newsShortContent * {
  display: inline;
}

.arrowIcon {
  margin-left: 4px;
  min-width: 16px;
}

@media screen and (max-width: 1090px) {
  .newsShortContent {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .newsShortContent {
    width: 500px;
  }
}

@media screen and (max-width: 606px) {
  .newsShortContent {
    width: 400px;
  }
}

@media screen and (max-width: 510px) {
  .newsShortContent {
    width: 300px;
  }
}

.inputNumberWrapper {
  display: inline-flex;
}

.inputNumberWrapper:global > .ant-input-number {
  border-radius: 4px 0 0 4px;
  /*border: 1px solid var(--lines);*/
}

.inputNumberWrapper:global > .inputNumberWrapperError {
  border-color: #ff6743;
}

.inputNumberWrapper:global > .ant-input-number-focused,
.inputNumberWrapper:global > .ant-input-number:active,
.inputNumberWrapper:global > .ant-input-number:focus,
.inputNumberWrapper:global > .ant-input-number:hover {
  /*border-color: transparent;*/
  /*border-color: var(--text);*/
  box-shadow: none;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  color: var(--text);
  font-size: 14px;
  line-height: 1.5;
  background-color: var(--white);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--lines);
  border-radius: 0 4px 4px 0;
  border-left: none;
}

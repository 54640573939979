.hamburgerBackground{
    background: var(--lightGrey);
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    margin: 0 10px;
    border-radius: 6px;
}



.linesWrapper{
    position: relative;
    width: 22px;
    height: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.line{
    position: relative;
    background: var(--lightBlack);
    width: 50%;
    height: 2px;
}

.line:before,
.line:after{
    content: '';
    position: absolute;
    background: var(--lightBlack);
    height: 2px;
    transition: all .2s;
}

.line:before{
    width: 200%;
    transform: translateY(-5px) rotate(0deg);
}

.line:after{
    width: 150%;
    transform: translateY(5px) rotate(0deg);
}

.active{
    background: var(--darkBlue);
    transition: all .2s;
}

.active .linesWrapper .line{
    height: 0;
}

.active .linesWrapper .line:before,
.active .linesWrapper .line:after{
    width: 200%;
    transform: translate(0);
    transition: all .2s;
    background: var(--white);
}

.active .linesWrapper .line:before{
    transform: rotate(45deg);
}

.active .linesWrapper .line:after{
    transform: rotate(-45deg);
}

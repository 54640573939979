.checkBox {
  display: flex;
  align-items: center;
}

.checkBox input {
  display: none;
}

.checkBox label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkBox label span {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--lightBlack);
  margin-left: 10px;
}

.checkBox.isMinified label span {
  font-size: 14px;
}

.checkBox.isMinified .box {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.checkBox.disabled .box {
  background-color: var(--greyDisabled);
}
.checkBox.disabled .checkMark {
  color: var(--generalGreen);
}

.box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}

.eye {
  display: flex;
  flex-grow: 1;
}

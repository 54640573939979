/* Toolbar */
.wrapper {
  display: flex;
  flex-direction: column;
}

.filtersWrapper,
.tabsWrapper {
  display: flex;
  justify-content: space-between;
}

.pageTitle:global.ant-typography {
  font-family: "Exo 2", Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--spinfiText);
}

.tabs:global.ant-tabs {
  margin-top: 12px;
}

.tabs :global .ant-tabs-nav .ant-tabs-tab {
  padding-left: 0;
  padding-right: 0;
  font-family: "Exo 2", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.tabs :global .ant-tabs-ink-bar {
  height: 3px;
  background-color: var(--limeGreen);
}

.tabs :global .ant-tabs-bar {
  border-color: var(--linesList);
}

.tabs :global .ant-tabs-nav .ant-tabs-tab-active,
.tabs :global .ant-tabs-nav .ant-tabs-tab:hover {
  font-weight: 600;
  color: var(--limeGreen);
}

.tabs :global .ant-tabs-bar .ant-tabs-extra-content {
  line-height: 1;
  padding-top: 5px;
}

.headerRow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 24px;
}

.downloadIcon:global.anticon-download {
  font-size: 18px;
  color: var(--limeGreen);
}

.downloadButton:global.ant-btn,
.downloadButton:global.ant-btn:hover,
.chooseTitle:global.ant-typography,
.tabs:global.ant-tabs-nav .ant-tabs-tab {
  color: var(--lightBlack);
}

.filtersWrapper .downloadButton:global.ant-btn {
  font-size: 14px;
  font-weight: 400;
}

.downloadButton:global.ant-btn {
  font-weight: 300;
  font-family: Roboto, Arial, sans-serif;
}

.downloadButton:global.ant-btn:hover {
  background-color: var(--secondary);
}

.searchIconWrapper {
  display: flex;
  margin-left: 15px;
}
.searchIcon svg {
  width: 19px;
  height: 19px;
  fill: var(--textInTables);
}

.searchIcon.completed:global.anticon-check-circle {
  color: var(--limeGreen);
}

.downloadIconWrapper {
  display: none;
}

@media screen and (max-width: 796px) {
  .downloadIconWrapper {
    display: block;
  }
}

.searchInputMobile:global.ant-input-affix-wrapper {
  margin-top: 24px;
}
/* Filters row */

.monthPicker {
  max-width: 210px;
}

.filtersRow {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.searchIconPrefix svg {
  fill: var(--linesList);
}

.select:global.ant-select,
.searchField:global.ant-input-affix-wrapper {
  width: 100%;
}

.selectNoBordered:global.ant-select {
  width: auto;
  min-width: 160px;
}

.selectNoBordered:global.ant-select .ant-select-selection {
  border-color: transparent !important;
}

.selectNoBordered:global.ant-select .ant-select-selection__placeholder {
  color: var(--lightBlack);
  font-size: 16px;
}

.filtersRow > * {
  flex: 0 0 auto;
}

@media screen and (min-width: 768px) {
  .row {
    display: none;
  }

  .filtersRow > *:not(:first-child) {
    margin-left: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .select:global.ant-select {
    max-width: 310px;
  }

  .searchInput:global.ant-input-affix-wrapper {
    max-width: 200px;
  }
}

@media screen and (min-width: 992px) {
  .searchInput:global.ant-input-affix-wrapper {
    max-width: 270px;
  }

  .select:global.ant-select {
    max-width: 325px;
  }
}

@media screen and (max-width: 991px) {
  .downloadButton:global.ant-btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .row {
    display: none;
  }

  .chooseWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    text-align: center;
  }

  .chooseTitle:global.ant-typography {
    display: inline-block;
    margin-bottom: 4px;
  }

  .filtersRow > *:not(:first-child) {
    margin-top: 16px;
  }

  .filtersRow,
  .filtersWrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .monthPicker {
    max-width: none;
    width: 100%;
  }

  .searchInput {
    order: -1;
    margin: 0 0 16px !important;
  }
}

.menuTabs:global.ant-menu {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.menuTabs:global.ant-menu .ant-menu-item + .ant-menu-submenu {
  margin-left: auto;
}

.menuTabs:global.ant-menu .ant-menu-item {
  position: relative;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  font-family: "Exo 2", Arial, sans-serif;
  border-bottom: 0;
  padding-right: 32px;
  color: var(--lightBlack);
}
.menuTabs:global.ant-menu .ant-menu-submenu {
  padding: 0;
  border: 0;
  color: var(--text) !important;
}
.menuTabs:global.ant-menu .ant-menu-submenu:hover {
  padding: 0;
  border: 0;
  color: var(--text);
}

.menuTabs:global.ant-menu .ant-menu-submenu:hover .ant-menu-submenu-title,
.menuTabs:global.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0;
  font-size: 16px;
}
.menuTabs:global.ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  color: var(--text);
}
.menuTabs:global.ant-menu .ant-menu-item.ant-menu-item-selected {
  color: var(--limeGreen);
}

.menuTabs:global.ant-menu .ant-menu-item.ant-menu-item-selected:after {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  content: "";
  width: calc(100% - 32px);
  height: 3px;
  background: var(--limeGreen);
}

.menuTabs:global.ant-menu .ant-menu-item:hover {
  color: var(--limeGreen);
  border-bottom: 0;
}
:global .ant-menu-submenu .ant-menu-vertical .ant-menu-item {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  height: auto;
}
:global .ant-menu-submenu .ant-menu-item.ant-menu-item-selected,
:global .ant-menu-submenu .ant-menu-item:hover {
  color: var(--limeGreen);
}

.moreBtn {
  display: inline-flex;
  align-items: center;
}

.moreBtn .moreBtnArrow:global.anticon {
  display: inline-block;
  margin-left: 3px;
  margin-right: 0;
  transition: 0.2s ease;
  font-size: 12px;
  color: var(--lightBlack);
}

.menuTabs :global .ant-menu-submenu-open .anticon {
  transform: rotate(180deg);
}

.timePicker:global.ant-time-picker .ant-time-picker-input {
    border: 1px solid var(--lines);
    border-radius: 4px;
    color: var(--text);
}

/* hover,active,focus */
:global .ant-time-picker .ant-time-picker-input:focus,
.timePicker:global.ant-time-picker .ant-time-picker-input:hover,
.timePicker:global.ant-time-picker .ant-time-picker-input:active,
.timePicker:global.ant-time-picker .ant-time-picker-input:focus {
    border: 1px solid var(--text);
    box-shadow: none;
}

:global .ant-time-picker .ant-time-picker-input:hover {
    border-color: var(--text);
}

/* icon */
.timePicker:global.ant-time-picker .ant-time-picker-clock-icon {
    color: var(--darkBlue);
}
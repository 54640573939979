.total {
    text-align: right;
    margin-top: 30px;
}

@media screen and (min-width: 640px) {
    .formWrapper {
        background-color: var(--tableRow);
        padding: 8px 24px 14px;
        border-radius: 8px;
    }
}

@media screen and (max-width: 639px) {
    .formWrapper {
        padding-top: 10px;
    }
}
/* col label title */
.formLabel {
    max-width: 345px;
    align-self: center;
}

.formLDateLabel {
    max-width: 86px;
}

.formItem {
    width: 100%;
    max-width: 256px;
}

.formItem:global.ant-form-item:not(:last-of-type) {
    margin-right: 32px;
    margin-bottom: 10px;
}

.formItemNumber:global.ant-form-item,
.formItem:global.ant-form-item {
    margin-bottom: 0;
}

.beforeEmploymentSelect:global.ant-select .ant-select-selection__clear {
    opacity: 1;
    color: grey;
}

.employmentDetailsInput {
    max-width: 130px;
}

/* button */
.buttonWrapper {
    justify-content: space-between;
}

.colTop:global.ant-col {
    align-items: flex-start;
}
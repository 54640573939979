.icon:global.anticon {
  color: var(--limeGreen);
}

.list:global {
  margin-top: 24px;
  margin-left: -10px;
  margin-right: -10px;
}

.item:global {
  padding: 16px 10px;
  line-height: 1.2;
  border-bottom: 1px solid var(--linesList);
  color: var(--textInTables);
}

.text:not(:last-child):global.ant-typography {
  margin-right: 16px;
}

.footerText {
  margin-top: 13px;
}

.itemHeader:global {
  flex: 1;
}

.itemActions:global {
  flex: 0 0 auto;
  margin-left: 16px;
}

.itemAction:global {
  display: inline-block;
  cursor: pointer;
}

.itemAction:global:last-child {
  margin-left: 10px;
}

.itemActionAddLeave:global {
  color: var(--red);
}

.itemActionAddVacation:global {
  color: var(--emeraldGreen);
}

.itemDescription:global.ant-typography {
  margin-top: 3px;
  margin-bottom: 7px;
  color: inherit;
  max-width: 80%;
}
.ItemHeader:global.ant-row-flex {
  display: flex;
  flex-wrap: nowrap;
}
.scheduleLink {
  text-overflow: ellipsis;
}

.removeBtn:global.anticon {
  color: var(--limeGreen);
}

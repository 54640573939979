/* General */

.commentaryModalRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalButtonWrapper {
  margin-top: 0;
  justify-content: space-between;
}

.button:global.ant-btn {
  width: auto;
  min-width: 140px;
  max-width: none;
}

.textDefault {
  font-size: 16px;
  color: var(--white);
}

.checkbox {
  font-size: 14px;
}

:global form textarea.ant-input {
  height: 52px;
}

.divider {
  height: 21px;
  width: 100%;
}

/* Comments */

.commentsList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 200px;
}

.commentView {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding: 5px;
  margin-bottom: 10px;
  min-width: 100px;
  max-width: 300px;
  border-radius: 6px;
  background-color: var(--blue);
}
.commentViewValidating {
  background-color: var(--blandEmeraldGreen);
}
.commentViewPayingAndValidating {
  background-color: var(--brightOrange);
}

.textAdditional {
  font-size: 14px;
  color: var(--darkBlue);
}
.textAdditionalValidating {
  font-size: 14px;
  color: var(--darkGreen);
}
.textAdditionalPayingAndValidating {
  font-size: 14px;
  color: var(--brown);
}

.textTruncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textComment {
  align-self: flex-start;
  width: 100%;
  overflow-wrap: break-word;
  hyphens: auto;
}

.commentDate {
  align-self: flex-end;
}

.noCommentsWrapper {
  align-items: center;
  gap: 10px;
}
.noCommentsIcon {
  margin-top: 10px;
  font-size: 54px;
}

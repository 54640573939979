.rangePickerWrapper:global.ant-calendar-picker .ant-input {
    border: 1px solid var(--lines);
    border-radius: 4px;
    padding: 0;
    display: flex;
    align-items: center;
}

.rangePickerWrapper {
    display: flex;
    align-items: center;
}

.rangePickerWrapper :global .ant-calendar-picker {
    width: 100%;
    max-width: 120px;
}

.rangePickerWrapper :global .ant-calendar-picker .ant-input{
    border-radius: 6px;
    color: var(--lightBlack);
    border-color: var(--linesList);
}

.separator {
    display: inline-block;
    padding: 0 5px;
}

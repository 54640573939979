.background {
  background: #f2f2f2;
  height: 100%;
  min-height: 553px;
  max-height: none;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
}

.pageWrapper {
  padding-top: 47px;
  height: 100%;
  min-height: 100%;
  max-height: none;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
}

.iconWrapper {
  cursor: pointer;
  width: max-content;
}

.penIcon {
  margin-right: 15px;
}

.miniWrapper {
  width: 780px;
  margin: 0 auto;
}

.table:global.ant-table-wrapper {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
}

.table:global.ant-table-wrapper .ant-table-thead tr > th,
.table:global.ant-table-wrapper .ant-table-tbody tr:nth-child(even) > td {
  background: white;
}

.table:global.ant-table-wrapper .ant-table-placeholder {
  display: none;
}

.tablePlaceholder {
  width: 100%;
  margin: 0 auto;
  height: 268px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  background: white;
}

.tablePlaceholderText {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--spinfiText);
}

.table:global.ant-table-wrapper .ant-table-thead tr th {
  background: #f4f7f9;
  color: var(--lightBlack);
}

.table:global.ant-table-wrapper .ant-table-thead tr > th {
  border-top: none;
}

.table:global.ant-table-wrapper .ant-table-tbody tr {
  color: var(--lightBlack);
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: white;
}

.table:global.ant-table-wrapper
  .ant-table-tbody
  tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: white;
}

.selectCellWrapper {
  width: 100%;
}

.select:global.ant-select {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  cursor: pointer;
}

.select:global.ant-select input.ant-select-search__field {
  user-select: none;
  pointer-events: none;
  width: 0px;
  cursor: pointer;
}
.select:global.ant-select .ant-select-selection__rendered {
  cursor: pointer;
}
.select:global.ant-select .ant-select-search__field__mirror {
  user-select: none;
  pointer-events: none;
  width: 0px;
  cursor: pointer;
}

.select:global.ant-select .ant-select-selection {
  background: white;
  padding: 3px 0px;
  border: none;
}

.select:global.ant-select-focused .ant-select-selection,
.select:global.ant-select .ant-select-selection:focus,
.select:global.ant-select .ant-select-selection:hover,
.select:global.ant-select .ant-select-selection:active {
  border: none;
}

.select:global.ant-select
  .ant-select-selection__choice.ant-select-selection__choice__disabled {
  background: transparent;
  border: none;
}

.selectDropdown :global .ant-select-dropdown-menu-item {
  display: flex;
  align-items: center;
}

.selectDropdown :global .ant-empty-normal {
  margin: 0 auto;
}

.selectDropdown :global .ant-empty-normal ~ .anticon {
  display: none !important;
}

.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  border: 1px solid var(--limeGreen);
  border-radius: 3px;
  padding: 1px;
  display: block;
  margin: 0 10px 0 0;
  position: relative;
  transform: translateY(0);
  order: -1;
  top: auto;
  right: auto;
}

.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-active
  .ant-select-selected-icon,
.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item:hover
  .ant-select-selected-icon {
  color: var(--limeGreen);
}

.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon {
  background: var(--limeGreen);
}

.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon,
.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  color: white;
}

.selectDropdown
  :global
  .ant-select-dropdown-menu
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.selectDropdown
  :global
  .ant-select-dropdown-menu
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: white;
}

.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected,
.selectDropdown:global.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover {
  font-weight: 400;
}

.selectDropdown :global .ant-select-dropdown-menu-item {
  white-space: normal;
}

.selectDropdown :global .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

/* Track */
.selectDropdown :global .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.selectDropdown :global .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  position: relative;
  background: var(--limeGreen);
  border-radius: 6px;
  border-right: 4px solid white;
  border-left: 4px solid white;
}

.buttonWrapper {
  margin: 30px 0 0;
  display: flex;
}

.submitButton {
  margin-right: 10px;
}

.resetChangesButton:global.ant-btn {
  max-width: 214px;
  min-width: 214px;
  width: 100%;
  font-weight: 700;
}

.inputWrapper {
  width: 100%;
}

.input {
  border: 1px solid #e3e3e3;
  outline: none;
  background: white;
  padding: 8px 14px;
  border-radius: 6px;
  color: #242428;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  min-width: 100px;
}

.input:disabled {
  background: white;
  border: 1px solid #f2f2f2;
}

.inputError {
  border: 1px solid var(--red);
}

.overflowHidden {
  overflow: hidden;
}
